import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MediaContent } from '@domain/entities/media-content/MediaContent'

@Component({
  selector: 'zen-media-list-row',
  templateUrl: './media-list-row.component.html',
  styleUrls: ['./media-list-row.component.scss'],
})
export class MediaListRowComponent {

  @Input() mediaList: MediaContent[] = []
  @Input() limitTo = 5
  @Input() showMoreFilterConfig = ''
  @Input() showCategories = false

  @Output() mediaItemSelected = new EventEmitter<string>()
  @Output() moreBoxClicked = new EventEmitter<void>()

}
