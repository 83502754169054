import { Injectable, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import {
    doc,
    docSnapshots,
    DocumentReference,
    Firestore,
    getDoc,
    increment,
    setDoc,
    updateDoc,
} from '@angular/fire/firestore'
import { Auth, user } from '@angular/fire/auth'
import { Functions, httpsCallable } from '@angular/fire/functions';
import {
    loadMindfitnessProfile,
    loadMindfitnessProfileError,
    loadMindfitnessProfileSuccess,
    requestNewContentDone,
    unloadMindfitnessProfile,
} from './store/actions'
import { FS_COLLECTION_USER_DATA_MINDFITNESS } from '@firestore/collections/user-data-mindfitness';
import {
    FSCUserMindfitnessProfile,
    mapFromFSCUserMindfitnessProfile,
} from '@/types/firestore/collections/user-data-mindfitness/FSCUserMindfitnessProfile';
import { UserMindfitnessProfileFields } from '@domain/entities/user-mindfitness/UserMindfitnessProfile';

@Injectable({
    providedIn: 'root',
})
export class FirestoreUserDataMindfitnessService implements OnDestroy {
    private userDataMindfitnessSubscription: Subscription | null = null
    private currentUserId: string | null = null

    constructor(private firestore: Firestore, private store: Store, private auth: Auth, private functions: Functions) {
        user(this.auth).pipe()
            .subscribe(async (authUser) => {
                if (authUser) {
                    this.currentUserId = authUser.uid
                    this.store.dispatch(loadMindfitnessProfile())
                    this.userDataMindfitnessSubscription = await this.subscribeToUserDataMindfitness(this.currentUserId)

                } else {
                    this.currentUserId = null
                    this.store.dispatch(unloadMindfitnessProfile())
                    this.unsubscribeFromUserDataMindfitness()
                }
            })
    }

    ngOnDestroy(): void {
        this.unsubscribeFromUserDataMindfitness()
    }

    public async markInfoDismissed(): Promise<void> {
        if (this.currentUserId) {
            const docRef = doc(this.firestore, FS_COLLECTION_USER_DATA_MINDFITNESS, this.currentUserId) as DocumentReference<FSCUserMindfitnessProfile>

            return updateDoc(docRef, UserMindfitnessProfileFields.infoDismissed, true)
        }
    }

    public async markIntroCompleted(): Promise<void> {
        if (this.currentUserId) {
            const docRef = doc(this.firestore, FS_COLLECTION_USER_DATA_MINDFITNESS, this.currentUserId) as DocumentReference<FSCUserMindfitnessProfile>

            return updateDoc(docRef, UserMindfitnessProfileFields.introCompleted, true)
        }
    }

    public async markDailyContentCompleted(): Promise<void> {
        if (this.currentUserId) {
            const docRef = doc(this.firestore, FS_COLLECTION_USER_DATA_MINDFITNESS, this.currentUserId) as DocumentReference<FSCUserMindfitnessProfile>

            return updateDoc(docRef,
                UserMindfitnessProfileFields.assignedMediaCompleted, true,
                UserMindfitnessProfileFields.levelProgress, increment(1),
            )
        }
    }

    public async markInitiationContentCompleted(): Promise<void> {
        if (this.currentUserId) {
            const docRef = doc(this.firestore, FS_COLLECTION_USER_DATA_MINDFITNESS, this.currentUserId) as DocumentReference<FSCUserMindfitnessProfile>

            // no levelProgress update, as that should have been pre-incremented on assignment for the initiation
            return updateDoc(docRef,
                UserMindfitnessProfileFields.assignedMediaCompleted, true,
            )
        }
    }

    public async requestDailyContent(): Promise<void> {
        if (this.currentUserId) {
            const requestNewMindfitnessDaily = httpsCallable(this.functions, 'requestNewMindfitnessDaily');

            try {
                await requestNewMindfitnessDaily()
                this.store.dispatch(requestNewContentDone())
            } catch (e) {
                console.log('FirestoreUserDataMindfitnessService: requestDailyContent error', e)
            }
        }
    }

    private async subscribeToUserDataMindfitness(userId: string): Promise<Subscription | null> {
        const docRef = doc(this.firestore, FS_COLLECTION_USER_DATA_MINDFITNESS, userId) as DocumentReference<FSCUserMindfitnessProfile>

        const docSnap = await getDoc(docRef)
        if (!docSnap.exists()) {
            await setDoc(docRef, {
                id: userId,
                infoDismissed: false,
                introCompleted: false,
                assignedMediaCompleted: false,
                assignedMedia: null,
                levelProgress: 1,
            })
        }

        return docSnapshots(docRef)
            .pipe(
                map((snap) => {
                    const fscUserMindfitnessProfile = snap.data()
                    return fscUserMindfitnessProfile
                        ? mapFromFSCUserMindfitnessProfile(fscUserMindfitnessProfile)
                        : null
                }),
            )
            .subscribe({
                    next: (userMindfitnessProfile) => {
                        this.store.dispatch(loadMindfitnessProfileSuccess(userMindfitnessProfile))
                    },
                    error: (error) => {
                        this.store.dispatch(loadMindfitnessProfileError())
                    },
                },
            )
    }

    private unsubscribeFromUserDataMindfitness(): void {
        if (this.userDataMindfitnessSubscription) {
            this.userDataMindfitnessSubscription.unsubscribe()
        }
    }
}
