import { createAction } from '@ngrx/store';
import { UserSubscriptionProfile } from '@domain/entities/user-subscription/UserSubscriptionProfile';

export const unLoadUserSubscription = createAction(
    '[UserSubscription] UNLOAD',
)
export const loadUserSubscription = createAction(
    '[UserSubscription] load - START',
)

export const loadUserSubscriptionError = createAction(
    '[UserSubscription] load - ERROR',
)

export const loadUserSubscriptionSuccess = createAction(
    '[UserSubscription] load - SUCCESS',
    (userSubscriptionProfile: UserSubscriptionProfile | null) => ({ userSubscriptionProfile }),
)
