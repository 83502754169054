<zen-page-layout [addFooterPaddings]="false">

    <div class="privacy-policy">
        <h3>1. A Szabályzat célja</h3>

        <p>1. A jelen adatvédelmi és adatkezelési tájékoztató (a továbbiakban: Tájékoztató) Hajdu Gábor egyéni
            vállalkozó (a
            továbbiakban: Szolgáltató) által vezetett nyilvántartásokra vonatkozó legfontosabb adatvédelmi szabályokat,
            elveket tartalmazza, különös tekintettel az adatkezeléssel, adatfeldolgozással, adattovábbítással,
            adatvédelemmel és nyilvánosságra hozatallal kapcsolatos követelményekre. A Tájékoztató célja, hogy a
            vonatkozó
            magyar és uniós jogszabályok előírásai szerint, az érintettek érdekeinek megfelelően meghatározza az
            Szolgáltató
            által alkalmazott adatvédelmi és adatkezelési elveket és szabályokat és az adatfeldolgozás feltételeit,
            módját.</p>
        <p>2. A Szolgáltató működteti a ZenCool meditációs és önismereti applikációt (továbbiakban: Applikáció), melynek
            körében az Applikáción kínált termékek, szolgáltatások után érdeklődő felhasználók (a továbbiakban
            együttesen:
            Felhasználók) adatait kezeli.</p>
        <p>3. A Tájékoztató az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII.
            törvény
            (a továbbiakban: Infotv.), az elektronikus kereskedelmi szolgáltatások, valamint az információs
            társadalommal
            összefüggő szolgáltatások egyes kérdéseiről szóló 2001. évi CVIII. törvény (Ekertv.), a gazdasági
            reklámtevékenység alapvető feltételeiről és egyes korlátairól szóló 2008. évi XLVIII. tv., valamint a
            természetes személyeknek a személyes adatok kezelése tekintetében történő védelméről és az ilyen adatok
            szabad
            áramlásáról, valamint a 95/46/EK Rendelet hatályon kívül helyezéséről rendelkező Európai Parlament és a
            Tanács
            (EU) 2016/679/EU rendelete (továbbiakban: GDPR) rendelkezéseinek megfelelően került kialakításra.</p>
        <p>4. A Szolgáltató fenntartja annak jogát, hogy az általa nyújtott szolgáltatások vagy általános szerződési
            feltételei módosításairól e-mail útján vagy az Applikáció értesítési felületén tájékoztatja a Felhasználót.
            A
            Szolgáltató a tájékoztatást reklám céljára nem használja.</p>

        <h3>2. Szolgáltató adatai</h3>

        <ul>
            <li>Cégnév: Hajdu Gábor e.v.</li>
            <li>Székhely: 1095 Budapest, Gát utca 29.</li>
            <li>Adószám: 69267297-1-43</li>
            <li>Nyilvántartási szám: 52937194</li>
            <li>Elektronikus elérhetőség: <a href="mailto:zencool@zencool.app">zencool@zencool.app</a></li>
        </ul>

        <p>Tárhelyszolgáltató adatai:</p>
        <ul>
            <li>Név: Google Cloud EMEA Limited - Google Cloud (Firebase)</li>
            <li>Cím: 70 Sir John Rogerson’s Quay, Dublin 2, Ireland</li>
            <li>Elérhetőségek:
                <ul>
                    <li><a href="https://cloud.google.com/contact" target="_blank">https://cloud.google.com/contact</a>
                    </li>
                    <li><a href="https://cloud.google.com/terms/google-entity" target="_blank">https://cloud.google.com/terms/google-entity</a>
                    </li>
                </ul>
            </li>
        </ul>

        <p>A tényleges adatfeldolgozás helye:</p>
        <p>
            A Google Cloud platform sok <a href="https://firebase.google.com/docs/functions/locations" target="_blank">adatközponttal
            rendelkezik a
            világ több pontján.</a> Ezekből mi a az alábbi adatközpontokat használjuk a
            Firestore és a Cloud Functions szolgáltatásokhoz:
        </p>
        <ul>
            <li>europe-west3 (Frankfurt)</li>
            <li>europe-central2 (Warsaw)</li>
        </ul>

        <p>Applikáció üzemeltetője: Hajdu Gábor e.v.</p>

        <h2>3. A Szolgáltató által végzett adatkezelés</h2>

        <h3>1. Az Applikációban regisztráció elvégzésével történik a kapcsolatfelvétel kezdeményezése.</h3>

        <p>Rögzített adatok:</p>
        <ul>
            <li>E-mail cím</li>
            <li>Név*</li>
            <li>Profilkép*</li>
        </ul>
        <p class="subtext">*Google vagy Facebook profillal való regisztráció esetén</p>
        <p>A Felhasználó a kapcsolatfelvételt megelőzően az adatkezeléshez történő hozzájárulását „checkbox”
            alkalmazásával
            adja meg, melynek előfeltételeként a Felhasználó nyilatkozik az adatkezelés elfogadásáról.</p>
        <ul>
            <li>Adatkezelés célja: kapcsolatfelvétel a Szolgáltatóval</li>
            <li>Adatkezelés jogalapja: hozzájárulás</li>
            <li>Adatkezelés időtartama: érintett regisztrációja törlésével egyidejűleg</li>
            <li>Adattárolás módja: a Szolgáltató a Felhasználó regisztrált adatait a Szerverszolgáltató által
                üzemeltetett
                tárhelyszolgáltatónál tárolja
            </li>
        </ul>

        <h3>2. Vásárlás az Applikációban, számlakiállítás</h3>
        <ul>
            <li>Adatkezelés célja: bizonylat (elektronikus számla) kiállítása</li>
            <li>Rögzített adatok:
                <ul>
                    <li>Vevő neve</li>
                    <li>Vevő címe</li>
                </ul>
            </li>
            <li>Adatkezelés jogalapja: jogszabályon alapuló kötelező adatkezelés (a számvitelről szóló 2000. évi C.
                törvény
                166.
                § (1) bekezdése).
            </li>
            <li>Adatkezelés időtartama: a számla kiállításától számított 8 naptári év, ennek elteltével a Szolgáltató
                köteles az
                adatot törölni.
            </li>
            <li>Adattárolás módja: a Szolgáltató a Felhasználó regisztrált adatait a Szerverszolgáltató által
                üzemeltetett
                tárhelyszolgáltatónál tárolja.
            </li>
        </ul>
        <p>Szolgáltató nem adja ki Felhasználó adatait harmadik fél részére. A bankkártyás fizetés során azonban szükség
            van
            harmadik fél bevonására. Az online bankkártyás fizetések a Barion rendszerén keresztül valósulnak meg. A
            bankkártya adatok a kereskedőhöz nem jutnak el. A szolgáltatást nyújtó Barion Payment Zrt. a Magyar Nemzeti
            Bank
            felügyelete alatt álló intézmény, engedélyének száma: H-EN-I-1064/2013.</p>
        <p>Szolgáltató nem osztja meg személyes adatait Barion rendszerével, a fizetési szolgáltatást azonban beágyazta
            Applikáció fizetési helyére.</p>
        <p>A számlakivonatokon, a Barion Payment Zrt. fog szerepelni.</p>
        <p>Az előfizetéssel kapcsolatos minden technikai információ megtalálható jelen ÁSZF 2. (Vásárlás az
            applikációban)
            pontjában.</p>

        <h2>4. Felelősség</h2>
        <p>A Felhasználó által regisztrált személyes adatokat a Szolgáltató nem ellenőrzi. A regisztrált adatok
            megfelelősségéért kizárólag a Felhasználó felel. A Felhasználó felel az általa regisztrált e-mail címre a
            Szolgáltató által továbbított adatok hozzáférhetőségéért és adatok felhasználásáért.
        </p>

        <h2>5. Regisztrált adatok kezelése</h2>


        <p>A Felhasználó által regisztrált adatokat a Szolgáltató és az adatkezelésre kijelölt munkavállalói és az
            adatfeldolgozást végző személy(ek) jogosultak megismerni. A Szolgáltató adatkezelési folyamatára vonatkozó
            hozzáférési jogosultságot a Szolgáltató vezető tisztségviselője jogosult meghatározni. A Szolgáltató által
            megbízott adatfeldolgozás folyamatára vonatkozó hozzáférési jogosultságot a megbízott adatfeldolgozó vezető
            tisztségviselője határozza meg. A Szolgáltató harmadik személy részére jogszabályban előírt kötelezettségei
            céljából jogosult és köteles adatot továbbítani.</p>

        <h2>6. A Felhasználó jogai</h2>

        <p>1. A Felhasználó a Szolgáltatónak küldött levéllel kezdeményezheti törlését a rendszerből.
        </p>
        <p>2. A Felhasználó jogosult díjmentesen tájékoztatást kérni kezelt személyes adatairól, az adatkezelés
            céljáról,
            jogalapjáról, időtartamáról, az adatkezelésre és adatfeldolgozásra jogosult nevéről, címéről (székhelyéről)
            és
            az adatkezeléssel összefüggő tevékenységéről, továbbá arról, hogy kik és milyen módon ismerhetik meg az
            adatokat, továbbá az érintett adatkezeléssel kapcsolatos jogairól és a jogorvoslati lehetőségekről. A
            Felhasználó jogosult jogszabályban meghatározott esetekben díjmentesen kérni adatai helyesbítését, törlését,
            zárolását, vagy kezelésének korlátozását, és tiltakozhat személyes adatai kezelése ellen.</p>

        <p>3. A Szolgáltató a kérelem benyújtásától számított legrövidebb időn belül, de legfeljebb 30 napon belül –
            tiltakozás esetén 15 napon belül – köteles megvizsgálni a Felhasználó kérelmét, és annak megalapozottsága
            kérdésében döntést hozni, amelyről a kérelmezőt írásban tájékoztatja. Ha a Szolgáltató a Felhasználó
            kérelmét
            nem teljesíti, döntésében közli a kérelem elutasításának ténybeli és jogi indokait a Felhasználóval.</p>

        <p>4. A Felhasználó jogosult a Polgári Törvénykönyvről szóló 2013. évi V. törvény alapján bíróság előtt
            érvényesíteni jogait, valamint az Infotv.-ben foglaltak szerint a Nemzeti Adatvédelmi és Információszabadság
            Hatósághoz (1125 Budapest, Szilágyi Erzsébet fasor 22/c.; <a href="" target="_blank">https://www.naih.hu/panaszuegyintezes-rendje.html</a>)
            fordulni és panasszal élni.</p>

        <h2>7. Egyéb tájékoztatás</h2>
        <p>1. A Szolgáltató az érdeklődők aktivitásáról adatokat gyűjt, oly módon, mely az érdeklődők személyét nem
            teszi
            beazonosíthatóvá, nem kapcsolható össze a Felhasználó által a regisztráció során közölt személyes adatokkal
            vagy
            további honlapok vagy szolgáltatások igénybevételekor megadott adatokkal.</p>

        <p>2. A Szolgáltató köteles a Felhasználót előzetesen tájékoztatni és előzetes, kifejezett hozzájárulását kérni,
            amennyiben a Felhasználó személyes adatait az általa meghatározott adatkezelési céltól eltérő célra kívánja
            felhasználni.</p>

        <p>3. A Szolgáltató köteles az adatkezelés és adatfeldolgozás biztonságáról gondoskodni és minden szükséges
            technikai intézkedést megtenni a rögzített, tárolt és kezelt adatok védelme, továbbá azok jogosulatlan
            felhasználása vagy módosítása, illetve megsemmisülése elkerülése érdekében.</p>

        <p>4. Adatvédelmi incidens a biztonság olyan sérülése, amely a kezelt személyes adatok véletlen vagy jogellenes
            megsemmisítését, elvesztését, megváltoztatását, jogosulatlan közlését vagy az azokhoz való jogosulatlan
            hozzáférést eredményezi. Adatvédelmi incidens esetén a Szolgáltató a GDPR 33. és 34. cikkei szerint köteles
            eljárni. Szolgáltató nyilvántartja az adatvédelmi incidenseket, feltüntetve az adatvédelmi incidenshez
            kapcsolódó tényeket, annak hatásait és az orvoslására tett intézkedéseket.</p>

        <p>5. A Szolgáltató bármikor jogosult jelen Tájékoztatót egyoldalúan módosítani. A Szolgáltató a Tájékoztató
            módosítását az Applikációban közzéteszi. A Felhasználó Applikáció szolgáltatásainak további igénybevételére
            Tájékoztató módosításának elfogadását követően jogosult.</p>
    </div>

</zen-page-layout>
