import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

const MAX_FILE_SIZE = 1048576 * 50 // 50MB

export enum ImageUploadValidatorErrors {
  fileSize = 'fileSize',
  fileType = 'fileType'
}

export const fileUploadValidator = (typePrefix: string): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => validateFile(control.value as File, typePrefix)

const validateFile = (file: File | null, typePrefix: string): ValidationErrors | null => {
  const validationErrors: ValidationErrors = {}

  if (!file) {
    return null
  }

  if (file.size > MAX_FILE_SIZE) {
    validationErrors[ImageUploadValidatorErrors.fileSize] = 'File is larger than 3MB'
  }

  if (!file.type.startsWith(typePrefix)) {
    validationErrors[ImageUploadValidatorErrors.fileType] = 'File is not an Image'
  }

  return Object.keys(validationErrors).length > 0
    ? null
    : validationErrors
}
