<div *ngIf="zenTag" class="media-tag" [style]="'background-color: ' + zenTag.color">

    <div class="icon-wrapper">
        <zen-svg-icon [icon]="zenTag.icon"></zen-svg-icon>
    </div>

    <div class="label">
        {{ zenTag.label }}
    </div>

</div>
