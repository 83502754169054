<zen-page-layout [addFooterPaddings]="false">

    <div class="content-edit">

        <zen-loader *ngIf="loading; else loaded"></zen-loader>

        <ng-template #loaded>

            <div class="edit-form" *ngIf="mindfitnessContent; else notFound">
                <zen-mindfitness-content-form
                        [contentToEdit]="mindfitnessContent"
                ></zen-mindfitness-content-form>
            </div>

            <ng-template #notFound>
                A tartalom nem található
            </ng-template>
        </ng-template>

    </div>

</zen-page-layout>
