import { Injectable } from '@angular/core'
import { ZenUser } from '@domain/entities/user/ZenUser'
import { doc, Firestore, updateDoc } from '@angular/fire/firestore'
import {
    ActionCodeSettings,
    Auth,
    FacebookAuthProvider,
    getRedirectResult,
    GoogleAuthProvider,
    isSignInWithEmailLink,
    sendSignInLinkToEmail,
    signInWithEmailLink,
    signInWithRedirect,
    signOut,
    user,
} from '@angular/fire/auth'
import { FS_COLLECTION_USERS } from '@firestore/collections/users'
import { ZenUserRoles } from '@domain/entities/user/ZenUserRoles'
import { environment } from '@/environments/environment'
import { RoutePaths } from '@/app/routes'
import { RoutePathsLogin } from '@/app/login/RoutePathsLogin'
import { Store } from '@ngrx/store';
import * as AuthActions from './store/actions';

const STORAGE_KEY_SIGN_IN_EMAIL = 'emailForSignIn'

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private auth: Auth, fireStore: Firestore, private store: Store) {

        // After returning from the redirect when your app initializes you can obtain the result
        getRedirectResult(auth)
            .then((redirectResult) => {
                const redirectType = redirectResult?.operationType
                if (redirectType) {
                    console.log('redirect type:', redirectType)
                }
            })
            .catch((error) => {
                this.store.dispatch(AuthActions.loginError(error.message))
            })

        user(auth).pipe().subscribe(async (authUser) => {
            if (authUser) {
                const role: ZenUserRoles = ((await authUser.getIdTokenResult()).claims.role as ZenUserRoles | undefined)
                    || ZenUserRoles.guest
                const currentUser = new ZenUser({
                    id: authUser.uid,
                    displayName: authUser.displayName,
                    photoURL: authUser.photoURL,
                    email: authUser.email,
                    role,
                    nickName: null,
                })

                this.store.dispatch(AuthActions.setCurrentUser(currentUser))

                // TODO move it into auth effect
                await updateDoc(
                    doc(fireStore, `${FS_COLLECTION_USERS}/${authUser.uid}`),
                    'id', currentUser.id,
                    'displayName', currentUser.displayName,
                    'photoURL', currentUser.photoURL,
                    'email', currentUser.email,
                    'role', currentUser.role,
                )

            } else {
                this.store.dispatch(AuthActions.setCurrentUser(null))
            }
        })
    }

    get activeSignInEmail(): string | null {
        return localStorage.getItem(STORAGE_KEY_SIGN_IN_EMAIL)
    }

    async logOut(): Promise<void> {
        await signOut(this.auth)
        this.store.dispatch(AuthActions.logoutComplete())
    }

    async logInWithGoogle(): Promise<void> {

        try {
            await signInWithRedirect(this.auth, new GoogleAuthProvider())
            this.store.dispatch(AuthActions.loginSuccess())

        } catch (e) {
            console.log('logInWithGoogle error', e)
            this.store.dispatch(AuthActions.loginError(null))
        }
    }

    async logInWithFacebook(): Promise<void> {

        try {
            await signInWithRedirect(this.auth, new FacebookAuthProvider())
            this.store.dispatch(AuthActions.loginSuccess())

        } catch (e) {
            console.log('logInWithFacebook error', e)
            this.store.dispatch(AuthActions.loginError(null))
        }
    }

    async initiateEmailLogin(email: string): Promise<void> {

        const url = `${environment.publicSiteUrl}/${RoutePaths.login}/${RoutePathsLogin.emailConfirm}`

        try {
            const settings: ActionCodeSettings = {
                url,
                handleCodeInApp: true,
            }

            await sendSignInLinkToEmail(this.auth, email, settings)
            window.localStorage.setItem(STORAGE_KEY_SIGN_IN_EMAIL, email)

        } catch (e) {
            console.log('initiateEmailLogin error', e)
        }
    }

    /**
     * Returns true is it is in fact an email link redirect
     */
    checkForEmailLink(): boolean {
        return isSignInWithEmailLink(this.auth, window.location.href)
    }

    async confirmEmailLogin(email: string): Promise<void> {
        if (this.checkForEmailLink()) {

            // The client SDK will parse the code from the link for you.
            signInWithEmailLink(this.auth, email, window.location.href)
                .then((result) => {
                    // Clear email from storage.
                    window.localStorage.removeItem(STORAGE_KEY_SIGN_IN_EMAIL)
                    // You can access the new user via result.user
                    // Additional user info profile not available via:
                    // result.additionalUserInfo.profile == null
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser
                    this.store.dispatch(AuthActions.loginSuccess())

                })
                .catch((error) => {
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                    console.log('confirmEmailLogin error', error)
                    this.store.dispatch(AuthActions.loginError(error.message))
                })
        }
    }
}
