import { createSelector } from '@ngrx/store';
import { selectCurrentUser } from '@/app/features/auth/store/selectors';
import {
    INITIATION_BACKGROUND_URL,
    selectAssignedMedia,
    selectAssignedMediaExpired,
    selectCurrentLevel,
    selectIntroCompleted,
    selectLastDateTime, selectLevelProgress,
    selectMindfitnessFeatureEnabled,
} from '@/app/features/mindfitness/store/selector';
import { ZenUserRoles } from '@domain/entities/user/ZenUserRoles';
import { environment } from '@/environments/environment';
import { STORAGE_PATH_MINDFITNESS_CONTENTS } from '@firestore/collections/mindfitness-contents';
import { selectUserHasSubscription } from '@/app/features/user-subscription/store/selectors';
import { MindfitnessMediaTypes } from '@domain/entities/mindfitness';
import { getIntroductionMindfitnessAssignedMedia } from '@domain/entities/user-mindfitness/MindfitnessAssignedMedia';
import { canDailyContentBeRequested } from '@domain/utils/mindfitness';
import {
    determineInitiationContent
} from "@domain/usecases/assignNewMindfitnessContentToUser/determineInitiationContent";

export const MINDFITNESS_MEDIA_URL_PREFIX = `${environment.publicStorageBucketCDN}/${STORAGE_PATH_MINDFITNESS_CONTENTS}`
export const MINDFITNESS_MEDIA_URL_PREFIX_LOCAL = '/assets'
export const MINDFITNESS_INTRO_CONTENT = '/introduction/content.mp3'


export const selectMindfitnessAvailableForCurrentUser = createSelector(
    selectMindfitnessFeatureEnabled,
    selectCurrentUser,
    (enabledGlobally, currentUser) => enabledGlobally || currentUser?.role === ZenUserRoles.admin,
)

export const selectMindfitnessSubscriptionActive = createSelector(
    selectUserHasSubscription,
    selectCurrentUser,
    (subscriptionActive, currentUser) => subscriptionActive || currentUser?.role === ZenUserRoles.admin,
)

export const selectBackgroundImgUrl = createSelector(
    selectAssignedMedia,
    selectCurrentLevel,
    (assignedMedia, level) => assignedMedia?.type === MindfitnessMediaTypes.initiation
        ? INITIATION_BACKGROUND_URL
        : level.backgroundImgUrl,
)

export const selectDailyContentCanBeRequested = createSelector(
    selectAssignedMedia,
    selectAssignedMediaExpired,
    selectIntroCompleted,
    selectMindfitnessSubscriptionActive,
    selectLastDateTime,
    (assignedMedia, assignedMediaExpired, introCompleted, subscriptionActive, lastDateTime) =>
        canDailyContentBeRequested({
            assignedMedia,
            assignedMediaExpired,
            introCompleted,
            subscriptionActive,
            currentDateTime: lastDateTime,
        }),
)

export const selectCurrentMedia = createSelector(
    selectAssignedMedia,
    selectAssignedMediaExpired,
    selectIntroCompleted,
    selectMindfitnessSubscriptionActive,
    (assignedMedia, assignedMediaExpired, introCompleted, subscriptionActive) => {
        return !introCompleted || !subscriptionActive
            ? getIntroductionMindfitnessAssignedMedia()
            : !assignedMedia || assignedMediaExpired
                ? null
                : assignedMedia

    },
)

export const selectInitiationContentUpcoming = createSelector(
    selectLevelProgress,
    (levelProgress) => !!determineInitiationContent(levelProgress)
)
