import { Component } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { AuthService } from '@/app/features/auth/auth.service'
import { ZenButtonSizes } from '@domain/entities/ZenButtonSizes'
import { ZenButtonStyles } from '@domain/entities/ZenButtonStyles'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'
import { ZenSvgIconStyles } from '@domain/entities/ZenSvgIconStyles'

@Component({
    selector: 'zen-login-email',
    templateUrl: './login-email.component.html',
    styleUrls: ['./login-email.component.scss'],
})
export class LoginEmailComponent {
    submitted = false

    buttonSizes = ZenButtonSizes
    buttonStyles = ZenButtonStyles

    zenSvgIcons = ZenSvgIcons
    zenSvgIconStyles = ZenSvgIconStyles

    emailLoginForm = new UntypedFormGroup({
        email: new UntypedFormControl('', [
            Validators.required,
            Validators.email,
        ]),
    })

    constructor(private authService: AuthService) {
    }

    get email(): UntypedFormControl {
        return this.emailLoginForm.get('email') as UntypedFormControl
    }

    onSubmit() {
        console.warn('onSubmit', this.emailLoginForm.value)

        this.emailLoginForm.disable()
        void this.authService.initiateEmailLogin(this.email.value)
        this.submitted = true
    }
}

