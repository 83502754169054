import { Injectable } from '@angular/core'
import { Actions } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { AfsUserDataSubscriptionService } from '@/app/features/user-subscription/afs-user-data-subscription.service';


@Injectable()
export class UserSubscriptionEffects {

    // loadMindfitnessStart$ = createEffect(() => this.actions$.pipe(
    //     ofType(MindfitnessActions.loadMindfitnessContentStart),
    //     tap(() => console.log('loadMindfitnessStart effect')),
    //     mergeMap(() => this.service.getMindfitnessContent()
    //         .pipe(
    //             map(content => MindfitnessActions.loadMindfitnessContentSuccess(content)),
    //             catchError(() => EMPTY),
    //         )),
    // ))

    // loadMindfitnessProfileStart$ = createEffect(() => this.actions$.pipe(
    //     ofType(MindfitnessActions.loadMindfitnessProfileStart),
    //     tap(() => console.log('loadMindfitnessProfileStart effect')),
    //     mergeMap(() => this.service.getMindfitnessProfile()
    //         .pipe(
    //             map(profile => MindfitnessActions.loadMindfitnessProfileSuccess(profile)),
    //             catchError(() => EMPTY),
    //         )),
    // ))

    constructor(
        private actions$: Actions,
        private service: AfsUserDataSubscriptionService,
        private store: Store,
    ) {
    }

}
