<zen-page-layout [addFooterPaddings]="false">

    <div class="terms">
        <p>Jelen applikáció, vagyis a ZenCool meditációs és önismereti applikáció (továbbiakban Applikáció) üzemeltetője
            Hajdu Gábor e.v. (továbbiakban Szolgáltató)</p>

        <p>Szolgáltató székhelye: 1095 Budapest, Gát u. 29. adószáma: 69267297-1-43, Nyilvántartási száma: 52937194.</p>

        <p>Applikáció megnyitásával, annak tartalmához történő hozzáféréssel Ön korlátozás és fenntartás nélkül
            elfogadja a
            jelen nyilatkozatban foglalt feltételeket, függetlenül attól, hogy azokat elolvasta-e, vagy sem.</p>

        <p>Szolgáltató fenntartja a jogot az Applikációban közzétett tartalom bármikor történő felülvizsgálatára,
            módosítására, illetve frissítésére, mely módosítások Felhasználóra nézve kötelező érvényűek.</p>

        <h2>1. Felelősség</h2>
        <p>Applikáció bármilyen célú használatát mindenki saját felelősségére teszi, saját belátása alapján. Az
            applikáción
            keresztül letöltött és megismert információ felhasználása önkéntes és kizárólag saját kockázatra végzett
            tevékenység. A Felhasználó vállalja, hogy az Applikációban található anyagokat kizárólag saját felelősségére
            használja.
            Applikáció használata közben közvetlenül, illetve közvetetten vagy véletlenszerűen – felmerülő hibáért,
            kárért,
            adatvesztésért, illetve az applikáció tartalmi hibáiért vagy hiányosságaiért felelősség Szolgáltatót nem
            terheli.</p>

        <p>Szolgáltató fenntartja azt a jogot, hogy előzetes bejelentés nélkül Applikáció bármely vagy összes
            szolgáltatását
            megszüntesse.</p>

        <p>Az Applikációban technikai pontatlanságok előfordulhatnak. Szolgáltató fenntartja a jogot, hogy a mindenkor
            megjelenő tartalmat és termékeket bármikor, értesítés nélkül megváltoztassa, kijavítsa és/vagy
            tökéletesítse.</p>

        <p>Szolgáltató nem vonható felelősségre semmiféle kárért, amely az Ön készülékét vagy egyéb tulajdonát az
            Applikációhoz történő kapcsolódás, illetve hozzáférés során éri annak használata következtében, ideértve
            bármely
            anyag, adat, szöveg, kép, videó vagy hanganyag letöltése során bekövetkező negatív eseményt.</p>

        <p class="disclaimer">
            FIGYELEM! Az applikáció használata, illetve a hanganyagok meghallgatása nem helyettesít semmilyen orvosi
            vizsgálatot vagy kezelést, terápiát, nem alkalmas diagnózis vagy gyógymód felállítására.
        </p>

        <h2>2. Regisztráció az applikációban</h2>
        <p>Az applikációba történő regisztráció ingyenes. Regisztráció után Felhasználó azonnal hozzáfér az Applikáció
            ingyenes tartalmaihoz és szolgáltatásaihoz.</p>
        <p>Felhasználó a hozzáférés megszüntetését, illetve adatainak törlését e-mailben, a <a
                src="mailto:zencool@zencool.app">zencool@zencool.app</a> címen kérheti, e-mail címének megadásával,
            illetve Facebook profillal történt regisztráció esetén, a Facebookon regisztrált e-mail címének megadásával.
        </p>

        <h2>3. Vásárlás az applikációban</h2>

        <p>Amennyiben Felhasználó előfizet az Applikáció bármely oldalán található, bármely fizetős tartalmára, abban az
            esetben a szolgáltatás ellenértéke azonnal, visszavonhatatlanul terhelődik a Felhasználó által megadott
            bankkártyával.</p>
        <p>Amennyiben bármilyen rendszerhiba vagy bármilyen egyéb okból kifolyólag Felhasználó nem a megfelelő áron,
            vagy
            díjmentesen jut hozzá bármelyik szolgáltatáshoz, Szolgáltató jogosult sztornózni a tranzakciót, ezáltal az
            adott
            szolgáltatást megvonni Felhasználótól, és az esetlegesen kifizetett összeget visszaszolgáltatni Felhasználó
            felé.</p>

        <p>Az online bankkártyás fizetések a Barion rendszerén keresztül valósulnak meg. A bankkártya adatok a
            kereskedőhöz
            nem jutnak el. A szolgáltatást nyújtó Barion Payment Zrt. a Magyar Nemzeti Bank felügyelete alatt álló
            intézmény, engedélyének száma: H-EN-I-1064/2013.</p>
        <p>Szolgáltató nem osztja meg személyes adatait Barion rendszerével, a fizetési szolgáltatást azonban beágyazta
            Applikáció fizetési helyére.</p>
        <p>A számlakivonatokon, a Barion Payment Zrt. fog szerepelni.</p>
        <p>Az előfizetéssel Felhasználó automatikusan elfogadja Applikáció jelen felhasználási feltételeit. További
            információk a fizetéssel kapcsolatban adatvédelmi szempontból jelen ÁSZF 4.3.2. (Vásárlás az Applikációban,
            számlakiállítás) pontjában találhatók.</p>

        <h2>4. Szerzői jog</h2>
        <p>Applikációban található összes képi, szöveges, audio, audiovizuális tartalom és azok elrendezése szerzői
            jogi,
            illetve védjegy oltalom alatt állnak, azok Szolgáltató kizárólagos tulajdonát képezik. Azok bármilyen
            formában
            történő felhasználása, a személyes használatot meghaladó mértékben kizárólag Szolgáltató kifejezetten erre
            vonatkozó írásos engedélyével lehetséges.</p>
        <p>Az applikációban hallható bizonyos háttérzenék szerzője és tulajdonosa: Chris Collins, <a
                href="https://indiemusicbox.com" target="_blank">indiemusicbox.com</a></p>

        <p>Az applikációban látható bizonyos grafikák szerzője és tulajdonosa: <a href="https://www.freepik.com/free-vector/beautiful-scene-tiantan-temple-famous-monument-beijing-china-one-famous-landmark-asia-tourist-attraction-design-postcard-travel-poster-vector-illustration_23245948.htm" target="_blank">jcomp a Freepik-en</a>
        </p>

        <h2>5. Kapcsolat</h2>
        <p>A jelen applikáció üzemeltetője Hajdu Gábor e.v., (székhely: 1095 Budapest, Gát u. 29. adószám:
            69267297-1-43,
            Nyilvántartási száma: 52937194).</p>
        <p>Az applikációval kapcsolatos kérdés és észrevétel esetén kérjük, vegye fel a kapcsolatot Szolgáltatóval a <a
                href="mailto:zencool@zencool.app">zencool@zencool.app</a> e-mail címen!</p>
        <p>Szolgáltató a jelen felhasználási feltételek módosításának jogát fenntartja. E felhasználási feltételek és az
            esetleges újabb információk áttekintése érdekében rendszeresen látogassa ezt az oldalt!</p>
    </div>

</zen-page-layout>
