import { createReducer, on } from '@ngrx/store'
import { NavItem, ZenRouteConfig } from '@/types';
import { RoutePaths } from '@/app/routes';
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons';
import * as NavigationActions from '../actions'


export const navigationFeatureKey = 'navigation'

export interface NavigationState {
    adminNavItems: NavItem[]
    secondaryNavItems: NavItem[]
    previousRoute: string
    lastRoute: string
    activeRouteConfig: ZenRouteConfig | null,
    notificationsActive: boolean;
    settingsActive: boolean;
    loginActive: boolean;
    headerTitle: string
}

const initialState: NavigationState = {
    adminNavItems: [
        {
            routerLink: `/${RoutePaths.admin}`,
            icon: ZenSvgIcons.cog,
            label: 'Adminisztráció',
        },
        {
            routerLink: `/${RoutePaths.adminMindfitness}`,
            icon: ZenSvgIcons.cog,
            label: 'Admin - Mindfitness',
        },
    ],
    secondaryNavItems: [
        {
            routerLink: `/${RoutePaths.aboutUs}`,
            icon: ZenSvgIcons.people,
            label: 'Rólunk',
        },
        {
            routerLink: `/${RoutePaths.termsAndConditions}`,
            icon: ZenSvgIcons.document,
            label: 'Felhasználási feltételek',
        },
        {
            routerLink: `/${RoutePaths.privacyPolicy}`,
            icon: ZenSvgIcons.document,
            label: 'Adatvédelmi Szabályzat',
        },
    ],
    previousRoute: '',
    lastRoute: '',
    activeRouteConfig: null,
    notificationsActive: false,
    settingsActive: false,
    loginActive: false,
    headerTitle: 'ZenCool',
}

export const reducer = createReducer(
    initialState,

    on(NavigationActions.toggleNotificationsModal, (state): NavigationState => ({
        ...state,
        notificationsActive: !state.notificationsActive,
        loginActive: false,
        settingsActive: false,
        headerTitle: !state.notificationsActive
            ? 'Értesítések'
            : state.activeRouteConfig?.routeTitle
                ? state.activeRouteConfig.routeTitle
                : 'ZenCool',
    })),

    on(NavigationActions.toggleSettingsModal, (state): NavigationState => ({
        ...state,
        settingsActive: !state.settingsActive,
        notificationsActive: false,
        loginActive: false,
        headerTitle: !state.settingsActive
            ? 'Beállítások'
            : state.activeRouteConfig?.routeTitle
                ? state.activeRouteConfig.routeTitle
                : 'ZenCool',
    })),

    on(NavigationActions.toggleLoginModal, (state): NavigationState => ({
        ...state,
        loginActive: !state.loginActive,
        settingsActive: false,
        notificationsActive: false,
        headerTitle: !state.loginActive
            ? 'Jelentkezz be!'
            : state.activeRouteConfig?.routeTitle
                ? state.activeRouteConfig.routeTitle
                : 'ZenCool',
    })),

    on(NavigationActions.setLastRoute, (state, { lastRoute }): NavigationState => ({
        ...state,
        previousRoute: state.lastRoute,
        lastRoute,
    })),

    on(NavigationActions.setActiveRouteConfig, (state, { activeRouteConfig }): NavigationState => ({
        ...state,
        activeRouteConfig,
        headerTitle: activeRouteConfig?.routeTitle
            ? activeRouteConfig.routeTitle
            : 'ZenCool',
    })),

    on(NavigationActions.closeAllModals, (state): NavigationState => ({
        ...state,
        notificationsActive: false,
        settingsActive: false,
        loginActive: false,
    })),
)
