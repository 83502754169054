<zen-app-layout-relative-ui #appLayoutUI
                            [class]="{'zen-debug':  false, 'zen-use-filters':  true}"
                            [headerVisible]="(activeRouteConfig$ | async)?.toolbarEnabled || false"
                            [footerVisible]="(activeRouteConfig$ | async)?.bottomNavEnabled || false"
                            [menuVisible]="(settingsActive$ | async) || false"
                            [notificationsVisible]="(notificationsActive$ | async) || false"
                            [loginVisible]="(loginActive$ | async) || false"
>

    <zen-header header></zen-header>

    <router-outlet content (activate)="onRouteActivate()"></router-outlet>

    <zen-tab-bar footer></zen-tab-bar>

<!--    Overlays-->
    <zen-settings></zen-settings>

    <zen-login-contextual></zen-login-contextual>

    <!--    <zen-notifications></zen-notifications>-->

</zen-app-layout-relative-ui>
