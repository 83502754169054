import { Component, OnInit } from '@angular/core'
import { ZenButtonSizes } from '@domain/entities/ZenButtonSizes'
import { ZenButtonStyles } from '@domain/entities/ZenButtonStyles'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'
import { Router } from '@angular/router'
import { SettingsService } from '@/app/settings/settings.service'
import { RoutePaths } from '@/app/routes'

@Component({
    selector: 'zen-login-success',
    templateUrl: './login-success.component.html',
    styleUrls: ['./login-success.component.scss'],
})
export class LoginSuccessComponent implements OnInit {

    buttonSizes = ZenButtonSizes
    buttonStyles = ZenButtonStyles
    svgIcons = ZenSvgIcons

    private redirectTimeout: number = 0

    constructor(private router: Router) {
    }

    async backToApp(): Promise<void> {
        window.clearTimeout(this.redirectTimeout)
        await this.router.navigateByUrl(SettingsService.getGoToPathAfterLogin() || `/${RoutePaths.pageHome}`)
        SettingsService.clearGoToPathAfterLogin()
    }

    ngOnInit(): void {
        this.redirectTimeout = window.setTimeout(() => {
            void this.backToApp()
        }, 3000)
    }

}
