<div class="overlay-slider" [class.visible]="visible" (swipeleft)="nextSlide()" (swiperight)="prevSlide()">

    <div class="wrapper">

        <div class="top-area">

            <div class="actions">

                <zen-button (pressed)="dismiss()"
                            [style]="zenButtonStyles.quaternary"
                            [size]="zenButtonSizes.small"
                >
                    Átugrás
                </zen-button>

            </div>

        </div>


        <ng-container *ngIf="activeSlide === 1">
            <div class="content-area">

                <div class="content-top">

                    <div class="graphic">
                        <zen-svg-graphic [graphic]="zenSvgGraphics.zencoolLogoCircle"></zen-svg-graphic>
                    </div>

                </div>
                <div class="content-bottom">

                    <div class="placeholder half"></div>

                    <div class="headline">
                        Mit ad neked a ZenCool rendszeres használata?
                    </div>

                    <div class="placeholder half"></div>

                    <div class="bullet-list">
                        <div class="bullet-list-item">
                            <div class="icon">
                                <zen-svg-icon [icon]="zenSvgIcons.successRounded"
                                              [style]="zenSvgIconStyles.accent"></zen-svg-icon>
                            </div>

                            <div class="text">
                                Közelebb kerülsz önmagadhoz
                            </div>
                        </div>

                        <div class="bullet-list-item">
                            <div class="icon">
                                <zen-svg-icon [icon]="zenSvgIcons.successRounded"
                                              [style]="zenSvgIconStyles.accent"></zen-svg-icon>
                            </div>

                            <div class="text">
                                Kiegyensúlyozottabb, nyugodtabb leszel
                            </div>
                        </div>

                        <div class="bullet-list-item">
                            <div class="icon">
                                <zen-svg-icon [icon]="zenSvgIcons.successRounded"
                                              [style]="zenSvgIconStyles.accent"></zen-svg-icon>
                            </div>

                            <div class="text">
                                Harmonikusabbá válik az életed
                            </div>
                        </div>
                    </div>

                    <div class="placeholder"></div>

                </div>

            </div>
        </ng-container>

        <ng-container *ngIf="activeSlide === 2">
            <div class="content-area">

                <div class="content-top">

                    <div class="graphic">
                        <zen-svg-graphic [graphic]="zenSvgGraphics.headphoneBuddhaCircle"></zen-svg-graphic>
                    </div>

                </div>
                <div class="content-bottom">

                    <div class="placeholder half"></div>

                    <div class="headline">
                        Böngéssz szabadon az ingyenes tartalmak között!
                    </div>

                    <div class="placeholder half"></div>

                    <div class="bullet-list">
                        <div class="bullet-list-item">
                            <div class="icon">
                                <zen-svg-icon [icon]="zenSvgIcons.catMeditaciok"
                                              [style]="zenSvgIconStyles.accent"></zen-svg-icon>
                            </div>

                            <div class="text">
                                Vezetett meditációk
                            </div>
                        </div>

                        <div class="bullet-list-item">
                            <div class="icon">
                                <zen-svg-icon [icon]="zenSvgIcons.catEloadasok"
                                              [style]="zenSvgIconStyles.accent"></zen-svg-icon>
                            </div>

                            <div class="text">
                                Önismereti tanítások
                            </div>
                        </div>

                        <div class="bullet-list-item">
                            <div class="icon">
                                <zen-svg-icon [icon]="zenSvgIcons.catGyakorlatok"
                                              [style]="zenSvgIconStyles.accent"></zen-svg-icon>
                            </div>

                            <div class="text">
                                Fejlesztő gyakorlatok
                            </div>
                        </div>
                    </div>

                    <div class="placeholder"></div>

                </div>

            </div>
        </ng-container>

        <ng-container *ngIf="activeSlide === 3">
            <div class="content-area">

                <div class="content-top">

                    <div class="graphic">
                        <zen-svg-graphic [graphic]="zenSvgGraphics.templeExteriorPageBgCircle"></zen-svg-graphic>
                    </div>

                </div>
                <div class="content-bottom">

                    <div class="placeholder half"></div>

                    <div class="headline">
                        Fejlődj folyamatosan MindFitness programunkkal!
                    </div>

                    <div class="placeholder half"></div>

                    <div class="bullet-list">
                        <div class="bullet-list-item">
                            <div class="icon">
                                <zen-svg-icon [icon]="zenSvgIcons.calendar"
                                              [style]="zenSvgIconStyles.accent"></zen-svg-icon>
                            </div>

                            <div class="text">
                                Rendszeres gyakorlás
                            </div>
                        </div>

                        <div class="bullet-list-item">
                            <div class="icon">
                                <zen-svg-icon [icon]="zenSvgIcons.rocket"
                                              [style]="zenSvgIconStyles.accent"></zen-svg-icon>
                            </div>

                            <div class="text">
                                Folyamatos képességfejlesztés
                            </div>
                        </div>

                        <div class="bullet-list-item">
                            <div class="icon">
                                <zen-svg-icon [icon]="zenSvgIcons.catEloadasok"
                                              [style]="zenSvgIconStyles.accent"></zen-svg-icon>
                            </div>

                            <div class="text">
                                Egyéni, karmikus tanítások
                            </div>
                        </div>

                        <div class="bullet-list-item">
                            <div class="icon">
                                <zen-svg-icon [icon]="zenSvgIcons.sun"
                                              [style]="zenSvgIconStyles.accent"></zen-svg-icon>
                            </div>

                            <div class="text">
                                Beavatások
                            </div>
                        </div>
                    </div>

                    <div class="placeholder"></div>

                </div>

            </div>
        </ng-container>

        <ng-container *ngIf="activeSlide === 4">
            <div class="content-area">

                <div class="content-top">

                    <div class="graphic">
                        <zen-svg-graphic [graphic]="zenSvgGraphics.taraMethodLogo"></zen-svg-graphic>
                    </div>

                </div>
                <div class="content-bottom">

                    <div class="placeholder half"></div>

                    <div class="headline">
                        Hanganyagaink a <br />TARA METHOD<sup>&reg;</sup> komplex tudásrendszerre épülnek.
                    </div>

                    <div class="placeholder half"></div>

                    <div class="bullet-list">
                        <div class="bullet-list-item">
                            <div class="icon">
                                <zen-svg-icon [icon]="zenSvgIcons.successRounded"
                                              [style]="zenSvgIconStyles.accent"></zen-svg-icon>
                            </div>

                            <div class="text">
                                Mély felismerések
                            </div>
                        </div>

                        <div class="bullet-list-item">
                            <div class="icon">
                                <zen-svg-icon [icon]="zenSvgIcons.successRounded"
                                              [style]="zenSvgIconStyles.accent"></zen-svg-icon>
                            </div>

                            <div class="text">
                                Célorientált gondolkodás
                            </div>
                        </div>

                        <div class="bullet-list-item">
                            <div class="icon">
                                <zen-svg-icon [icon]="zenSvgIcons.successRounded"
                                              [style]="zenSvgIconStyles.accent"></zen-svg-icon>
                            </div>

                            <div class="text">
                                Tudatosságfejlesztés
                            </div>
                        </div>
                    </div>

                    <div class="placeholder"></div>

                </div>

            </div>
        </ng-container>


        <div class="bottom-area">
            <zen-slider-progress-indicator class="indicator"
                                           [active]="activeSlide"
                                           [max]="maxSlide">
            </zen-slider-progress-indicator>

            <zen-button class="cta"
                        [chevron]="true"
                        [style]="zenButtonStyles.primary"
                        [size]="zenButtonSizes.dynamic"
                        [fullWidth]="true"
                        (pressed)="ctaNext()">
                {{ activeSlide === maxSlide ? 'Kezdjük' : 'Következő' }}
            </zen-button>
        </div>

    </div>

</div>
