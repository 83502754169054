import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { debounceTime, throttleTime, map } from 'rxjs/operators'
import { setCompleted, setLiked, setProgress } from '../actions'
import { AfsMediaContentProgressService } from '../../afs-media-content-progress.service'

@Injectable()
export class MediaContentProgressEffects {

  setProgress$ = createEffect(() => this.actions$.pipe(
    ofType(setProgress),
    throttleTime(10000),
    map(({ mediaContentId, progress }) => this.service.setMediaContentProgress(mediaContentId, progress)),
  ), { dispatch: false })

  setCompleted$ = createEffect(() => this.actions$.pipe(
    ofType(setCompleted),
    map(({ mediaContentId, completed }) => this.service.setMediaContentCompleted(mediaContentId, completed)),
  ), { dispatch: false })

  setLiked$ = createEffect(() => this.actions$.pipe(
    ofType(setLiked),
    map(({ mediaContentId, liked }) => this.service.setMediaContentLiked(mediaContentId, liked)),
  ), { dispatch: false })

  constructor(private actions$: Actions, private service: AfsMediaContentProgressService) {
  }

}
