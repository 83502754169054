import { NgModule } from '@angular/core'
import { BrowserModule, HammerModule } from '@angular/platform-browser'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from '@/environments/environment'
import { HttpClientModule } from '@angular/common/http'
import { initializeApp, provideFirebaseApp } from '@angular/fire/app'
import { getFirestore, provideFirestore } from '@angular/fire/firestore'
import { getStorage, provideStorage } from '@angular/fire/storage'
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions'
import { getAuth, provideAuth, useDeviceLanguage } from '@angular/fire/auth'
import { StoreModule } from '@ngrx/store'
import { metaReducers, reducers } from './store/reducers'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { MediaContentModule } from '@/app/features/media-content/media-content.module'
import { EffectsModule } from '@ngrx/effects'
import { CoreModule } from '@/app/core/core.module'
import { ComponentsModule } from '@/app/components/components.module'
import { MediaContentProgressModule } from '@/app/features/media-content-progress/media-content-progress.module'
import { SettingsModule } from './settings/settings.module'
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics'
import { LoginModule } from '@/app/login/login.module'
import { CurrentUserModule } from '@/app/features/current-user/current-user.module'
import { NavigationModule } from '@/app/features/navigation/navigation.module';
import { AuthModule } from '@/app/features/auth/auth.module';
import { MindfitnessModule } from '@/app/features/mindfitness/mindfitness.module';
import { UserSubscriptionModule } from '@/app/features/user-subscription/user-subscription.module';
import { DEFAULT_REGION } from '../../functions/src/constants';
import { AdminMindfitnessViewModule } from '@/app/views/admin-mindfitness-view/admin-mindfitness-view.module';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HammerModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideAuth(() => {
            const auth = getAuth()
            useDeviceLanguage(auth)
            return auth
        }),
        provideFirestore(() => {
            const firestore = getFirestore()
            // connectFirestoreEmulator(firestore, 'localhost', 8080);
            // void enableIndexedDbPersistence(firestore)
            return firestore
        }),
        provideStorage(() => getStorage()),
        provideFunctions(() => {
            const functions = getFunctions(undefined, DEFAULT_REGION)
            if (!environment.production) {
                connectFunctionsEmulator(functions, '127.0.0.1', 5001)
            }
            return functions
        }),
        provideAnalytics(() => getAnalytics()),
        StoreModule.forRoot(reducers, { metaReducers }),
        EffectsModule.forRoot([]),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        AppRoutingModule,
        CoreModule,
        ComponentsModule,
        MediaContentModule,
        MediaContentProgressModule,
        MindfitnessModule,
        CurrentUserModule,
        SettingsModule,
        LoginModule,
        NavigationModule,
        AuthModule,
        UserSubscriptionModule,
        AdminMindfitnessViewModule,
    ],
    providers: [
        ScreenTrackingService,
        UserTrackingService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
