import { Component } from '@angular/core'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'
import { ZenSvgIconStyles } from '@domain/entities/ZenSvgIconStyles'
import { NavItem } from '@/types'
import { RoutePaths } from '@/app/routes'
import { RoutePathsRecordings } from '@/app/recordings/RoutePathsRecordings'

@Component({
    selector: 'zen-tab-bar',
    templateUrl: './tab-bar.component.html',
    styleUrls: ['./tab-bar.component.scss'],
})
export class TabBarComponent {

    navItems: NavItem[] = [
        {
            routerLink: `/${RoutePaths.recordings}`,
            icon: ZenSvgIcons.headphone,
            label: 'Felvételek',
        },
        {
            routerLink: `/${RoutePaths.recordings}/${RoutePathsRecordings.search}`,
            icon: ZenSvgIcons.search,
            label: 'Keresés',
        },
        {
            routerLink: `/${RoutePaths.recordings}/${RoutePathsRecordings.myLists}`,
            icon: ZenSvgIcons.list,
            label: 'Listáim',
        },
    ]

    zenSvgIcons = ZenSvgIcons
    zenSvgIconStyles = ZenSvgIconStyles

}
