export enum ZenSvgIcons {
    navCollapsed = 'navCollapsed',
    navExpanded = 'navExpanded',

    iconCompleted = 'iconCompleted', // Completed recording check

    // phase 2
    bell = 'bell',
    menu = 'menu',
    info = 'info',
    filter = 'filter',
    share = 'share',
    shareIOS = 'shareIOS',
    heart = 'heart',
    article = 'article',
    envelope = 'envelope',
    radioOff = 'radioOff',
    radioOn = 'radioOn',
    checkboxOff = 'checkboxOff',
    checkboxOn = 'checkboxOn',
    play = 'play',
    startPlay = 'startPlay',
    pause = 'pause',
    rev = 'rev',
    fwd = 'fwd',
    replay = 'replay',
    chevronLeft = 'chevronLeft',
    chevronRight = 'chevronRight',
    chevronUp = 'chevronUp',
    chevronDown = 'chevronDown',
    headphone = 'headphone',
    search = 'search',
    list = 'list',
    cross = 'cross',
    logoGoogleCircled = 'logoGoogleCircled',
    envelopeCircled = 'envelopeCircled',
    logoFacebookCircled = 'logoFacebookCircled',
    crossRounded = 'crossRounded',
    successRounded = 'successRounded',
    logoGoogle = 'logoGoogle',
    roundedArrowRight = 'roundedArrowRight',
    logoFacebook = 'logoFacebook',
    logoZenCool = 'logoZenCool',
    accountCircle = 'accountCircle',
    login = 'login',
    logout = 'logout',
    recentActivity = 'recentActivity',
    logoInstagram = 'logoInstagram',
    catMeditaciok = 'catMeditaciok',
    catEloadasok = 'catEloadasok',
    catGyakorlatok = 'catGyakorlatok',
    tagFokusz = 'tagFokusz',
    tagEgeszseg = 'tagEgeszseg',
    tagTisztanlatas = 'tagTisztanlatas',
    tagBurnout = 'tagBurnout',
    tagElszakadas = 'tagElszakadas',
    tagNyugalom = 'tagNyugalom',
    tagOnbizalom = 'tagOnbizalom',
    tagTeremtes = 'tagTeremtes',
    tagBoldogsag = 'tagBoldogsag',
    tagAlvas = 'tagAlvas',
    tagSzerelem = 'tagSzerelem',
    tagSzeretet = 'tagSzeretet',
    people = 'people', // Rolunk link
    document = 'document', // ASZF link, Adatvédelmi Szabályzat
    cog = 'cog', // Adminisztracio link
    calendar = 'calendar',
    rocket = 'rocket',
    sun = 'sun'
}
