import { ZenUserMediaContentProgress } from '@domain/entities/user/ZenUserMediaContentProgress'
import { Timestamp } from '@angular/fire/firestore'

export interface FSCZenUserMediaContentProgress {
  id: string;
  progress: number;
  completed: boolean;
  liked: boolean;
  lastPlayed: Timestamp | null;
  playCount: number;
}

export const mapToFSCZenUserMediaContentProgress = (contentProgress: ZenUserMediaContentProgress): FSCZenUserMediaContentProgress => ({
  ...contentProgress,
  lastPlayed: contentProgress.lastPlayed
    ? Timestamp.fromDate(contentProgress.lastPlayed)
    : null,
})

export const mapBackToZenUserMediaContentProgress = (fssContentProgress: FSCZenUserMediaContentProgress): ZenUserMediaContentProgress =>
  new ZenUserMediaContentProgress({
    ...fssContentProgress,
    lastPlayed: fssContentProgress.lastPlayed
      ? fssContentProgress.lastPlayed.toDate()
      : null,
  })
