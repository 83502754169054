import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
    selector: 'zen-page-title',
    templateUrl: './page-title.component.html',
    styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent {

    @Input() subTitle?: boolean = false
    @Input() titleIcon?: boolean = false
    @Input() titleClickable?: boolean = false
    @Output() clicked = new EventEmitter<void>()
}
