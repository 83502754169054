import {
    IUserMindfitnessProfile,
    UserMindfitnessProfile,
} from '@domain/entities/user-mindfitness/UserMindfitnessProfile';
import {
    FSCMindfitnessAssignedMedia,
    mapFromFSCMindfitnessAssignedMedia,
    mapToFSCMindfitnessAssignedMedia,
} from './FSCMindfitnessAssignedMedia';

export interface FSCUserMindfitnessProfile extends IUserMindfitnessProfile {
    assignedMedia: FSCMindfitnessAssignedMedia | null
}

export const mapToFSCUserMindfitnessProfile = (userMindfitnessProfile: UserMindfitnessProfile): FSCUserMindfitnessProfile => ({
    ...userMindfitnessProfile,
    assignedMedia: userMindfitnessProfile.assignedMedia
        ? mapToFSCMindfitnessAssignedMedia(userMindfitnessProfile.assignedMedia)
        : null,
})

export const mapFromFSCUserMindfitnessProfile = (fscUserMindfitnessProfile: FSCUserMindfitnessProfile): UserMindfitnessProfile => ({
    ...fscUserMindfitnessProfile,
    assignedMedia: fscUserMindfitnessProfile.assignedMedia
        ? mapFromFSCMindfitnessAssignedMedia(fscUserMindfitnessProfile.assignedMedia)
        : null,
})
