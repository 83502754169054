import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'
import { ZenSvgIconStyles } from '@domain/entities/ZenSvgIconStyles'

@Component({
    selector: 'zen-svg-icon',
    templateUrl: './svg-icon.component.html',
    styleUrls: ['./svg-icon.component.scss'],
})
export class SvgIconComponent implements OnChanges {

    @Input() icon: ZenSvgIcons = ZenSvgIcons.menu
    @Input() style: ZenSvgIconStyles = ZenSvgIconStyles.normal

    private urlSuffix = this.calculateUrlSuffix()
    private assetName = this.calculateAssetName()

    zenSvgIcons = ZenSvgIcons
    zenSvgIconStyles = ZenSvgIconStyles

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.style) {
            this.urlSuffix = this.calculateUrlSuffix()
        }

        if (changes.icon) {
            this.assetName = this.calculateAssetName()
        }
    }

    protected get svgUrl(): string {
        return `/assets/svg-icons/${this.assetName}${this.urlSuffix}`
    }

    private calculateAssetName(): string {
        return [
            ZenSvgIcons.bell,
            ZenSvgIcons.menu,
            ZenSvgIcons.info,
            ZenSvgIcons.filter,
            ZenSvgIcons.share,
            ZenSvgIcons.shareIOS,
            ZenSvgIcons.heart,
            ZenSvgIcons.article,
            ZenSvgIcons.envelope,
            ZenSvgIcons.radioOff,
            ZenSvgIcons.radioOn,
            ZenSvgIcons.checkboxOn,
            ZenSvgIcons.checkboxOff,
            ZenSvgIcons.play,
            ZenSvgIcons.pause,
            ZenSvgIcons.rev,
            ZenSvgIcons.fwd,
            ZenSvgIcons.replay,
            ZenSvgIcons.chevronLeft,
            ZenSvgIcons.chevronRight,
            ZenSvgIcons.chevronUp,
            ZenSvgIcons.chevronDown,
            ZenSvgIcons.headphone,
            ZenSvgIcons.search,
            ZenSvgIcons.list,
            ZenSvgIcons.cross,
            ZenSvgIcons.logoGoogleCircled,
            ZenSvgIcons.envelopeCircled,
            ZenSvgIcons.logoFacebookCircled,
            ZenSvgIcons.logoFacebook,
            ZenSvgIcons.logoGoogle,
            ZenSvgIcons.logoZenCool,
            ZenSvgIcons.logoInstagram,
            ZenSvgIcons.roundedArrowRight,
            ZenSvgIcons.crossRounded,
            ZenSvgIcons.successRounded,
            ZenSvgIcons.tagFokusz,
            ZenSvgIcons.tagEgeszseg,
            ZenSvgIcons.tagTisztanlatas,
            ZenSvgIcons.tagBurnout,
            ZenSvgIcons.tagElszakadas,
            ZenSvgIcons.tagNyugalom,
            ZenSvgIcons.tagOnbizalom,
            ZenSvgIcons.tagTeremtes,
            ZenSvgIcons.tagBoldogsag,
            ZenSvgIcons.tagAlvas,
            ZenSvgIcons.tagSzerelem,
            ZenSvgIcons.tagSzeretet,
            ZenSvgIcons.catMeditaciok,
            ZenSvgIcons.catEloadasok,
            ZenSvgIcons.catGyakorlatok,
            ZenSvgIcons.accountCircle,
            ZenSvgIcons.login,
            ZenSvgIcons.logout,
            ZenSvgIcons.people,
            ZenSvgIcons.document,
            ZenSvgIcons.recentActivity,
            ZenSvgIcons.cog,
            ZenSvgIcons.startPlay,
            ZenSvgIcons.calendar,
            ZenSvgIcons.rocket,
            ZenSvgIcons.sun,
        ].includes(this.icon)
            ? this.icon
            : ZenSvgIcons.menu
    }

    private calculateUrlSuffix(): string {
        switch (this.style) {
            case ZenSvgIconStyles.normal:
                return '.svg'
            case ZenSvgIconStyles.active:
                return '_active.svg'
            case ZenSvgIconStyles.inactive:
                return '_inactive.svg'
            case ZenSvgIconStyles.background:
                return '_background.svg'
            case ZenSvgIconStyles.accent:
                return '_accent.svg'
            default:
                return '.svg'

        }
    }

}
