import { Component, Input } from '@angular/core'
import { ZenSvgIconSizes } from '@domain/entities/ZenSvgIconSizes'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'
import { ZenSvgIconStyles } from '@domain/entities/ZenSvgIconStyles'

@Component({
    selector: 'zen-svg-icon-round',
    templateUrl: './svg-icon-round.component.html',
    styleUrls: ['./svg-icon-round.component.scss'],
})
export class SvgIconRoundComponent {

    @Input() backgroundColor = 'black'
    @Input() icon: ZenSvgIcons = ZenSvgIcons.menu
    @Input() size: ZenSvgIconSizes = ZenSvgIconSizes.normal
    @Input() style: ZenSvgIconStyles = ZenSvgIconStyles.normal

    zenSvgIconSizes = ZenSvgIconSizes
}
