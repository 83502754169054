import { Component, EventEmitter, OnInit, Output } from '@angular/core'

@Component({
  selector: 'zen-home-card',
  templateUrl: './home-card.component.html',
  styleUrls: ['./home-card.component.scss'],
})
export class HomeCardComponent implements OnInit {

  @Output()
  clicked = new EventEmitter<void>()

  constructor() {
  }

  ngOnInit(): void {
  }

}
