<div class="overlay-container" [class.visible]="visible">

    <zen-page-layout [addSidePaddings]="false">

        <div class="header">

            <div class="placeholder"></div>

            <div class="title">{{ title }}</div>

            <div class="header-right placeholder">

                <div class="placeholder"></div>

                <zen-svg-icon-btn (pressed)="closed.emit()">
                    <zen-svg-icon [icon]="zenSvgIcons.cross"></zen-svg-icon>
                </zen-svg-icon-btn>
            </div>

        </div>

        <div class="content">

            <div class="inner-content">
                <ng-content></ng-content>
            </div>

        </div>

    </zen-page-layout>

</div>
