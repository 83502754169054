import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule } from '@angular/router'
import { redirectUnauthorizedToLogin, RoutePaths } from '@/app/routes'
import { AuthGuard } from '@angular/fire/auth-guard'
import { HomeModule } from '@/app/home/home.module'
import { LoginModule } from '@/app/login/login.module'
import { AboutUsComponent } from '@/app/components/_screens/about-us/about-us.component'
import { TermsComponent } from '@/app/components/_screens/terms/terms.component'
import { PrivacyPolicyComponent } from '@/app/components/_screens/privacy-policy/privacy-policy.component'

@NgModule({
    imports: [RouterModule.forRoot([
        {
            path: RoutePaths.pageHome,
            // loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
            loadChildren: () => HomeModule,
        },
        {
            path: RoutePaths.recordings,
            loadChildren: () => import('./recordings/recordings.module').then(m => m.RecordingsModule),
            // canActivate: [AuthGuard],
            data: {
                // authGuardPipe: redirectUnauthorizedToLogin,
            },
        },
        {
            path: RoutePaths.mindfitness,
            loadChildren: () => import('./mindfitness/mindfitness.module').then(m => m.MindfitnessModule),
            // canActivate: [AuthGuard],
            data: {
                // authGuardPipe: redirectUnauthorizedToLogin,
            },
        },
        {
            path: RoutePaths.login,
            loadChildren: () => LoginModule,
        },
        {
            path: RoutePaths.admin,
            loadChildren: () => import('./features/admin/admin.module').then(m => m.AdminModule),
            canActivate: [AuthGuard],
            data: {
                authGuardPipe: redirectUnauthorizedToLogin,
            },
        },
        {
            path: RoutePaths.adminMindfitness,
            loadChildren: () => import('@/app/views/admin-mindfitness-view/admin-mindfitness-view-routing.module').then(m => m.AdminMindfitnessViewRoutingModule),
            canActivate: [AuthGuard],
            data: {
                authGuardPipe: redirectUnauthorizedToLogin,
            },
        },
        {
            path: RoutePaths.aboutUs,
            component: AboutUsComponent,
            data: {
                zenRouteConfig: {
                    toolbarEnabled: true,
                    sidebarEnabled: true,
                    bottomNavEnabled: false,
                    routeTitle: 'Rólunk',
                },
            },
        },
        {
            path: RoutePaths.termsAndConditions,
            component: TermsComponent,
            data: {
                zenRouteConfig: {
                    toolbarEnabled: true,
                    sidebarEnabled: true,
                    bottomNavEnabled: false,
                    routeTitle: 'Felhasználási Feltételek',
                },
            },
        },
        {
            path: RoutePaths.privacyPolicy,
            component: PrivacyPolicyComponent,
            data: {
                zenRouteConfig: {
                    toolbarEnabled: true,
                    sidebarEnabled: true,
                    bottomNavEnabled: false,
                    routeTitle: 'Adatvédelmi Szabályzat',
                },
            },
        },
        {
            path: '',
            pathMatch: 'full',
            redirectTo: RoutePaths.pageHome,
        },
        {
            path: '**',
            redirectTo: RoutePaths.pageHome,
        },
        // {
        //   path: 'player',
        //   loadChildren: () => import('./features/audio-player/audio-player.module').then(m => m.AudioPlayerModule),
        //   canActivate: [AuthGuard],
        //   data: {
        //     authGuardPipe: redirectUnauthorizedToLogin,
        //     zenRouteConfig: {
        //       toolbarEnabled: true,
        //       sidebarEnabled: true,
        //       bottomNavEnabled: true,
        //       routeTitle: 'Lejátszó',
        //     },
        //   },
        // },
        // {
        //   path: RoutePaths.welcome,
        //   loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomeModule),
        //   canActivate: [AuthGuard],
        //   data: {
        //     authGuardPipe: redirectLoggedInToHome,
        //     zenRouteConfig: {
        //       toolbarEnabled: false,
        //       sidebarEnabled: false,
        //       bottomNavEnabled: false,
        //       desktopFooterEnabled: true,
        //     },
        //   },
        // },
        // {
        //   path: RoutePaths.profile,
        //   loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
        //   canActivate: [AuthGuard],
        //   data: {
        //     authGuardPipe: redirectUnauthorizedToLogin,
        //     zenRouteConfig: {
        //       toolbarEnabled: true,
        //       sidebarEnabled: true,
        //       bottomNavEnabled: true,
        //     },
        //   },
        // },
    ], {
        enableTracing: false, // !environment.production, // <-- debugging purposes only
        preloadingStrategy: PreloadAllModules, // Admin modules are for logged in admin users only - TODO implement selective preloading
    })],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
