import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ZenButtonStyles } from '@domain/entities/ZenButtonStyles'
import { ZenButtonSizes } from '@domain/entities/ZenButtonSizes'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'
import { ZenSvgGraphics } from '@domain/entities/ZenSvgGraphics'
import { ZenSvgIconStyles } from '@domain/entities/ZenSvgIconStyles'

@Component({
    selector: 'zen-home-slider',
    templateUrl: './home-slider.component.html',
    styleUrls: ['./home-slider.component.scss'],
})
export class HomeSliderComponent {

    @Input() title?: string
    @Input() visible?: boolean | null = false
    @Input() noHeader?: boolean = false
    @Output() closed = new EventEmitter<void>()

    activeSlide = 1
    maxSlide = 4

    zenSvgGraphics = ZenSvgGraphics
    zenSvgIcons = ZenSvgIcons
    zenSvgIconStyles = ZenSvgIconStyles
    zenButtonSizes = ZenButtonSizes
    zenButtonStyles = ZenButtonStyles

    nextSlide() {
        if (this.activeSlide === this.maxSlide) {
            this.dismiss()
        } else {
            this.activeSlide++
        }
    }

    prevSlide() {
        this.activeSlide = this.activeSlide === 1
            ? 1
            : this.activeSlide - 1
    }

    ctaNext() {
        if (this.activeSlide === this.maxSlide) {
            this.dismiss()
        } else {
            this.nextSlide()
        }
    }

    dismiss() {
        this.closed.emit()
        this.activeSlide = 1
    }
}
