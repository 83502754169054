import { Timestamp } from '@angular/fire/firestore';
import { IUserSubscriptionData, UserSubscriptionData } from '@domain/entities/user-subscription/UserSubscriptionData';

export interface FSCUserSubscriptionData extends IUserSubscriptionData {
    expiresAt: Timestamp
}

export const mapToFSCUserSubscriptionData = (userSubscriptionData: UserSubscriptionData): FSCUserSubscriptionData => ({
    ...userSubscriptionData,
    expiresAt: Timestamp.fromDate(new Date(userSubscriptionData.expiresAt)),
})

export const mapFromFSCUserSubscriptionData = (fscUserSubscriptionData: FSCUserSubscriptionData): UserSubscriptionData => ({
    ...fscUserSubscriptionData,
    expiresAt: fscUserSubscriptionData.expiresAt.toDate().toISOString(),
})
