import { FieldValue, Timestamp } from '@angular/fire/firestore';
import { IMindfitnessContent, MindfitnessContent } from '@domain/entities/mindfitness';

export interface FSCMindfitnessContentUpdate extends IMindfitnessContent {
    updatedAt: FieldValue;
}

export interface FSCMindfitnessContent extends IMindfitnessContent {
    updatedAt: Timestamp;
}

export const mapToFSCMindfitnessContent = (mindfitnessContent: MindfitnessContent): FSCMindfitnessContent => ({
    ...mindfitnessContent,
    updatedAt: Timestamp.fromDate(new Date(mindfitnessContent.updatedAt)),
})

export const mapFromFSCMindfitnessContent = (fscMindfitnessContent: FSCMindfitnessContent): MindfitnessContent => ({
    ...fscMindfitnessContent,
    updatedAt: fscMindfitnessContent.updatedAt.toDate().toISOString(),
})
