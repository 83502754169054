import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HomeRoutingModule } from './home-routing.module'
import { ComponentsModule } from '@/app/components/components.module'
import { HomeComponent } from './home.component'
import { HomeGreetingComponent } from './home-greeting/home-greeting.component'
import { HomeCardComponent } from './home-card/home-card.component'
import { HomeMindfitnessTeaserComponent } from './home-mindfitness-teaser/home-mindfitness-teaser.component'
import { HomeSliderComponent } from './home-slider/home-slider.component';
import { ReactiveFormsModule } from '@angular/forms'

@NgModule({
    declarations: [
        HomeComponent,
        HomeGreetingComponent,
        HomeCardComponent,
        HomeMindfitnessTeaserComponent,
        HomeSliderComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        HomeRoutingModule,
        ComponentsModule,
    ],
})
export class HomeModule {
}
