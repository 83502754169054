import { Component, Input } from '@angular/core'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'

@Component({
    selector: 'zen-feedback-card-success',
    templateUrl: './feedback-card-success.component.html',
    styleUrls: ['./feedback-card-success.component.scss'],
})
export class FeedbackCardSuccessComponent {

    @Input() title = ''

    svgIcons = ZenSvgIcons

}
