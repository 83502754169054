import { Component, Input } from '@angular/core'

@Component({
    selector: 'zen-page-layout',
    templateUrl: './page-layout.component.html',
    styleUrls: ['./page-layout.component.scss'],
})
export class PageLayoutComponent {

    @Input() addSidePaddings = true
    @Input() addHeaderPaddings = true
    @Input() addFooterPaddings = true
}
