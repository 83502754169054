import {
    FSCUserSubscriptionData,
    mapFromFSCUserSubscriptionData,
    mapToFSCUserSubscriptionData,
} from '@/types/firestore/collections/user-data-subscription/FSCUserSubscriptionData';
import {
    IUserSubscriptionProfile,
    UserSubscriptionProfile,
} from '@domain/entities/user-subscription/UserSubscriptionProfile';

/**
 * Documents under firestore
 * /user-data-subscription/{userId}
 */
export interface FSCUserSubscriptionProfile extends IUserSubscriptionProfile {
    currentSubscription: FSCUserSubscriptionData | null
}

export const mapToFSCUserSubscriptionProfile = (userSubscriptionProfile: UserSubscriptionProfile): FSCUserSubscriptionProfile => ({
    ...userSubscriptionProfile,
    currentSubscription: userSubscriptionProfile.currentSubscription
        ? mapToFSCUserSubscriptionData(userSubscriptionProfile.currentSubscription)
        : null,
})

export const mapFromFSCUserSubscriptionProfile = (fscUserSubscriptionsProfile: FSCUserSubscriptionProfile): UserSubscriptionProfile => ({
    ...fscUserSubscriptionsProfile,
    currentSubscription: fscUserSubscriptionsProfile.currentSubscription
        ? mapFromFSCUserSubscriptionData(fscUserSubscriptionsProfile.currentSubscription)
        : null,
})
