import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ZenSvgIconSizes } from '@domain/entities/ZenSvgIconSizes'
import { ZenButtonStyles } from '@domain/entities/ZenButtonStyles';

@Component({
    selector: 'zen-svg-icon-btn',
    templateUrl: './svg-icon-btn.component.html',
    styleUrls: ['./svg-icon-btn.component.scss'],
})
export class SvgIconBtnComponent {

    @Input() label = ''
    @Input() encircled = false
    @Input() propagateEvents = false
    @Input() iconSize: ZenSvgIconSizes = ZenSvgIconSizes.normal
    @Input() buttonStyle: ZenButtonStyles = ZenButtonStyles.primary

    @Output() pressed = new EventEmitter<void>()

    protected readonly zenSvgIconSizes = ZenSvgIconSizes
    protected readonly zenButtonStyles = ZenButtonStyles

    clicked(event: Event): void {
        if (!this.propagateEvents) {
            event.stopPropagation()
        }
        this.pressed.emit()
    }

}
