import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer, userSubscriptionFeatureKey } from './store/reducer';
import { UserSubscriptionEffects } from './store/effects';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(userSubscriptionFeatureKey, reducer),
        EffectsModule.forFeature([UserSubscriptionEffects]),
    ],
})
export class UserSubscriptionModule {
}
