<zen-page-layout [addSidePaddings]="false"
                 [addHeaderPaddings]="false"
                 [addFooterPaddings]="false"
>

    <ng-content select="[container]"></ng-content>

    <div class="layout-player">

        <div class="top-half"
             [style.background-image]="'url(\'' + backgroundImgUrl + '\')'"
             [class.player-visible]="playerVisible"
             (click)="onTopSectionCLicked()"
        >

            <div class="top-content">

                <ng-content select="[top-content]"></ng-content>

            </div>

            <div class="bottom-gradient"></div>

        </div>

        <div class="bottom-half" [class.player-visible]="playerVisible">

            <ng-content select="[bottom-content]"></ng-content>

        </div>

    </div>

</zen-page-layout>
