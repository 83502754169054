<div class="container"
     matRipple="true"
     (click)="moreClicked.emit()"
>

    <div class="wrapper">

        <div class="icon">
            <zen-svg-icon [icon]="zenSvgIcons.roundedArrowRight"></zen-svg-icon>
        </div>

    </div>

</div>
