import { ZenSvgIcons } from './ZenSvgIcons'

export enum ZenCategories {
  meditaciok = 'meditaciok',
  eloadasok = 'eloadasok',
  gyakorlatok = 'gyakorlatok',
}

export interface ZenCategory {
  id: ZenCategories;
  label: string;
  icon: ZenSvgIcons;
}

export const mapToZenCategory = (category: ZenCategories): ZenCategory => {
  switch (category) {
    case ZenCategories.meditaciok:
      return {
        id: ZenCategories.meditaciok,
        label: 'Meditációk',
        icon: ZenSvgIcons.catMeditaciok,
      }

    case ZenCategories.eloadasok:
      return {
        id: ZenCategories.eloadasok,
        label: 'Tanítások',
        icon: ZenSvgIcons.catEloadasok,
      }

    case ZenCategories.gyakorlatok:
      return {
        id: ZenCategories.gyakorlatok,
        label: 'Gyakorlatok',
        icon: ZenSvgIcons.catGyakorlatok,
      }
  }
}
