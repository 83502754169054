import { Component, ElementRef, HostBinding, HostListener, Input, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'zen-app-layout-relative-ui',
    templateUrl: './app-layout-relative-ui.component.html',
    styleUrls: ['./app-layout-relative-ui.component.scss'],
})
export class AppLayoutRelativeUiComponent implements OnInit {


    @Input() headerVisible? = false
    @Input() footerVisible? = false
    @Input() menuVisible? = false
    @Input() notificationsVisible? = false
    @Input() loginVisible? = false

    @HostBinding('style.--measured-height')
    measuredHeight?: string // TODO check if still needed

    @ViewChild('mainContent')
    private mainContentDiv!: ElementRef<HTMLElement>

    private resizeID = 0

    get upscaleForHeight(): boolean {
        return window.outerHeight > 667
    }

    @HostListener('window:resize', ['$event'])
    private setMeasuredHeight() {
        window.clearTimeout(this.resizeID)
        this.resizeID = window.setTimeout(() => this.measuredHeight = `${window.innerHeight}px`, 100)
    }

    ngOnInit(): void {
        this.setMeasuredHeight()
    }

    scrollToTop(): void {
        // Scrolling back to the top
        // Reference: https://stackoverflow.com/questions/48048299/angular-5-scroll-to-top-on-every-route-click/48048822
        if (this.mainContentDiv) {
            this.mainContentDiv.nativeElement.scrollTop = 0
        }
    }
}
