import { MindfitnessAssignedMedia } from '../../entities/user-mindfitness/MindfitnessAssignedMedia';
import { DateTime } from 'luxon';

interface Params {
    assignedMedia: MindfitnessAssignedMedia | null
    assignedMediaExpired: boolean
    introCompleted: boolean
    subscriptionActive: boolean
    currentDateTime: string
}

export const canDailyContentBeRequested = (params: Params): boolean => {
    const { assignedMedia, assignedMediaExpired, introCompleted, subscriptionActive, currentDateTime } = params

    if (introCompleted && subscriptionActive) {
        if (assignedMedia) {
            const assignedAt = DateTime.fromISO(assignedMedia.assignedAt).toUTC()
            const now = DateTime.fromISO(currentDateTime).toUTC()
            return assignedMediaExpired || !assignedAt.hasSame(now, 'day')
        } else {
            return true
        }
    } else {
        return false
    }
    
}
