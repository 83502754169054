import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { mediaContentFeatureKey, reducer } from './store/reducer/media-content.reducer'
import { EffectsModule } from '@ngrx/effects'
import { MediaContentEffects } from '@/app/features/media-content/store/effects/media-content.effects'
import { MediaContentProgressModule } from '@/app/features/media-content-progress/media-content-progress.module'


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(mediaContentFeatureKey, reducer),
        EffectsModule.forFeature([MediaContentEffects]),
        MediaContentProgressModule,
    ],
    exports: [],
})
export class MediaContentModule {
}
