import { MindfitnessAssignedMedia } from '../../entities/user-mindfitness/MindfitnessAssignedMedia';
import { MindfitnessMediaTypes } from '../../entities/mindfitness';
import { DateTime } from 'luxon';

interface Params {
    assignedMedia: MindfitnessAssignedMedia | null
    assignedMediaCompleted: boolean
    currentDateTime: string
}

export const isAssignedMediaExpired = (params: Params): boolean => {
    const { assignedMedia, assignedMediaCompleted, currentDateTime } = params

    if (assignedMedia) {
        if (assignedMedia.type === MindfitnessMediaTypes.initiation) {
            return assignedMediaCompleted
        } else {
            const expiresAt = DateTime.fromISO(assignedMedia.assignedAt).plus({ hour: 24 })
            const now = DateTime.fromISO(currentDateTime)
            return expiresAt < now
        }
    } else {
        return true
    }

}
