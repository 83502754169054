import { Component, HostBinding, Input } from '@angular/core'

@Component({
  selector: 'zen-media-card-timeline',
  templateUrl: './media-card-timeline.component.html',
  styleUrls: ['./media-card-timeline.component.scss'],
})
export class MediaCardTimelineComponent {

  @HostBinding('style.--progress-percent')
  @Input() progress = '50%'

  constructor() {
  }

}
