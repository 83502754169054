import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { AuthService } from '../../auth.service'
import {
    doStartLoginFacebook,
    doStartLoginGoogle,
    doStartLogout,
    loginError,
    loginSuccess,
    logoutComplete,
} from '../actions';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RoutePaths } from '@/app/routes';

@Injectable()
export class AuthEffects {

    doStartLoginGoogle$ = createEffect(() => this.actions$.pipe(
        ofType(doStartLoginGoogle),
        map(() => {
            void this.service.logInWithGoogle()
        }),
    ), { dispatch: false })

    doStartLoginFacebook$ = createEffect(() => this.actions$.pipe(
        ofType(doStartLoginFacebook),
        map(() => {
            void this.service.logInWithFacebook()
        }),
    ), { dispatch: false })

    loginComplete$ = createEffect(() => this.actions$.pipe(
        ofType(loginSuccess, loginError),
        map(() => {
            void this.router.navigate([RoutePaths.login])
        }),
    ), { dispatch: false })

    doStartLogout$ = createEffect(() => this.actions$.pipe(
        ofType(doStartLogout),
        map(() => {
            void this.service.logOut()
        }),
    ), { dispatch: false })

    logoutComplete$ = createEffect(() => this.actions$.pipe(
        ofType(logoutComplete),
        map(() => {
            void this.router.navigate([RoutePaths.pageHome])
        }),
    ), { dispatch: false })


    constructor(private actions$: Actions, private service: AuthService, private router: Router) {
    }

}
