<zen-overlay-wrapper [visible]="(this.settingsActive$ | async) || false">

    <zen-page-layout [addSidePaddings]="false" [addFooterPaddings]="false">

        <div class="content">

            <zen-settings-user-logged-in *ngIf="currentUser$ | async; else showLogin1"
                                         [icon]="zenSvgIcons.accountCircle"
                                         [loggedInUser]="currentUser$ | async"
                                         (logOut)="logout()"
            >
            </zen-settings-user-logged-in>

            <div class="divider"></div>

            <ng-template #showLogin1>
                <zen-settings-item [routerLink]="routePaths.login"
                                   [icon]="zenSvgIcons.login"
                >
                    Bejelentkezés
                </zen-settings-item>
            </ng-template>


            <ng-template [ngIf]="(currentUser$ | async)?.role === zenUserRoles.admin && (adminNavItems$ | async)">
                <zen-settings-item *ngFor="let navItem of adminNavItems$ | async"
                                   [routerLink]="navItem.routerLink"
                                   [icon]="navItem.icon"
                >
                    {{ navItem.label }}
                </zen-settings-item>
            </ng-template>

            <ng-template [ngIf]="(secondaryNavItems$ | async)">
                <zen-settings-item *ngFor="let navItem of secondaryNavItems$ | async"
                                   [routerLink]="navItem.routerLink"
                                   [icon]="navItem.icon"
                >
                    {{ navItem.label }}
                </zen-settings-item>
            </ng-template>


            <div class="divider"></div>


            <zen-settings-link
                    [icon]="zenSvgIcons.logoFacebook"
                    [url]="'https://www.facebook.com/ZenCool-103153272170482'"
            >
                facebook.com/zencoolapp
            </zen-settings-link>

            <zen-settings-link
                    [icon]="zenSvgIcons.logoInstagram"
                    [url]="'https://www.instagram.com/zencoolapp/'"
            >
                instagram.com/zencoolapp
            </zen-settings-link>


            <!--        <div class="divider"></div>-->


            <!--        <zen-settings-item [icon]="zenSvgIcons.cross">-->
            <!--            Felhasználói fiók törlése-->
            <!--        </zen-settings-item>-->

        </div>

    </zen-page-layout>

</zen-overlay-wrapper>
