import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomeComponent } from './home.component'

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      zenRouteConfig: {
        toolbarEnabled: true,
        sidebarEnabled: true,
        bottomNavEnabled: false,
        routeTitle: 'Főoldal',
      },
    },
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {
}
