import { createAction } from '@ngrx/store'
import { UserMindfitnessProfile } from '@domain/entities/user-mindfitness/UserMindfitnessProfile';

export const loadMindfitnessProfile = createAction(
    '[Mindfitness] Profile load - START',
)

export const loadMindfitnessProfileError = createAction(
    '[Mindfitness] Profile load - ERROR',
)

export const loadMindfitnessProfileSuccess = createAction(
    '[Mindfitness] Profile load - SUCCESS',
    (userMindfitnessProfile: UserMindfitnessProfile | null) => ({ userMindfitnessProfile }),
)

export const unloadMindfitnessProfile = createAction(
    '[Mindfitness] Profile - UNLOAD',
)

export const setInfoOverlayActive = createAction(
    '[Mindfitness] infoOverlayActive - SET',
    (active: boolean) => ({ active }),
)

export const markIntroCompleted = createAction(
    '[Mindfitness] mark intro - COMPLETED',
)

export const markDailyContentCompleted = createAction(
    '[Mindfitness] mark dailyContent - COMPLETED',
)

export const markInitiationContentCompleted = createAction(
    '[Mindfitness] mark initiationContent - COMPLETED',
)

export const requestNewContent = createAction(
    '[Mindfitness] new content request - START',
)

export const requestNewContentDone = createAction(
    '[Mindfitness] new content request - DONE',
)

export const updateLastDateTime = createAction(
    '[Mindfitness] lastDateTime - UPDATE',
    (isoDateTime: string) => ({ isoDateTime }),
)
