import { createAction } from '@ngrx/store'
import { ZenRouteConfig } from '@/types';

export const toggleNotificationsModal = createAction(
    '[Navigation] Navigation - TOGGLE NOTIFICATIONS_MODAL',
)

export const toggleSettingsModal = createAction(
    '[Navigation] Navigation - TOGGLE SETTINGS_MODAL',
)

export const toggleLoginModal = createAction(
    '[Navigation] Navigation - TOGGLE LOGIN_MODAL',
)

export const closeAllModals = createAction(
    '[Navigation] Navigation - CLOSE ALL MODALS',
)

export const setLastRoute = createAction(
    '[Navigation] Navigation - SET LAST_ROUTE',
    (lastRoute: string) => ({ lastRoute }),
)

export const setActiveRouteConfig = createAction(
    '[Navigation] Navigation - SET ACTIVE_ROUTE_CONFIG',
    (activeRouteConfig: ZenRouteConfig | null) => ({ activeRouteConfig }),
)

export const doNavigateBack = createAction(
    '[Navigation] Navigation - DO NAVIGATE BACK',
)

export const doNavigateHome = createAction(
    '[Navigation] Navigation - DO NAVIGATE HOME',
)
