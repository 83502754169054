import { createReducer, on } from '@ngrx/store'
import * as MindfitnessActions from '../actions'
import { LoadStatuses } from '@domain/entities/mindfitness';
import { environment } from '@/environments/environment';
import { UserMindfitnessProfile } from '@domain/entities/user-mindfitness/UserMindfitnessProfile';

export const mindFitnessFeatureKey = 'mindFitness'

export interface MindfitnessState {
    /**
     * Controls that Mindfitness as a feature is enabled / available at all
     */
    featureEnabled: boolean

    profile: UserMindfitnessProfile | null;
    profileLoadStatus: LoadStatuses
    requestContentInProgress: boolean
    infoOverlayActive: boolean
    lastDateTime: string
}

export const initialState: MindfitnessState = {
    featureEnabled: environment.features.mindfitness || false,
    profile: null,
    profileLoadStatus: LoadStatuses.unloaded,
    requestContentInProgress: false,
    infoOverlayActive: false,
    lastDateTime: new Date().toISOString(),
}

export const reducer = createReducer(
    initialState,

    on(MindfitnessActions.unloadMindfitnessProfile, (state): MindfitnessState => ({
        ...state,
        profile: null,
        profileLoadStatus: LoadStatuses.unloaded,
    })),

    on(MindfitnessActions.loadMindfitnessProfile, (state): MindfitnessState => ({
        ...state,
        profile: null,
        profileLoadStatus: LoadStatuses.loading,
    })),

    on(MindfitnessActions.loadMindfitnessProfileError, (state): MindfitnessState => ({
        ...state,
        profile: null,
        profileLoadStatus: LoadStatuses.error,
    })),

    on(MindfitnessActions.loadMindfitnessProfileSuccess, (state, { userMindfitnessProfile }): MindfitnessState => ({
        ...state,
        profile: userMindfitnessProfile,
        profileLoadStatus: LoadStatuses.success,
        infoOverlayActive: !userMindfitnessProfile?.infoDismissed,
    })),

    on(MindfitnessActions.setInfoOverlayActive, (state, { active }): MindfitnessState => ({
        ...state,
        infoOverlayActive: active,
    })),

    on(MindfitnessActions.requestNewContent, (state): MindfitnessState => ({
        ...state,
        requestContentInProgress: true,
    })),

    on(MindfitnessActions.requestNewContentDone, (state): MindfitnessState => ({
        ...state,
        requestContentInProgress: false,
    })),

    on(MindfitnessActions.updateLastDateTime, (state, { isoDateTime }): MindfitnessState => ({
        ...state,
        lastDateTime: isoDateTime,
    })),
)
