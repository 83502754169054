import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { adminMindfitnessFeatureKey, reducer } from './store/reducer';
import { AdminMindfitnessEffects } from './store/effects';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterOutlet,
        StoreModule.forFeature(adminMindfitnessFeatureKey, reducer),
        EffectsModule.forFeature([AdminMindfitnessEffects]),
    ],
})
export class AdminMindfitnessFeatureModule {
}
