import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  loadMediaContentsStart,
  loadMediaContentsSuccess,
} from '@/app/features/media-content/store/actions/media-content.actions'
import { catchError, EMPTY, mergeMap } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpsMediaContentProviderService } from '@/app/features/media-content/https-media-content-provider.service'
import { Store } from '@ngrx/store'


@Injectable()
export class MediaContentEffects {

  loadMediaContents$ = createEffect(() => this.actions$.pipe(
    ofType(loadMediaContentsStart),
    mergeMap(() => this.mediaService.getMediaLibrary()
      .pipe(
        map(contentList => loadMediaContentsSuccess(contentList)),
        catchError(() => EMPTY),
      )),
  ))

  constructor(
    private actions$: Actions,
    private mediaService: HttpsMediaContentProviderService,
    private store: Store,
  ) {

    this.store.dispatch(loadMediaContentsStart())
  }

}
