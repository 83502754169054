import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, throttleTime } from 'rxjs/operators'
import { updateNickName } from '../actions'
import { AfsCurrentUserService } from '../../afs-current-user.service'

@Injectable()
export class CurrentUserEffects {

    updateNickName$ = createEffect(() => this.actions$.pipe(
        ofType(updateNickName),
        throttleTime(1000),
        map(({ nickName }) => this.service.updateCurrentUserNickName(nickName)),
    ), { dispatch: false })

    constructor(private actions$: Actions, private service: AfsCurrentUserService) {
    }

}
