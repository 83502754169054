import { getIntroductionMindfitnessContent, MindfitnessContent, MindfitnessMediaTypes } from '../mindfitness';

export interface IMindfitnessAssignedMedia {
    type: MindfitnessMediaTypes
    content: MindfitnessContent
    assignedAt: any
}

export interface MindfitnessAssignedMedia extends IMindfitnessAssignedMedia {
    assignedAt: string
}

export const getIntroductionMindfitnessAssignedMedia = (): MindfitnessAssignedMedia => ({
    type: MindfitnessMediaTypes.introduction,
    assignedAt: new Date().toISOString(),
    content: getIntroductionMindfitnessContent(),
})
