export enum ZenSvgGraphics {

    zencoolLogoWithText = 'zencool_logo_with_text',
    zencoolLogoCircle = 'zencool-logo-circle',
    taraMethodLogo = 'tara-method-logo',
    headphoneBuddhaCircle = 'headphone-buddha-circle',
    headphoneBuddhaCardWrapped = 'headphone-buddha_card_wrapped',
    templeExteriorCardWrapped = 'temple-exterior_card_wrapped',
    templeExteriorPageBgCircle = 'temple-exterior_page-bg-circle',
    templeExteriorPageBgWide = 'temple-exterior_page-bg-wide',
}
