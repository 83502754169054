import { Injectable, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import {
  collection,
  CollectionReference,
  collectionSnapshots,
  doc,
  Firestore,
  increment,
  limit,
  query,
  runTransaction,
  serverTimestamp,
} from '@angular/fire/firestore'
import { Auth, user } from '@angular/fire/auth'
import { FS_COLLECTION_USERS } from '@firestore/collections/users'
import { FS_COLLECTION_USERS_SUB_MEDIA_CONTENT_PROGRESS } from '@firestore/collections/users/media-content-progress'
import {
  FSCZenUserMediaContentProgress,
  mapBackToZenUserMediaContentProgress,
  mapToFSCZenUserMediaContentProgress,
} from '@/types/firestore/collections/users/media-content-progress'
import { loadedList } from './store/actions'
import { ZenUserMediaContentProgress } from '@domain/entities/user/ZenUserMediaContentProgress'

interface UpdateFieldParams {
  mediaContentId: string;
  field: 'progress' | 'completed' | 'liked';
  value: boolean | number;
}

@Injectable({
  providedIn: 'root',
})
export class AfsMediaContentProgressService implements OnDestroy {
  private mediaContentProgressSubscription: Subscription | null = null
  private uid: string | null = null

  constructor(private firestore: Firestore, private store: Store, private auth: Auth) {
    user(auth).pipe()
      .subscribe(async (authUser) => {
        if (authUser) {
          // console.log('AfsMediaContentProgressService: subscribing with uid', authUser.uid)
          this.uid = authUser.uid
          this.mediaContentProgressSubscription = this.subscribeToUserMediaContentProgress(authUser.uid)

        } else {
          // console.log('AfsMediaContentProgressService: unsubscribing')
          this.uid = null
          this.unsubscribeFromUserMediaContentProgress()
        }
      })
  }

  ngOnDestroy(): void {
    // console.log('AfsMediaContentProgressService: ngOnDestroy')
    this.unsubscribeFromUserMediaContentProgress()
  }

  setMediaContentProgress(mediaContentId: string, progress: number): Promise<void> {
    return this.updateDocFieldWith({
      mediaContentId,
      field: 'progress',
      value: progress,
    })
  }

  setMediaContentCompleted(mediaContentId: string, completed: boolean): Promise<void> {
    return this.updateDocFieldWith({
      mediaContentId,
      field: 'completed',
      value: completed,
    })
  }

  setMediaContentLiked(mediaContentId: string, liked: boolean): Promise<void> {
    return this.updateDocFieldWith({
      mediaContentId,
      field: 'liked',
      value: liked,
    })
  }

  private async updateDocFieldWith({ mediaContentId, field, value }: UpdateFieldParams): Promise<void> {
    if (this.uid) {
      const docRef = doc(this.firestore, FS_COLLECTION_USERS, this.uid, FS_COLLECTION_USERS_SUB_MEDIA_CONTENT_PROGRESS, mediaContentId)

      try {
        await runTransaction(this.firestore, async (transaction) => {
          const docSnap = await transaction.get(docRef)

          if (docSnap.exists()) {
            transaction.update(docRef, field, value)

            if (field === 'progress') {
              transaction.update(docRef, 'lastPlayed', serverTimestamp())
            }

            if (field === 'completed') {
              // When completed, also reset the progress, and increment the playedCount
              transaction.update(docRef, 'progress', 0)
              transaction.update(docRef, 'playCount', increment(1))
            }

          } else {
            const recordToSet: FSCZenUserMediaContentProgress = mapToFSCZenUserMediaContentProgress(new ZenUserMediaContentProgress({
              id: mediaContentId,
              progress: 0,
              completed: false,
              liked: false,
              lastPlayed: null,
              playCount: field === 'completed'
                ? 1
                : 0,
            }))

            transaction.set(docRef, {
              ...recordToSet,
              [field]: value,
            })
          }
        })

      } catch (e) {
        console.log('Error in setMediaContentProgress', { field, value }, e)
      }
    }
  }

  private subscribeToUserMediaContentProgress(userId: string): Subscription | null {
    const q = query(collection(this.firestore,
        FS_COLLECTION_USERS,
        userId,
        FS_COLLECTION_USERS_SUB_MEDIA_CONTENT_PROGRESS) as CollectionReference<FSCZenUserMediaContentProgress>,
      limit(50))

    return collectionSnapshots(q)
      .pipe(
        debounceTime(1000),
        map((items) => items.map((item) => mapBackToZenUserMediaContentProgress(item.data()))),
      ).subscribe((next) => this.store.dispatch(loadedList(next)))
  }

  private unsubscribeFromUserMediaContentProgress(): void {
    if (this.mediaContentProgressSubscription) {
      this.mediaContentProgressSubscription.unsubscribe()
    }
  }
}
