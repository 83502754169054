<div class="layout-container" [class.upscale-for-height]="upscaleForHeight">

    <div *ngIf="headerVisible" class="layout-header">
        <ng-content select="[header]"></ng-content>
    </div>

    <div #mainContent class="layout-content">
        <ng-content></ng-content>
    </div>

    <div *ngIf="footerVisible" class="layout-footer">
        <ng-content select="[footer]"></ng-content>
    </div>

</div>
