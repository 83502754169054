<zen-page-layout [addFooterPaddings]="false">

    <div class="about-us">

        <div class="content">

            <div class="photo"></div>

            <div class="section">

                <p>
                    Az applikáció a <span class="tara-method">TARA METHOD<sup>&reg;</sup></span> módszerét használja
                    fel.
                </p>

                <p>
                    A <span class="tara-method">TARA METHOD<sup>&reg;</sup></span>
                    &nbsp;
                    <span class="highlighted">egy átfogó, komplex szakmai tudás összesített rendszere</span>, amely a
                    buddhista hagyományok és szemléletmód bölcsességét és a kognitív gondolkodás modernkori módszereit,
                    metódusait egyesíti.
                </p>

                <p>
                    A hanganyagokat Veress Szilviától, a <span class="tara-method">TARA METHOD<sup>&reg;</sup></span>
                    vezető trénerétől hallhatod.
                </p>

                <p>
                    Szilvi elkötelezte magát a meditáció és a belső én megismerése iránti tanításnak. Számos tanfolyamot
                    és workshopot tart, hogy megmutassa az embereknek, hogyan tudnak elmélyülni, fejlődni és elindulni
                    saját belső utazásukra. Az alázattal és szeretetteljesen vezetett meditációi segítenek a stressz
                    csökkentésében, az érzelmi egyensúly elérésében, a belső béke és harmónia megteremtésében.
                </p>

                <p>
                    A hanganyagok elkészítésében jelentős szerepet vállalt Hajdu Gábor, a <span class="tara-method">TARA METHOD<sup>&reg;</sup></span>
                    tudatosságfejlesztő trénere. Gábor célja, hogy segítse az embereket abban, hogy ismerjék meg és
                    fogadják el önmagukat, higgyenek magukban, tegyenek saját magukért, teljes harmóniában meg tudják
                    élni az érzelmeiket, merjék vállalni a felelősséget a tetteikért és az érzelmeikért.
                </p>

                <p>
                    Az applikáció használatával te is közelebb kerülsz ehhez az állapothoz.
                </p>

            </div>

            <div class="section">
                <h2>Mit ad a ZenCool rendszeres használata?</h2>
                <p>
                    Egymásra épülő rendszert, egy olyan szemléletmódot, amely nyugalomhoz és az élet szeretetéhez vezet.
                </p>
                <ul>
                    <li>
                        Tudatosság, önismeret, fókuszáltság, érzékszervek erősödése
                    </li>
                    <li>
                        Nyugalom, megértés, vezetői képességek erősödése
                    </li>
                    <li>
                        Érzelmi intelligencia szint emelkedése
                    </li>
                    <li>
                        Harmónia, együttműködési képesség erősödése
                    </li>
                    <li>
                        Jó szeretetteli kapcsolatok, reziliencia szint növekedése
                    </li>
                    <li>
                        Kreativitás erősödése
                    </li>
                    <li>
                        Kiegyensúlyozottság, koncentráltság, hatékony stressz kezelés
                    </li>
                </ul>
            </div>

        </div>

    </div>

</zen-page-layout>
