import { createFeatureSelector, createSelector } from '@ngrx/store'
import { currentUserFeatureKey, CurrentUserState } from '../reducer'

const NICKNAME_PLACEHOLDER = '#becenév'

export const selectCurrentUserState = createFeatureSelector<CurrentUserState>(currentUserFeatureKey)

export const selectCurrentUserLoaded = createSelector(
    selectCurrentUserState,
    (state) => state.loaded,
)

export const selectCurrentUserData = createSelector(
    selectCurrentUserState,
    (state) => state.userData,
)

export const selectCurrentUserNickName = createSelector(
    selectCurrentUserData,
    (userData) => userData?.nickName || NICKNAME_PLACEHOLDER,
)

export const selectCurrentUserNickNamePlaceholder = createSelector(
    selectCurrentUserData,
    (userData) => !userData?.nickName,
)
