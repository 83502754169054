import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
  selector: 'zen-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor {

  @Input() uploadProgress = 0
  @ViewChild('fileInput', { static: true }) fileInput!: ElementRef<HTMLInputElement>

  fileToUpload: File | null = null
  disabled = false

  @HostListener('change', ['$event.target.files'])
  emitFiles(files: FileList) {
    if (files) {
      this.fileToUpload = files.item(0)
      this.onChange(this.fileToUpload)
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event: any) {
    console.log('onBlur', event)
    this.onTouched()
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  writeValue(obj: any): void {
    this.fileInput.nativeElement.value = ''
    this.fileToUpload = null
  }

  private onChange: (_: any) => any = () => {
    console.log('dummy onChange')
  }

  private onTouched: () => any = () => {
    console.log('dummy onTouched')
  }
}
