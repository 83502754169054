import { createReducer, on } from '@ngrx/store'
import * as AuthActions from '../actions';
import { ZenUser } from '@domain/entities/user/ZenUser';

export const authFeatureKey = 'auth'

export enum LOGIN_STATUS {
    success = 'success',
    error = 'error',
}

export interface LastLogin {
    status: LOGIN_STATUS
    errorMessage?: string | null
}

export interface AuthState {
    loginInProgress: boolean
    logoutInProgress: boolean
    lastLogin: LastLogin | null
    currentUser: ZenUser | null
}

const initialState: AuthState = {
    loginInProgress: false,
    logoutInProgress: false,
    lastLogin: null,
    currentUser: null,
}

export const reducer = createReducer(
    initialState,

    on(AuthActions.doStartLoginEmail, (state): AuthState => ({
        ...state,
        loginInProgress: true,
    })),

    on(AuthActions.doStartLoginGoogle, (state): AuthState => ({
        ...state,
        loginInProgress: true,
    })),

    on(AuthActions.doStartLoginFacebook, (state): AuthState => ({
        ...state,
        loginInProgress: true,
    })),

    on(AuthActions.loginSuccess, (state): AuthState => ({
        ...state,
        loginInProgress: false,
        lastLogin: {
            status: LOGIN_STATUS.success,
        },
    })),

    on(AuthActions.loginError, (state, { errorMessage }): AuthState => ({
        ...state,
        loginInProgress: false,
        lastLogin: {
            status: LOGIN_STATUS.error,
            errorMessage,
        },
    })),

    on(AuthActions.doStartLogout, (state): AuthState => ({
        ...state,
        logoutInProgress: true,
    })),

    on(AuthActions.logoutComplete, (state): AuthState => ({
        ...state,
        logoutInProgress: false,
    })),

    on(AuthActions.setCurrentUser, (state, { currentUser }): AuthState => ({
        ...state,
        currentUser,
    })),
)
