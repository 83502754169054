import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ZenButtonSizes } from '@domain/entities/ZenButtonSizes'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'
import { ZenSvgIconSizes } from '@domain/entities/ZenSvgIconSizes'
import { ZenButtonStyles } from '@domain/entities/ZenButtonStyles'

@Component({
    selector: 'zen-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {

    @Input() size: ZenButtonSizes = ZenButtonSizes.small
    @Input() style: ZenButtonStyles = ZenButtonStyles.primary
    @Input() icon?: ZenSvgIcons | null = null
    @Input() chevron = false
    @Input() fullWidth = false
    @Input() disabled = false

    @Output() pressed = new EventEmitter<void>()

    sizes = ZenButtonSizes
    styles = ZenButtonStyles
    zenSvgIcons = ZenSvgIcons
    zenSvgIconSizes = ZenSvgIconSizes

    onClick() {
        if (!this.disabled) {
            this.pressed.emit()
        }
    }
}
