import { Component, Input } from '@angular/core'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'

@Component({
    selector: 'zen-settings-item',
    templateUrl: './settings-item.component.html',
    styleUrls: ['./settings-item.component.scss'],
})
export class SettingsItemComponent {

    @Input() icon: ZenSvgIcons = ZenSvgIcons.info

    zenSvgIcons = ZenSvgIcons

}
