import { createReducer, on } from '@ngrx/store'
import * as ProgressActions from '../actions'
import { ZenUserMediaContentProgress } from '@domain/entities/user/ZenUserMediaContentProgress'

export const mediaContentProgressFeatureKey = 'mediaContentProgress'

export interface MediaContentProgressState {
  mediaContentProgressList: ZenUserMediaContentProgress[];
  loaded: boolean;
}

const initialState: MediaContentProgressState = {
  mediaContentProgressList: [],
  loaded: false,
}

export const reducer = createReducer(
  initialState,

  on(ProgressActions.loadedList, (state, { mediaContentProgressList }): MediaContentProgressState => ({
    ...state,
    mediaContentProgressList,
    loaded: true,
  })),

  on(ProgressActions.setLiked, (state, { mediaContentId, liked }): MediaContentProgressState => ({
      ...state,
      mediaContentProgressList: progressRecordPresent(state.mediaContentProgressList, mediaContentId)
        ? state.mediaContentProgressList.map((progressItem) => progressItem.id === mediaContentId
          ? {
            ...progressItem,
            liked,
          }
          : progressItem)
        : [
          ...state.mediaContentProgressList,
          new ZenUserMediaContentProgress({
            id: mediaContentId,
            liked,
          }),
        ],
    })),

  on(ProgressActions.setCompleted, (state, { mediaContentId, completed }): MediaContentProgressState => ({
      ...state,
      mediaContentProgressList: progressRecordPresent(state.mediaContentProgressList, mediaContentId)
        ? state.mediaContentProgressList.map((progressItem) => progressItem.id === mediaContentId
          ? {
            ...progressItem,
            completed,
            progress: 0,
          }
          : progressItem)
        : [
          ...state.mediaContentProgressList,
          new ZenUserMediaContentProgress({
            id: mediaContentId,
            completed,
            progress: 0,
          }),
        ],
    })),

  on(ProgressActions.setProgress, (state, { mediaContentId, progress }): MediaContentProgressState => ({
      ...state,
      mediaContentProgressList: progressRecordPresent(state.mediaContentProgressList, mediaContentId)
        ? state.mediaContentProgressList.map((progressItem) => progressItem.id === mediaContentId
          ? {
            ...progressItem,
            progress,
          }
          : progressItem)
        : [
          ...state.mediaContentProgressList,
          new ZenUserMediaContentProgress({
            id: mediaContentId,
            progress,
          }),
        ],
    })),
)


const progressRecordPresent = (progressList: ZenUserMediaContentProgress[], mediaContentId: string): boolean =>
  !!progressList.find((progressItem) => progressItem.id === mediaContentId)
