<div class="container"
     [class.icon-small]="size === zenSvgIconSizes.small"
     [class.icon-large]="size === zenSvgIconSizes.large"
     [class.icon-xLarge]="size === zenSvgIconSizes.xLarge"
     [style.background-color]="backgroundColor"
>
    <div class="icon-wrapper">
        <div class="icon-container">
            <zen-svg-icon class="icon" [icon]="icon" [style]="style"></zen-svg-icon>
        </div>
    </div>
</div>
