import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core'
import { mergeMap, Observable, take } from 'rxjs'
import { Store } from '@ngrx/store'
import { FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { fileUploadValidator, ImageUploadValidatorErrors } from '@/app/features/admin/validators/fileUploadValidator'
import { filter } from 'rxjs/operators'
import { MatDialog } from '@angular/material/dialog'
import { ConfirmDialogComponent } from '@/app/components/_forms/confirm-dialog/confirm-dialog.component'
import { MatSnackBar } from '@angular/material/snack-bar'
import { getMockMindfitnessContent, MindfitnessContent } from '@domain/entities/mindfitness';
import {
    selectMindfitnessAssetUploadPending,
    selectMindfitnessAssetUploadPercentage,
    selectMindfitnessContentUpdatePending,
} from '@/app/features/admin-mindfitness-feature/store/selectors'
import {
    mindfitnessContentRemoveStart,
    mindfitnessContentUploadStart,
} from '@/app/features/admin-mindfitness-feature/store/actions';

@Component({
    selector: 'zen-mindfitness-content-form',
    templateUrl: './mindfitness-content-form.component.html',
    styleUrls: ['./mindfitness-content-form.component.scss'],
})
export class MindfitnessContentFormComponent implements OnInit {

    @Input()
    contentToEdit?: MindfitnessContent

    @ViewChild(FormGroupDirective)
    formDirective!: FormGroupDirective

    contentUpdatePending$: Observable<boolean>
    assetUploadPending$: Observable<boolean>
    assetUploadPercentage$: Observable<number>

    validationErrors = ImageUploadValidatorErrors

    mindfitnessContentForm = new UntypedFormGroup({
        title: new UntypedFormControl('', [
            Validators.required,
            Validators.minLength(5),
        ]),
        description: new UntypedFormControl('', [
            Validators.required,
            Validators.minLength(10),
        ]),
        length: new UntypedFormControl('', [
            Validators.required,
            Validators.min(1),
        ]),
        contentUpload: new UntypedFormControl(null, [
            fileUploadValidator('audio/'),
        ]),

    }, [])

    constructor(private store: Store, private dialog: MatDialog, private ngZone: NgZone, private snackbar: MatSnackBar) {
        this.contentUpdatePending$ = this.store.select(selectMindfitnessContentUpdatePending)
        this.assetUploadPending$ = this.store.select(selectMindfitnessAssetUploadPending)
        this.assetUploadPercentage$ = this.store.select(selectMindfitnessAssetUploadPercentage)
    }

    get title(): UntypedFormControl {
        return this.mindfitnessContentForm.get('title') as UntypedFormControl
    }

    get description(): UntypedFormControl {
        return this.mindfitnessContentForm.get('description') as UntypedFormControl
    }

    get length(): UntypedFormControl {
        return this.mindfitnessContentForm.get('length') as UntypedFormControl
    }

    get contentUpload(): UntypedFormControl {
        return this.mindfitnessContentForm.get('contentUpload') as UntypedFormControl
    }

    ngOnInit(): void {
        console.log('AdminContentFormComponent ngOnInit editMode:', !!this.contentToEdit)

        if (this.contentToEdit) {
            this.initializeFields(this.contentToEdit)

        } else {
            // Content upload is optional for existing content
            this.contentUpload.addValidators([Validators.required])
        }
    }

    onSubmit() {
        console.warn('onSubmit', this.mindfitnessContentForm.value)

        this.mindfitnessContentForm.disable()

        this.store.dispatch(mindfitnessContentUploadStart({
            mediaFields: {
                title: this.title.value,
                description: this.description.value,
                length: this.length.value,
            },
            existingContent: this.contentToEdit,
            mediaAsset: this.contentUpload.value,
        }))

        this.contentUpdatePending$.pipe(
            filter((contentUpdatePending) => !contentUpdatePending),
            mergeMap(() => this.assetUploadPending$.pipe(
                filter((assetUploadPending$) => !assetUploadPending$),
                take(1),
            )),
            take(1),
        ).subscribe({
            complete: () => {
                this.ngZone.run(() => {
                    if (this.contentToEdit) {
                        this.mindfitnessContentForm.patchValue({
                            contentUpload: null,
                        })

                        const snack = this.snackbar.open('Sikeresen módosítva', 'OK')
                        window.setTimeout(async () => {
                            snack.dismiss()
                        }, 2000)

                    } else {
                        this.mindfitnessContentForm.reset()
                        this.formDirective.resetForm()

                        const snack = this.snackbar.open('Sikeresen feltöltve', 'OK')
                        window.setTimeout(async () => {
                            snack.dismiss()
                        }, 2000)
                    }
                    this.mindfitnessContentForm.enable()
                })
            },
        })
    }

    onDelete() {
        console.warn('onDelete')
        this.mindfitnessContentForm.disable()

        const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Tartalom törlése',
                message: 'Biztosan törölni akarod a tartalmat?',
            },
        })
        confirmDialog.afterClosed()
            .subscribe((confirmed: boolean) => {
                if (confirmed && this.contentToEdit) {
                    this.store.dispatch(mindfitnessContentRemoveStart(this.contentToEdit.id, this.contentToEdit.contentFileName))
                } else {
                    this.mindfitnessContentForm.enable()
                }
            })
    }

    private initializeFields(mindfitnessContent: MindfitnessContent = getMockMindfitnessContent()) {
        const { title, description, length } = mindfitnessContent
        this.mindfitnessContentForm.patchValue({
            title,
            description,
            length,
            contentUpload: null,
        })
    }

}
