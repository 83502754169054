<div class="container"
     [class.header-paddings]="addHeaderPaddings"
     [class.footer-paddings]="addFooterPaddings"
>

    <ng-content select="[container]"></ng-content>

    <div class="content"
         [class.side-paddings]="addSidePaddings"
    >
        <ng-content></ng-content>
    </div>

</div>
