export const environment = {
  production: true,
  appName: 'zencool-dev',
  appTitle: 'ZenCool DEV',
  appVersion: require('../../package.json').version + '-dev',
  firebaseConfig: {
    apiKey: 'AIzaSyCCcHdQWs_qFzrhhNmeXQxv4O-79FeGNR0',
    authDomain: 'dev.zencool.app',
    projectId: 'zencool-dev',
    storageBucket: 'zencool-dev.appspot.com',
    messagingSenderId: '163521182837',
    appId: '1:163521182837:web:3a9dbb6a36deacac6b0c3c',
    measurementId: 'G-LC0P8NVGM7',
  },
  publicSiteUrl: 'https://dev.zencool.app',
  publicStorageBucketCDN: 'https://dev-public.zencool.app',
  publicStorageBucketUrl: 'gs://dev-public.zencool.app',
  zenCoolApiHostPrefix: '/api',
  features: {
    mindfitness: false,
  },
}
