<zen-page-layout>

    <div class="email-login">

        <div class="placeholder"></div>

        <form class="zen-email-login-form"
              *ngIf="!submitted; else linkSent"
              [formGroup]="emailLoginForm">

            <div class="title">
                <h1>Bejelentkezés email címmel</h1>
            </div>

            <div class="description">
                <p>Add meg az email címedet, majd a gombra kattintva egy megerősítő emailt küldünk neked. Ezen keresztül
                    tudod befejezni a regisztrációdat és belépni az appba.</p>
            </div>

            <div class="placeholder"></div>

            <label class="zen-form-field">

                <div class="label">
                    Email cím
                </div>

                <div class="field">
                    <input type="email"
                           id="email-input"
                           placeholder="minta.janos@email.com"
                           [formControlName]="'email'"
                           required>
                </div>

                <div class="error" *ngIf="email.touched && email.errors?.['required']">* Kötelező mező</div>
                <div class="error" *ngIf="email.touched && email.errors?.['email']">* Az email cím nem megfelelő</div>
            </label>

            <div class="placeholder"></div>

            <div class="zen-form-actions">
                <zen-button (pressed)="onSubmit()"
                            [disabled]="!emailLoginForm.valid || submitted"
                            [style]="buttonStyles.primary"
                            [size]="buttonSizes.dynamic"
                            [fullWidth]="true"
                            [chevron]="true"
                >
                    Megerősítő link küldése
                </zen-button>
            </div>

        </form>

        <ng-template #linkSent>

            <zen-feedback-card-warning
                    class="login-link-sent"
                    [icon]="zenSvgIcons.envelope"
                    [title]="'Elküldtük a belépési linket!'"
                    [description]="'Az email fiókodba érkezett megerősítő emailben kattints a “belépés”-re.'"
            >
            </zen-feedback-card-warning>

        </ng-template>

        <div class="placeholder"></div>

    </div>

</zen-page-layout>
