import { createAction } from '@ngrx/store'
import { MediaContent } from '@domain/entities/media-content/MediaContent'

export const loadMediaContentsStart = createAction(
  '[MediaContent] Load MediaContents - START',
)

export const loadMediaContentsError = createAction(
  '[MediaContent] Load MediaContents - ERROR',
)

export const loadMediaContentsSuccess = createAction(
  '[MediaContent] Load MediaContents - SUCCESS',
  (mediaContentList: MediaContent[]) => ({ mediaContentList }),
)

export const addMediaContent = createAction(
  '[MediaContent] Add MediaContent',
  (mediaContent: MediaContent) => ({ mediaContent }),
)
