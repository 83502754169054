import { createAction } from '@ngrx/store'
import { ZenUserMediaContentProgress } from '@domain/entities/user/ZenUserMediaContentProgress'

export const loadedList = createAction(
  '[MediaContentProgress] MediaContentProgress - LIST LOADED',
  (mediaContentProgressList: ZenUserMediaContentProgress[]) => ({ mediaContentProgressList }),
)

export const setProgress = createAction(
  '[MediaContentProgress] MediaContentProgress - SET PROGRESS',
  (mediaContentId: string, progress: number) => ({ mediaContentId, progress }),
)

export const setCompleted = createAction(
  '[MediaContentProgress] MediaContentProgress - SET COMPLETED',
  (mediaContentId: string, completed: boolean) => ({ mediaContentId, completed }),
)

export const setLiked = createAction(
  '[MediaContentProgress] MediaContentProgress - SET LIKED',
  (mediaContentId: string, liked: boolean) => ({ mediaContentId, liked }),
)
