import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { loadSettingsComplete } from '@/app/settings/store/actions'

const KEY_INFO_HOME_DISMISSED = 'infoHomeDismissed'
const KEY_INFO_RECORDINGS_DISMISSED = 'infoRecordingsDismissed'
const KEY_INFO_SEARCH_DISMISSED = 'infoSearchDismissed'
const KEY_INFO_PROFILE_DISMISSED = 'infoProfileDismissed'
const KEY_GO_TO_PATH_AFTER_LOGIN = 'goToPath'

@Injectable({
    providedIn: 'root',
})
export class SettingsService {

    constructor(private store: Store) {
        this.store.dispatch(loadSettingsComplete({
            infoHomeActive: !localStorage.getItem(KEY_INFO_HOME_DISMISSED),
            infoRecordingsActive: !localStorage.getItem(KEY_INFO_RECORDINGS_DISMISSED),
            infoSearchActive: !localStorage.getItem(KEY_INFO_SEARCH_DISMISSED),
            infoProfileActive: !localStorage.getItem(KEY_INFO_PROFILE_DISMISSED),
        }))
    }

    static getGoToPathAfterLogin(): string | null {
        return localStorage.getItem(KEY_GO_TO_PATH_AFTER_LOGIN)
    }

    static clearGoToPathAfterLogin(): void {
        localStorage.removeItem(KEY_GO_TO_PATH_AFTER_LOGIN)
    }

    static setGoToPathAfterLogin(goToPath: string): void {
        localStorage.setItem(KEY_GO_TO_PATH_AFTER_LOGIN, goToPath)
    }

    markInfoHomeDismissed(): void {
        localStorage.setItem(KEY_INFO_HOME_DISMISSED, 'true')
    }

    markInfoRecordingsDismissed(): void {
        localStorage.setItem(KEY_INFO_RECORDINGS_DISMISSED, 'true')
    }

    markInfoSearchDismissed(): void {
        localStorage.setItem(KEY_INFO_SEARCH_DISMISSED, 'true')
    }

    markInfoProfileDismissed(): void {
        localStorage.setItem(KEY_INFO_PROFILE_DISMISSED, 'true')
    }
}
