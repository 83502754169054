import { createAction } from '@ngrx/store'
import { ZenUser } from '@domain/entities/user/ZenUser'

export const dataLoaded = createAction(
    '[CurrentUser] CurrentUser - DATA LOADED',
    (userData: ZenUser | null) => ({ userData }),
)

export const updateNickName = createAction(
    '[CurrentUser] CurrentUser - UPDATE NICKNAME',
    (nickName: string) => ({ nickName }),
)
