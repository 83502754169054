<zen-page-layout>

    <div class="login-page">

        <div class="placeholder"></div>

        <div class="graphic">
            <zen-svg-graphic [graphic]="zenSvgGraphics.zencoolLogoWithText"></zen-svg-graphic>
        </div>

        <div class="placeholder height-up-scaled"></div>

        <zen-feedback-card *ngIf="lastLoginError$ | async"
                           [title]="'Hoppá!'"
                           [description]="'Sajnos nem sikerült a bejelentkezés. Próbálj másik lehetőséget!'"
                           [details]="(lastLogin$ | async)?.errorMessage || 'lorem'"
        ></zen-feedback-card>

        <div class="placeholder" *ngIf="lastLoginError$"></div>

        <zen-overlayed-content container
                               [title]="'Felhasználási feltételek'"
                               [visible]="termsAndConditionsVisible"
                               (closed)="termsAndConditionsVisible = false"
        >
            <zen-terms></zen-terms>
        </zen-overlayed-content>

        <zen-overlayed-content container
                               [title]="'Adatvédelmi Szabályzat'"
                               [visible]="privacyPolicyVisible"
                               (closed)="privacyPolicyVisible = false"
        >
            <zen-privacy-policy></zen-privacy-policy>
        </zen-overlayed-content>

        <div class="disclaimer-section">

            <div class="disclaimer-checkbox">
                <zen-svg-icon-btn (pressed)="termsAccepted = !termsAccepted">
                    <zen-svg-icon [icon]="termsAccepted ? svgIcons.checkboxOn : svgIcons.checkboxOff"></zen-svg-icon>
                </zen-svg-icon-btn>
            </div>

            <div class="disclaimer-text">
                Egyetértek a
                <span class="info-toggle" (click)="termsAndConditionsVisible = true">Felhasználási feltételekkel</span>
                és az
                <span class="info-toggle" (click)="privacyPolicyVisible = true">Adatvédelmi Szabályzattal</span>
            </div>
        </div>

        <div class="placeholder"></div>

        <div class="button-section">

            <zen-button
                    [fullWidth]="true"
                    [size]="buttonSizes.dynamic"
                    [style]="buttonStyles.primary"
                    [icon]="svgIcons.logoGoogleCircled"
                    [disabled]="!termsAccepted || ((loginInProgress$ | async) || false)"
                    (pressed)="loginGoogle()"
            >
                Belépés Google fiókkal
            </zen-button>

            <zen-button
                    [fullWidth]="true"
                    [size]="buttonSizes.dynamic"
                    [style]="buttonStyles.primary"
                    [icon]="svgIcons.logoFacebookCircled"
                    [disabled]="!termsAccepted || ((loginInProgress$ | async) || false)"
                    (pressed)="loginFacebook()"
            >
                Belépés Facebook fiókkal
            </zen-button>

            <zen-button
                    [fullWidth]="true"
                    [size]="buttonSizes.dynamic"
                    [style]="buttonStyles.primary"
                    [icon]="svgIcons.envelopeCircled"
                    [disabled]="!termsAccepted || ((loginInProgress$ | async) || false)"
                    (pressed)="goToEmailLogin()"
            >
                Belépés e-mail címmel
            </zen-button>
        </div>

        <div class="placeholder"></div>

    </div>

</zen-page-layout>
