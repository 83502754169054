import { Component } from '@angular/core'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'
import { Store } from '@ngrx/store'
import { Router } from '@angular/router'
import { RoutePaths } from '@/app/routes'
import { ZenSvgIconStyles } from '@domain/entities/ZenSvgIconStyles'
import { firstValueFrom, Observable } from 'rxjs'
import { selectInfoHomeActive } from '@/app/settings/store/selectors'
import { setInfoHomeActive } from '@/app/settings/store/actions'
import { ZenButtonSizes } from '@domain/entities/ZenButtonSizes'
import { ZenButtonStyles } from '@domain/entities/ZenButtonStyles'
import { ZenSvgGraphics } from '@domain/entities/ZenSvgGraphics'
import { selectMindfitnessAvailableForCurrentUser } from '@/app/mindfitness/mindfitness.selectors';

@Component({
    selector: 'zen-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent {

    zenSvgGraphics = ZenSvgGraphics
    zenSvgIcons = ZenSvgIcons
    zenSvgIconStyles = ZenSvgIconStyles
    zenButtonSizes = ZenButtonSizes
    zenButtonStyles = ZenButtonStyles
    infoBoxActive$: Observable<boolean> = this.store.select(selectInfoHomeActive)
    mindfitnessEnabled$: Observable<boolean> = this.store.select(selectMindfitnessAvailableForCurrentUser)
    mindFitnessTeaserActive = false

    constructor(private store: Store, private router: Router) {
    }

    onInfoBoxClose(): void {
        this.store.dispatch(setInfoHomeActive(false))
    }

    onInfoBoxOpen(): void {
        this.store.dispatch(setInfoHomeActive(true))
    }

    goToMediaLibrary() {
        void this.router.navigate([RoutePaths.recordings])
    }

    goToMindFitness() {
        firstValueFrom(this.mindfitnessEnabled$).then((enabled) => {
            enabled
                ? void this.router.navigate([RoutePaths.mindfitness])
                : this.mindFitnessTeaserActive = true
        })
    }

    onMindFitnessTeaserClose(): void {
        this.mindFitnessTeaserActive = false
    }

    goToZenGarden() {
        console.log('goToZenGarden clicked')
    }

    goToAshram() {
        console.log('goToAshram clicked')
    }

}
