import { Timestamp } from '@angular/fire/firestore'
import { MediaContentBase } from '@domain/entities/media-content/MediaContentBase'
import { MediaContent } from '@domain/entities/media-content/MediaContent'

export interface FSCMediaContent extends MediaContentBase {
  createdAt: Timestamp;
}

export const mapToFSCMediaContent = (mediaContent: MediaContentBase): FSCMediaContent => ({
  ...mediaContent,
  createdAt: Timestamp.now(),
})

export const mapBackFromFSCMediaContent = (fsMediaContent: FSCMediaContent): MediaContent => new MediaContent({
  ...fsMediaContent,
})
