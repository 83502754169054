import { createFeatureSelector, createSelector } from '@ngrx/store'
import { mindFitnessFeatureKey, MindfitnessState } from '../reducer'
import { calculateMindfitnessLevel, LoadStatuses } from '@domain/entities/mindfitness';
import { isAssignedMediaExpired } from '@domain/utils/mindfitness';

export const INITIATION_BACKGROUND_URL = '/assets/images/cfa363ae0f92e5f00d2e8e09f0f660de.png'

export const selectMindfitnessState = createFeatureSelector<MindfitnessState>(mindFitnessFeatureKey)

export const selectMindfitnessFeatureEnabled = createSelector(
    selectMindfitnessState,
    (state) => state.featureEnabled,
)

export const selectProfileLoading = createSelector(
    selectMindfitnessState,
    (state) => state.profileLoadStatus === LoadStatuses.loading,
)

export const selectRequestContentInProgress = createSelector(
    selectMindfitnessState,
    (state) => state.requestContentInProgress,
)

export const selectInfoOverlayActive = createSelector(
    selectMindfitnessState,
    (state) => state.infoOverlayActive,
)

export const selectInfoDismissed = createSelector(
    selectMindfitnessState,
    (state) => state.profile?.infoDismissed || false,
)

export const selectIntroCompleted = createSelector(
    selectMindfitnessState,
    (state) => state.profile?.introCompleted || false,
)

export const selectLevelProgress = createSelector(
    selectMindfitnessState,
    (state) => state.profile?.levelProgress || 0,
)

export const selectCurrentLevel = createSelector(
    selectLevelProgress,
    (levelProgress) => calculateMindfitnessLevel(levelProgress),
)

export const selectAssignedMedia = createSelector(
    selectMindfitnessState,
    (state) => state.profile?.assignedMedia || null,
)

export const selectAssignedMediaCompleted = createSelector(
    selectMindfitnessState,
    (state) => state.profile?.assignedMediaCompleted || false,
)

export const selectLastDateTime = createSelector(
    selectMindfitnessState,
    (state) => state.lastDateTime,
)

export const selectAssignedMediaExpired = createSelector(
    selectAssignedMedia,
    selectAssignedMediaCompleted,
    selectLastDateTime,
    (assignedMedia, assignedMediaCompleted, lastDateTime) =>
        isAssignedMediaExpired({
            assignedMedia,
            assignedMediaCompleted,
            currentDateTime: lastDateTime,
        }),
)
