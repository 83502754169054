<div class="card" matRipple="true" (click)="clicked.emit()">
    <div class="card-content">

        <div class="title">
            <ng-content select="[text-title]"></ng-content>
        </div>

        <div class="coming-soon">
            <ng-content select="[text-coming-soon]"></ng-content>
        </div>

        <div class="active">
            <ng-content select="[text-active]"></ng-content>
        </div>

    </div>

    <div class="card-background">

        <div class="png-wrapper">
            <ng-content select="[background]"></ng-content>
        </div>

    </div>
</div>
