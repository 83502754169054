import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'zen-app-layout-player',
    templateUrl: './app-layout-player.component.html',
    styleUrls: ['./app-layout-player.component.scss'],
})
export class AppLayoutPlayerComponent {

    @Output() topSectionClicked = new EventEmitter<void>()

    @Input() backgroundImgUrl = '/assets/images/aa780aa297cc492194acd0b22a65dff4.png'
    @Input() playerVisible: boolean = false

    onTopSectionCLicked() {
        this.topSectionClicked.emit()
    }

}
