import { Component } from '@angular/core';
import { RoutePathsAdminMindfitness } from './RoutePathsAdminMindfitness';
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons';

@Component({
    selector: 'zen-admin-mindfitness',
    templateUrl: './admin-mindfitness-view.component.html',
    styleUrls: ['./admin-mindfitness-view.component.scss'],
})
export class AdminMindfitnessViewComponent {

    protected routePaths = RoutePathsAdminMindfitness
    protected zenSvgIcons = ZenSvgIcons

}
