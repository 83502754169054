import { Component, Input } from '@angular/core';
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons';

@Component({
    selector: 'zen-settings-link',
    templateUrl: './settings-link.component.html',
    styleUrls: ['./settings-link.component.scss'],
})
export class SettingsLinkComponent {
    @Input() icon: ZenSvgIcons = ZenSvgIcons.info
    @Input() url = ''
    @Input() label = ''

    zenSvgIcons = ZenSvgIcons
}
