import { Component, Input } from '@angular/core';

@Component({
    selector: 'zen-overlay-wrapper',
    templateUrl: './overlay-wrapper.component.html',
    styleUrls: ['./overlay-wrapper.component.scss'],
})
export class OverlayWrapperComponent {

    @Input() visible = false
    @Input() overHeader = false

}
