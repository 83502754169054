<div *ngIf="zenCategory" class="media-category">

  <div class="icon-wrapper">
    <zen-svg-icon [icon]="zenCategory.icon" [style]="zenSvgIconStyles.normal"></zen-svg-icon>
  </div>

  <div class="label">
    {{ zenCategory.label }}
  </div>

</div>
