interface ConstructorParams {
  id: string;
  progress?: number;
  completed?: boolean;
  liked?: boolean;
  lastPlayed?: Date | null;
  playCount?: number;
}

export class ZenUserMediaContentProgress {
  readonly id: string // mediaId
  readonly progress: number // to be updated in ~10 seconds when playing <= maybe only store this in local storage / indexedDb to optimize?
  readonly completed: boolean // has ever been played fully
  readonly liked: boolean // Only selectable once 'completed' - mark content as favorite
  // Maybe for statistics later?
  readonly lastPlayed: Date | null // Last time it completed playing
  readonly playCount: number // Number of times it completed playing so far

  constructor(params: ConstructorParams) {
    this.id = params.id
    this.progress = params.progress || 0
    this.completed = !!params.completed
    this.liked = !!params.liked
    this.lastPlayed = params.lastPlayed || null
    this.playCount = params.playCount || 0
  }
}
