import { Component } from '@angular/core'

@Component({
    selector: 'zen-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss'],
})
export class TermsComponent {

}
