import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store'
import { navigationFeatureKey, reducer } from './store/reducer'
import { EffectsModule } from '@ngrx/effects'
import { NavigationEffects } from './store/effects'


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(navigationFeatureKey, reducer),
        EffectsModule.forFeature([NavigationEffects]),
    ],
})
export class NavigationModule {
}
