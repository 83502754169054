import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatRippleModule } from '@angular/material/core'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MediaListComponent } from './media-list/media-list.component'
import { MediaCardBoxComponent } from './media-card-box/media-card-box.component'
import { MediaCardTimelineComponent } from './media-card-timeline/media-card-timeline.component'
import { MediaListRowComponent } from './media-list-row/media-list-row.component'
import { MediaMoreBoxComponent } from './media-more-box/media-more-box.component'
import { LoaderComponent } from './loader/loader.component'
import { MediaTagComponent } from './media-tag/media-tag.component'
import { MediaTagCategoryComponent } from './media-tag-category/media-tag-category.component'
import { MediaTagListComponent } from './media-tag-list/media-tag-list.component'
import { MediaActionsComponent } from './media-actions/media-actions.component'
import { NotificationItemComponent } from './notification-item/notification-item.component'
import { NotificationListComponent } from './notification-list/notification-list.component'
import { SettingsComponent } from '@/app/components/_overlays/settings/settings.component'
import { NotificationsComponent } from '@/app/components/_overlays/notifications/notifications.component'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatListModule } from '@angular/material/list'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatBadgeModule } from '@angular/material/badge'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { RouterModule } from '@angular/router'
import { SvgIconComponent } from '@/app/components/svg-icon/svg-icon.component'
import { SvgIconBtnComponent } from '@/app/components/svg-icon-btn/svg-icon-btn.component'
import { OverlayedContentComponent } from '@/app/components/overlayed-content/overlayed-content.component'
import { AppLayoutRelativeComponent } from '@/app/components/_layout/app-layout-relative/app-layout-relative.component'
import { HeaderComponent } from '@/app/components/_layout/header/header.component'
import { TabBarComponent } from '@/app/components/_layout/tab-bar/tab-bar.component'
import { TabBarItemComponent } from '@/app/components/_layout/tab-bar-item/tab-bar-item.component'
import { PageLayoutComponent } from './_layout/page-layout/page-layout.component'
import { PageTitleComponent } from './_layout/page-title/page-title.component'
import { SvgIconRoundComponent } from './svg-icon-round/svg-icon-round.component'
import { MediaTeaserSectionComponent } from './media-teaser-section/media-teaser-section.component'
import { SettingsItemComponent } from '@/app/components/_overlays/settings/settings-item/settings-item.component'
import { ButtonComponent } from './button/button.component'
import { FeedbackCardComponent } from './feedback-card/feedback-card.component'
import { TermsComponent } from './_screens/terms/terms.component'
import { PrivacyPolicyComponent } from './_screens/privacy-policy/privacy-policy.component'
import { AboutUsComponent } from './_screens/about-us/about-us.component'
import { FeedbackCardSuccessComponent } from './feedback-card-success/feedback-card-success.component'
import { CountbackTimerComponent } from './countback-timer/countback-timer.component'
import { FormInputComponent } from './form-input/form-input.component'
import { SliderProgressIndicatorComponent } from './slider-progress-indicator/slider-progress-indicator.component'
import { FeedbackCardWarningComponent } from '@/app/components/feedback-card-warning/feedback-card-warning.component'
import { SvgGraphicComponent } from './svg-graphic/svg-graphic.component';
import {
    SettingsUserLoggedInComponent,
} from '@/app/components/_overlays/settings/settings-user-logged-in/settings-user-logged-in.component';
import { SettingsLinkComponent } from '@/app/components/_overlays/settings/settings-link/settings-link.component';
import { AppLayoutRelativeUiComponent } from './_layout/app-layout-relative-ui/app-layout-relative-ui.component'
import { LoginContextualComponent } from '@/app/components/_overlays/login-contextual/login-contextual.component';
import { AppLayoutPlayerComponent } from './_layout/app-layout-player/app-layout-player.component';
import { OverlayWrapperComponent } from './_layout/overlay-wrapper/overlay-wrapper.component';
import { FileUploadComponent } from './_forms/file-upload/file-upload.component';
import { ConfirmDialogComponent } from './_forms/confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

const componentsList = [
    MediaListComponent,
    MediaListRowComponent,
    MediaCardBoxComponent,
    MediaCardTimelineComponent,
    MediaMoreBoxComponent,
    LoaderComponent,
    MediaTagComponent,
    MediaTagCategoryComponent,
    MediaTagListComponent,
    MediaActionsComponent,
    NotificationItemComponent,
    NotificationListComponent,
    NotificationsComponent,
    SvgIconComponent,
    SvgIconBtnComponent,
    SvgIconRoundComponent,
    OverlayedContentComponent,
    AppLayoutRelativeComponent,
    HeaderComponent,
    TabBarComponent,
    TabBarItemComponent,
    MediaTeaserSectionComponent,
    PageLayoutComponent,
    PageTitleComponent,
    SettingsItemComponent,
    ButtonComponent,
    FeedbackCardComponent,
    TermsComponent,
    PrivacyPolicyComponent,
    AboutUsComponent,
    FeedbackCardSuccessComponent,
    FeedbackCardWarningComponent,
    CountbackTimerComponent,
    FormInputComponent,
    SliderProgressIndicatorComponent,
    SvgGraphicComponent,
    LoginContextualComponent,
    SettingsComponent,
    SettingsUserLoggedInComponent,
    SettingsLinkComponent,
    AppLayoutRelativeUiComponent,
    AppLayoutPlayerComponent,
    OverlayWrapperComponent,
    FileUploadComponent,
    ConfirmDialogComponent,
]

// TODO remove these
const materialModules = [
    MatRippleModule,
    MatSnackBarModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule, // used by MessageBoxComponent
    MatToolbarModule, // used in top-nav component
    MatMenuModule, // used in top-nav component
    MatBadgeModule, // used in top-nav component
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    //admin only
    MatProgressBarModule,
    MatProgressSpinnerModule, // audio-player
    MatDialogModule,
]

@NgModule({
    declarations: [
        ...componentsList,
    ],
    imports: [
        CommonModule,
        RouterModule,
        ...materialModules,
    ],
    exports: [
        ...materialModules,
        ...componentsList,
    ],
})
export class ComponentsModule {
}
