import { Component } from '@angular/core'
import { ZenButtonSizes } from '@domain/entities/ZenButtonSizes'
import { ZenButtonStyles } from '@domain/entities/ZenButtonStyles'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'
import { RoutePaths } from '@/app/routes'
import { Router } from '@angular/router'
import { SettingsService } from '@/app/settings/settings.service'
import { EMPTY, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectLoginActive } from '@/app/features/navigation/store/selectors';

@Component({
    selector: 'zen-login-contextual',
    templateUrl: './login-contextual.component.html',
    styleUrls: ['./login-contextual.component.scss'],
})
export class LoginContextualComponent {

    active$: Observable<boolean> = EMPTY
    buttonSizes = ZenButtonSizes
    buttonStyles = ZenButtonStyles
    svgIcons = ZenSvgIcons

    constructor(private router: Router, private store: Store) {
        this.active$ = this.store.select(selectLoginActive)
    }

    async goToLogin(): Promise<void> {
        SettingsService.setGoToPathAfterLogin(this.router.routerState.snapshot.url)
        // SettingsService.setGoToPathAfterLogin('/recordings/play/380fda54-2495-4d11-899f-768a15da08d6')
        await this.router.navigate([RoutePaths.login])
    }

}
