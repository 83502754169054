import { createReducer, on } from '@ngrx/store'
import { MindfitnessContent } from '@domain/entities/mindfitness';
import {
    mindfitnessContentAssetUploadError,
    mindfitnessContentAssetUploadProgressUpdate,
    mindfitnessContentAssetUploadSuccess,
    mindfitnessContentDataUploadError,
    mindfitnessContentDataUploadSuccess,
    mindfitnessContentUpdate,
    mindfitnessContentUploadStart,
} from '../actions';


export const adminMindfitnessFeatureKey = 'admin-mindfitness'

export interface AdminMindfitnessState {
    mindfitnessContents: MindfitnessContent[];
    mindfitnessContentUpload: {
        contentUpdatePending: boolean;
        contentUpdateError: boolean;
        assetUploadPending: boolean;
        assetUploadError: boolean;
        assetUploadPercentage: number;
    };
}

const initialState: AdminMindfitnessState = {
    mindfitnessContents: [],
    mindfitnessContentUpload: {
        contentUpdatePending: false,
        contentUpdateError: false,
        assetUploadPending: false,
        assetUploadError: false,
        assetUploadPercentage: 0,
    },
}
export const reducer = createReducer(
    initialState,

    on(mindfitnessContentUpdate, (state, { mindfitnessContents }): AdminMindfitnessState => ({
        ...state,
        mindfitnessContents,
    })),

    on(mindfitnessContentUploadStart, (state, { mediaAsset }): AdminMindfitnessState => ({
        ...state,
        mindfitnessContentUpload: {
            contentUpdatePending: true,
            contentUpdateError: false,
            assetUploadPending: !!mediaAsset,
            assetUploadError: false,
            assetUploadPercentage: 0,
        },
    })),

    on(mindfitnessContentAssetUploadProgressUpdate, (state, { assetUploadPercentage }): AdminMindfitnessState => ({
        ...state,
        mindfitnessContentUpload: {
            ...state.mindfitnessContentUpload,
            assetUploadPercentage,
        },
    })),

    on(mindfitnessContentAssetUploadSuccess, (state): AdminMindfitnessState => ({
        ...state,
        mindfitnessContentUpload: {
            ...state.mindfitnessContentUpload,
            assetUploadPending: false,
            assetUploadError: false,
            assetUploadPercentage: 100,
        },
    })),

    on(mindfitnessContentAssetUploadError, (state): AdminMindfitnessState => ({
        ...state,
        mindfitnessContentUpload: {
            ...state.mindfitnessContentUpload,
            assetUploadPending: false,
            assetUploadError: true,
            assetUploadPercentage: 0,
        },
    })),

    on(mindfitnessContentDataUploadSuccess, (state): AdminMindfitnessState => ({
        ...state,
        mindfitnessContentUpload: {
            ...state.mindfitnessContentUpload,
            contentUpdatePending: false,
        },
    })),

    on(mindfitnessContentDataUploadError, (state): AdminMindfitnessState => ({
        ...state,
        mindfitnessContentUpload: {
            ...state.mindfitnessContentUpload,
            contentUpdatePending: false,
            contentUpdateError: true,
        },
    })),
)
