import { Component, EventEmitter, Output } from '@angular/core'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ZenSvgIconStyles } from '@domain/entities/ZenSvgIconStyles'
import { ZenButtonStyles } from '@domain/entities/ZenButtonStyles'
import { ZenButtonSizes } from '@domain/entities/ZenButtonSizes'
import { firstValueFrom, Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import {
    selectCurrentUserLoaded,
    selectCurrentUserNickName,
    selectCurrentUserNickNamePlaceholder,
} from '@/app/features/current-user/store/selectors'
import { updateNickName } from '@/app/features/current-user/store/actions'
import { AuthService } from '@/app/features/auth/auth.service'
import { selectCurrentUser } from '@/app/features/auth/store/selectors';

@Component({
    selector: 'zen-home-greeting',
    templateUrl: './home-greeting.component.html',
    styleUrls: ['./home-greeting.component.scss'],
})
export class HomeGreetingComponent {
    @Output() infoBtnPressed = new EventEmitter<void>()

    zenSvgIcons = ZenSvgIcons
    zenSvgIconStyles = ZenSvgIconStyles
    zenButtonStyles = ZenButtonStyles
    zenButtonSizes = ZenButtonSizes
    nickNameForm = new UntypedFormGroup({
        nickName: new UntypedFormControl('', [
            Validators.required,
        ]),
    })

    nickNameLoaded$: Observable<boolean>
    nickName$: Observable<string>
    nickNamePlaceholder$: Observable<boolean>

    protected editActive = false
    protected submitted = false

    constructor(private store: Store, private authService: AuthService) {
        this.nickNameLoaded$ = this.store.select(selectCurrentUserLoaded)
        this.nickName$ = this.store.select(selectCurrentUserNickName)
        this.nickNamePlaceholder$ = this.store.select(selectCurrentUserNickNamePlaceholder)
    }

    get nickNameField(): UntypedFormControl {
        return this.nickNameForm.get('nickName') as UntypedFormControl
    }

    onSubmit() {
        this.nickNameForm.disable()
        this.store.dispatch(updateNickName(this.nickNameField.value))
        this.submitted = true
        this.editActive = false
    }

    onEditStart() {
        firstValueFrom(this.store.select(selectCurrentUser)).then((currentUser) => {
            if (currentUser) {
                this.editActive = true
                this.submitted = false
                this.nickNameForm.enable()
            }
        })
    }

}
