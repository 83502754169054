import { LoadStatuses } from '@domain/entities/mindfitness';
import { createReducer, on } from '@ngrx/store';
import * as Actions from '../actions';
import { UserSubscriptionData } from '@domain/entities/user-subscription/UserSubscriptionData';

export const userSubscriptionFeatureKey = 'userSubscription'


export interface UserSubscriptionState {
    featureEnabled: boolean
    subscription: UserSubscriptionData | null;
    subscriptionLoadStatus: LoadStatuses
}

export const initialState: UserSubscriptionState = {
    featureEnabled: true,
    subscription: null,
    subscriptionLoadStatus: LoadStatuses.unloaded,
}

export const reducer = createReducer(
    initialState,

    on(Actions.unLoadUserSubscription, (state): UserSubscriptionState => ({
        ...state,
        subscription: null,
        subscriptionLoadStatus: LoadStatuses.unloaded,
    })),

    on(Actions.loadUserSubscription, (state): UserSubscriptionState => ({
        ...state,
        subscription: null,
        subscriptionLoadStatus: LoadStatuses.loading,
    })),

    on(Actions.loadUserSubscriptionError, (state): UserSubscriptionState => ({
        ...state,
        subscription: null,
        subscriptionLoadStatus: LoadStatuses.error,
    })),

    on(Actions.loadUserSubscriptionSuccess, (state, { userSubscriptionProfile }): UserSubscriptionState => ({
        ...state,
        subscription: userSubscriptionProfile?.currentSubscription || null,
        subscriptionLoadStatus: LoadStatuses.success,
    })),
)
