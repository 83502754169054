import { createFeatureSelector, createSelector } from '@ngrx/store'
import { settingsFeatureKey, SettingsState } from '@/app/settings/store/reducer'

export const selectSettingsState = createFeatureSelector<SettingsState>(settingsFeatureKey)

export const selectInfoHomeActive = createSelector(
  selectSettingsState,
  (state) => state.infoHomeActive,
)

export const selectInfoRecordingsActive = createSelector(
  selectSettingsState,
  (state) => state.infoRecordingsActive,
)

export const selectInfoProfileActive = createSelector(
  selectSettingsState,
  (state) => state.infoProfileActive,
)

export const selectInfoSearchActive = createSelector(
  selectSettingsState,
  (state) => state.infoSearchActive,
)
