import { createReducer, on } from '@ngrx/store'
import * as SettingsActions from '../actions'

export const settingsFeatureKey = 'settings'

export interface SettingsState {
    infoHomeActive: boolean
    infoRecordingsActive: boolean
    infoSearchActive: boolean
    infoProfileActive: boolean
}

const initialState: SettingsState = {
    infoHomeActive: false,
    infoRecordingsActive: false,
    infoSearchActive: false,
    infoProfileActive: false,
}

export const reducer = createReducer(
    initialState,

    on(SettingsActions.loadSettingsComplete, (state, { settings }): SettingsState => ({
        ...state,
        ...settings,
    })),

    on(SettingsActions.setInfoHomeActive, (state, { active }): SettingsState => ({
        ...state,
        infoHomeActive: active,
    })),

    on(SettingsActions.setInfoRecordingsActive, (state, { active }): SettingsState => ({
        ...state,
        infoRecordingsActive: active,
    })),

    on(SettingsActions.setInfoSearchActive, (state, { active }): SettingsState => ({
        ...state,
        infoSearchActive: active,
    })),

    on(SettingsActions.setInfoProfileActive, (state, { active }): SettingsState => ({
        ...state,
        infoProfileActive: active,
    })),
)
