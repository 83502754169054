import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { environment } from '@/environments/environment'
import { CustomLogger } from './logging/CustomLogger'
import { CustomDummyLogger } from './logging/CustomDummyLogger'
import { CustomConsoleLogger } from './logging/CustomConsoleLogger'


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    {
      provide: CustomLogger,
      useClass: environment.production
        ? CustomDummyLogger
        : CustomConsoleLogger,
    },
  ],
  exports: [],
})
export class CoreModule {
}
