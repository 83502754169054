<div class="overlay-slider" [class.visible]="visible">

    <div class="wrapper">

        <div class="top-area">

            <div class="actions">

                <zen-button (pressed)="dismiss()"
                            [style]="zenButtonStyles.quaternary"
                            [size]="zenButtonSizes.small"
                >
                    Bezárás
                </zen-button>

            </div>

        </div>

        <div class="content-area">

            <div class="content-top">

                <div class="graphic">
                    <zen-svg-graphic [graphic]="zenSvgGraphics.templeExteriorPageBgCircle"></zen-svg-graphic>
                </div>

            </div>
            <div class="content-bottom">

                <div class="placeholder half"></div>

                <div class="headline">
                    Fejlődj folyamatosan MindFitness programunkkal!
                </div>

                <div class="placeholder half"></div>

                <div class="bullet-list">
                    <div class="bullet-list-item">
                        <div class="icon">
                            <zen-svg-icon [icon]="zenSvgIcons.calendar"
                                          [style]="zenSvgIconStyles.accent"></zen-svg-icon>
                        </div>

                        <div class="text">
                            Rendszeres gyakorlás
                        </div>
                    </div>

                    <div class="bullet-list-item">
                        <div class="icon">
                            <zen-svg-icon [icon]="zenSvgIcons.rocket"
                                          [style]="zenSvgIconStyles.accent"></zen-svg-icon>
                        </div>

                        <div class="text">
                            Folyamatos képességfejlesztés
                        </div>
                    </div>

                    <div class="bullet-list-item">
                        <div class="icon">
                            <zen-svg-icon [icon]="zenSvgIcons.catEloadasok"
                                          [style]="zenSvgIconStyles.accent"></zen-svg-icon>
                        </div>

                        <div class="text">
                            Egyéni, karmikus tanítások
                        </div>
                    </div>

                    <div class="bullet-list-item">
                        <div class="icon">
                            <zen-svg-icon [icon]="zenSvgIcons.sun"
                                          [style]="zenSvgIconStyles.accent"></zen-svg-icon>
                        </div>

                        <div class="text">
                            Beavatások
                        </div>
                    </div>
                </div>

                <div class="placeholder"></div>

                <div class="disclaimer">
                    *Hamarosan elérhető
                </div>

                <div class="placeholder"></div>


            </div>

        </div>


        <div class="bottom-area">
            <zen-button class="cta"
                        [chevron]="false"
                        [style]="zenButtonStyles.primary"
                        [size]="zenButtonSizes.dynamic"
                        [fullWidth]="true"
                        (pressed)="dismiss()">
                Bezárás
            </zen-button>
        </div>

    </div>

</div>
