import { CustomLogger } from './CustomLogger'
import { Injectable } from '@angular/core'

@Injectable()
export class CustomConsoleLogger extends CustomLogger {

  public log(message?: any, ...optionalParams: any[]): void {
    // eslint-disable-next-line no-console
    console.log(message, optionalParams)
  }
}
