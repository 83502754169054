import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { LoginComponent } from '@/app/login/login.component'
import { LoginEmailComponent } from '@/app/login/login-email/login-email.component'
import { LoginEmailConfirmComponent } from '@/app/login/login-email-confirm/login-email-confirm.component'
import { RoutePathsLogin } from './RoutePathsLogin'
import { LoginSuccessComponent } from '@/app/login/login-success/login-success.component'
import { AuthGuard } from '@angular/fire/auth-guard'
import { redirectLoggedInToLoginSuccess } from '@/app/routes'

const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
        canActivate: [AuthGuard],
        data: {
            authGuardPipe: redirectLoggedInToLoginSuccess,
            zenRouteConfig: {
                routeTitle: 'Bejelentkezés',
                toolbarEnabled: true,
            },
        },
    },
    {
        path: RoutePathsLogin.email,
        component: LoginEmailComponent,
        data: {
            zenRouteConfig: {
                routeTitle: 'Email belépés',
                toolbarEnabled: true,
                navBackDynamic: true,
            },
        },
    },
    {
        path: RoutePathsLogin.emailConfirm,
        component: LoginEmailConfirmComponent,
        data: {
            zenRouteConfig: {
                routeTitle: 'Email belépés',
                toolbarEnabled: true,
            },
        },
    },
    {
        path: RoutePathsLogin.success,
        component: LoginSuccessComponent,
        data: {
            zenRouteConfig: {
                routeTitle: 'Sikeres bejelentkezés',
                toolbarEnabled: false,
            },
        },
    },
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class LoginRoutingModule {
}
