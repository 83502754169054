<zen-page-layout [addSidePaddings]="false" [addFooterPaddings]="false">

    <div class="content">

        <zen-settings-item [routerLink]="routePaths.createContent"
                           [icon]="zenSvgIcons.document"
        >
            Tartalom feltöltés
        </zen-settings-item>

        <zen-settings-item [routerLink]="routePaths.listContent"
                           [icon]="zenSvgIcons.list"
        >
            Tartalmak kezelése
        </zen-settings-item>

    </div>

</zen-page-layout>
