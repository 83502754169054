import { createReducer, on } from '@ngrx/store'
import * as CurrentUserActions from '../actions'
import { ZenUser } from '@domain/entities/user/ZenUser'

export const currentUserFeatureKey = 'currentUser'

export interface CurrentUserState {
    userData: ZenUser | null;
    loaded: boolean;
}

const initialState: CurrentUserState = {
    userData: null,
    loaded: false,
}

export const reducer = createReducer(
    initialState,

    on(CurrentUserActions.dataLoaded, (state, { userData }): CurrentUserState => ({
        ...state,
        userData,
        loaded: true,
    })),

    on(CurrentUserActions.updateNickName, (state, { nickName }): CurrentUserState => ({
        ...state,
        userData: state.userData
            ? {
                ...state.userData,
                nickName,
            }
            : null,
    })),
)
