import { IMindfitnessAssignedMedia, MindfitnessAssignedMedia } from './MindfitnessAssignedMedia';

export enum UserMindfitnessProfileFields {
    infoDismissed = 'infoDismissed',
    introCompleted = 'introCompleted',
    levelProgress = 'levelProgress',
    assignedMedia = 'assignedMedia',
    assignedMediaCompleted = 'assignedMediaCompleted',
}


export interface IUserMindfitnessProfile {
    id: string // userId
    infoDismissed: boolean
    introCompleted: boolean
    assignedMedia: IMindfitnessAssignedMedia | null
    assignedMediaCompleted: boolean
    levelProgress: number // number of completed dailies
}

export interface UserMindfitnessProfile extends IUserMindfitnessProfile {
    assignedMedia: MindfitnessAssignedMedia | null
}
