import { Component, Input } from '@angular/core'
import { ZenCategory } from '@domain/entities/ZenCategories'
import { ZenSvgIconStyles } from '@domain/entities/ZenSvgIconStyles'

@Component({
    selector: 'zen-media-tag-category',
    templateUrl: './media-tag-category.component.html',
    styleUrls: ['./media-tag-category.component.scss'],
})
export class MediaTagCategoryComponent {
    @Input() zenCategory?: ZenCategory

    zenSvgIconStyles = ZenSvgIconStyles

}
