<div class="feedback-card-warning">
    <div class="icon">
        <zen-svg-icon [icon]="icon"></zen-svg-icon>
    </div>

    <div class="message">
        <div class="title">
            {{ title }}
        </div>

        <div class="description">
            {{ description }}
        </div>
    </div>
</div>
