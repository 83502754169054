<div class="settings-user">

    <div class="icon">
        <zen-svg-icon [icon]="icon"></zen-svg-icon>
    </div>

    <div class="user-section">
        <div class="name">
            {{ loggedInUser?.displayName || 'N/A' }}
        </div>

        <div class="status">
            Bejelentkezve
        </div>
    </div>

    <zen-svg-icon-btn (pressed)="logOut.emit()">
        <zen-svg-icon [icon]="zenSvgIcons.logout"></zen-svg-icon>
    </zen-svg-icon-btn>
</div>
