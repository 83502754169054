import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { currentUserFeatureKey, reducer } from './store/reducer'
import { CurrentUserEffects } from './store/effects'


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(currentUserFeatureKey, reducer),
        EffectsModule.forFeature([CurrentUserEffects]),
    ],
})
export class CurrentUserModule {
}
