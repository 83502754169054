import { Component, Input } from '@angular/core'
import { ZenTag } from '@domain/entities/ZenTags'

@Component({
  selector: 'zen-media-tag',
  templateUrl: './media-tag.component.html',
  styleUrls: ['./media-tag.component.scss'],
})
export class MediaTagComponent {

  @Input() zenTag?: ZenTag

}
