<div class="grid">

    <div class="grid-item" *ngFor="let mediaItem of mediaList">
        <zen-media-card-box
                [mediaContent]="mediaItem"
                [showCategory]="showCategories"
                [squeezedMode]="true"
                (clicked)="mediaItemSelected.emit($event)"
        ></zen-media-card-box>
    </div>

</div>
