<div matRipple="true"
     class="btn-container"
     (click)="clicked($event)"
     [class.encircled]="encircled"
     [class.accent]="buttonStyle === zenButtonStyles.accent"
>

    <div class="icon-area"
         [class.small]="iconSize === zenSvgIconSizes.small"
         [class.large]="iconSize === zenSvgIconSizes.large"
         [class.x-large]="iconSize === zenSvgIconSizes.xLarge"
         [class.xx-large]="iconSize === zenSvgIconSizes.xxLarge"
    >

        <div class="icon-container">

            <ng-content></ng-content>

        </div>

    </div>

    <div class="label-area">
        {{ label }}
    </div>

</div>
