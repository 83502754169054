<div class="container">

    <div class="section-heading">
        <zen-page-title [titleIcon]="true" [titleClickable]="true" (clicked)="clicked.emit()">
            <ng-content title-icon select="[section-icon]"></ng-content>
            <ng-content title select="[title]"></ng-content>
        </zen-page-title>
    </div>

    <ng-content></ng-content>

</div>
