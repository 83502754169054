import { Component, Input } from '@angular/core'
import { NavItem } from '@/types'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'
import { ZenSvgIconStyles } from '@domain/entities/ZenSvgIconStyles'
import { ZenSvgIconSizes } from '@domain/entities/ZenSvgIconSizes'

@Component({
    selector: 'zen-tab-bar-item',
    templateUrl: './tab-bar-item.component.html',
    styleUrls: ['./tab-bar-item.component.scss'],
})
export class TabBarItemComponent {

    @Input() navItem?: NavItem

    zenSvgIcons = ZenSvgIcons
    zenSvgIconStyles = ZenSvgIconStyles
    zenSvgIconSizes = ZenSvgIconSizes
}
