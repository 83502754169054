import { createFeatureSelector, createSelector } from '@ngrx/store'
import { adminMindfitnessFeatureKey, AdminMindfitnessState } from '../reducer'

export const selectAdminMindfitnessState = createFeatureSelector<AdminMindfitnessState>(adminMindfitnessFeatureKey)

export const selectMindfitnessContents = createSelector(
    selectAdminMindfitnessState,
    (state) => state.mindfitnessContents,
)

export const selectMindfitnessContentUpdatePending = createSelector(
    selectAdminMindfitnessState,
    (state) => state.mindfitnessContentUpload.contentUpdatePending,
)

export const selectMindfitnessAssetUploadPending = createSelector(
    selectAdminMindfitnessState,
    (state) => state.mindfitnessContentUpload.assetUploadPending,
)

export const selectMindfitnessAssetUploadPercentage = createSelector(
    selectAdminMindfitnessState,
    (state) => state.mindfitnessContentUpload.assetUploadPercentage,
)
