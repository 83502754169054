import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MediaContent } from '@domain/entities/media-content/MediaContent'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'
import { ZenSvgIconStyles } from '@domain/entities/ZenSvgIconStyles'
import { ZenSvgIconSizes } from '@domain/entities/ZenSvgIconSizes'

@Component({
    selector: 'zen-media-card-box',
    templateUrl: './media-card-box.component.html',
    styleUrls: ['./media-card-box.component.scss'],
})
export class MediaCardBoxComponent {

    @Input() mediaContent?: MediaContent
    @Input() squeezedMode = false
    @Input() showCategory = false

    @Output() clicked = new EventEmitter<string>()

    zenSvgIcons = ZenSvgIcons
    zenSvgIconStyles = ZenSvgIconStyles
    zenSvgIconSizes = ZenSvgIconSizes
}
