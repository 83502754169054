import { Component, Input } from '@angular/core'

@Component({
    selector: 'zen-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {

    @Input() overlay = true

}
