import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'

@Component({
  selector: 'zen-overlayed-content',
  templateUrl: './overlayed-content.component.html',
  styleUrls: ['./overlayed-content.component.scss'],
})
export class OverlayedContentComponent {

  @Input() title?: string
  @Input() visible?: boolean | null = false
  @Output() closed = new EventEmitter<void>()

  zenSvgIcons = ZenSvgIcons
}
