import { MindfitnessLevels } from './MindfitnessLevel';

export interface IMindfitnessContent {
    id: string
    updatedAt: any
    title: string
    description: string
    length: number // In minutes
    contentType: string
    contentFileName: string
}

export interface MindfitnessContent extends IMindfitnessContent {
    updatedAt: string
}

export const getMockMindfitnessContent = (): MindfitnessContent => {
    return {
        id: 'daily',
        updatedAt: '2023-12-12T09:03:38.539Z',
        title: 'Napi kis Vuk',
        description: 'Teszt napi tartalom',
        length: 2,
        contentType: 'audio/mpeg',
        contentFileName: 'content.mp3',
    }
}

export const getIntroductionMindfitnessContent = (): MindfitnessContent => {
    return {
        id: 'introduction',
        updatedAt: '2023-12-12T09:03:38.539Z',
        title: 'Bevezető',
        description: 'Ez az ingyenes változat lehetővé teszi számodra, hogy megismerd a MindFitness használatával járó előnyöket. A program használatához előfizetés szükséges.',
        length: 2,
        contentType: 'audio/mpeg',
        contentFileName: 'content.mp3',
    }
}

export const getInitiationMindfitnessContent = (forLevel: MindfitnessLevels): MindfitnessContent => {
    return {
        id: `initiation-${forLevel}`,
        updatedAt: '2023-12-12T09:03:38.539Z',
        title: forLevel === MindfitnessLevels.second
            ? 'Elmélyülés beavatási szertartása'
            : forLevel === MindfitnessLevels.third
                ? 'Rendíthetetlenség beavatási szertartása'
                : forLevel === MindfitnessLevels.fourth
                    ? 'Mesteri elme beavatási szertartása'
                    : forLevel === MindfitnessLevels.fifth
                        ? 'Gyémánt test beavatási szertartása'
                        : '',
        description: '',
        length: 2,
        contentType: 'audio/mpeg',
        contentFileName: 'content.mp3',
    }
}
