import { ZenSvgIcons } from './ZenSvgIcons'

export enum ZenTags {
    fokusz = 'fokusz',
    egeszseg = 'egeszseg',
    tisztanlatas = 'tisztanlatas',
    burnout = 'burnout',
    elszakadas = 'elszakadas',
    nyugalom = 'nyugalom',
    onbizalom = 'onbizalom',
    teremtes = 'teremtes',
    boldogsag = 'boldogsag',
    alvas = 'alvas',
    szerelem = 'szerelem',
    szeretet = 'szeretet',
}

export interface ZenTag {
    id: ZenTags;
    label: string;
    icon: ZenSvgIcons;
    color: string;
}

export const mapToZenTag = (tag: ZenTags): ZenTag => {
    switch (tag) {
        case ZenTags.fokusz:
            return {
                id: ZenTags.fokusz,
                label: 'Fókusz',
                icon: ZenSvgIcons.tagFokusz,
                color: '#5AEDF5',
                // color: '#67D1D9',
            }

        case ZenTags.egeszseg:
            return {
                id: ZenTags.egeszseg,
                label: 'Egészség',
                icon: ZenSvgIcons.tagEgeszseg,
                color: '#39C0E5',
                // color: '#4EB7D8',
            }

        case ZenTags.tisztanlatas:
            return {
                id: ZenTags.tisztanlatas,
                label: 'Tisztánlátás',
                icon: ZenSvgIcons.tagTisztanlatas,
                color: '#0076A8',
                // color: '#0084AD',
            }

        case ZenTags.burnout:
            return {
                id: ZenTags.burnout,
                label: 'Burnout',
                icon: ZenSvgIcons.tagBurnout,
                color: '#A4D87D',
                // color: '#9BC37E',
            }

        case ZenTags.elszakadas:
            return {
                id: ZenTags.elszakadas,
                label: 'Elengedés',
                icon: ZenSvgIcons.tagElszakadas,
                color: '#6ACB7C',
                // color: '#6FB67E',
            }

        case ZenTags.nyugalom:
            return {
                id: ZenTags.nyugalom,
                label: 'Nyugalom',
                icon: ZenSvgIcons.tagNyugalom,
                color: '#3A9773',
                // color: '#4A8A73',
            }

        case ZenTags.onbizalom:
            return {
                id: ZenTags.onbizalom,
                label: 'Önbizalom',
                icon: ZenSvgIcons.tagOnbizalom,
                color: '#FDCF57',
                // color: '#E0BC64',
            }

        case ZenTags.teremtes:
            return {
                id: ZenTags.teremtes,
                label: 'Teremtés',
                icon: ZenSvgIcons.tagTeremtes,
                color: '#FFB253',
                // color: '#E2A359',
            }

        case ZenTags.boldogsag:
            return {
                id: ZenTags.boldogsag,
                label: 'Boldogság',
                icon: ZenSvgIcons.tagBoldogsag,
                color: '#ED8838',
                // color: '#C47C46',
            }

        case ZenTags.alvas:
            return {
                id: ZenTags.alvas,
                label: 'Alvás',
                icon: ZenSvgIcons.tagAlvas,
                color: '#DA7C70',
                // color: '#C17772',
            }

        case ZenTags.szerelem:
            return {
                id: ZenTags.szerelem,
                label: 'Szerelem',
                icon: ZenSvgIcons.tagSzerelem,
                color: '#DE6561',
                // color: '#BF6061',
            }

        case ZenTags.szeretet:
            return {
                id: ZenTags.szeretet,
                label: 'Szeretet',
                icon: ZenSvgIcons.tagSzeretet,
                color: '#9A4751',
                // color: '#8B4C53',
            }
    }
}
