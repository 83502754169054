<zen-page-layout [addFooterPaddings]="false">

    <div class="admin-content-list">

        <div class="list" *ngFor="let mindfitnessItem of (mindfitnessContents$ | async) || []">

            <div class="mindfitness-item" (click)="onMindfitnessContentSelected(mindfitnessItem.id)">

                <div class="id">{{ mindfitnessItem.id }}</div>
                <div class="title">{{ mindfitnessItem.title }}</div>
                <div class="description">{{ mindfitnessItem.description }}</div>

            </div>

        </div>

    </div>

</zen-page-layout>
