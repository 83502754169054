<zen-page-layout>

    <div class="login-success">

        <div class="placeholder"></div>

        <zen-feedback-card-success
                [title]="'Sikeres bejelentkezés!'"
        >
        </zen-feedback-card-success>

        <div class="placeholder height-up-scaled"></div>

        <zen-button
                [fullWidth]="true"
                [size]="buttonSizes.dynamic"
                [style]="buttonStyles.primary"
                [chevron]="true"
                (pressed)="backToApp()"
        >
            Tovább!
        </zen-button>

        <div class="placeholder"></div>

    </div>

</zen-page-layout>
