import { Component } from '@angular/core'

@Component({
  selector: 'zen-mindfitness-content-create',
  templateUrl: './mindfitness-content-create.component.html',
  styleUrls: ['./mindfitness-content-create.component.scss'],
})
export class MindfitnessContentCreateComponent {

}
