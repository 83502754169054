<form class="zen-form" [formGroup]="mindfitnessContentForm" (ngSubmit)="onSubmit()">

    <div class="sub-title">Médiatartalom attribútumok</div>

    <div class="zen-form-fields">

        <mat-form-field class="zen-form-field" appearance="outline">
            <mat-label>Cím</mat-label>
            <input matInput placeholder="A róka és a farkas csikót vesz" [formControlName]="'title'" required>
            <mat-hint>A tartalom címe</mat-hint>
            <mat-error *ngIf="title.errors?.['required']">Kötelező mező</mat-error>
            <mat-error *ngIf="title.errors?.['minlength']">Nem elég hosszú (min. 5)</mat-error>
        </mat-form-field>

        <mat-form-field class="zen-form-field" appearance="outline">
            <mat-label>Leírás</mat-label>
            <textarea matInput
                      placeholder="Én nem tudok olvasni, de farkas komám tud, az majd elolvassa, hogy mi az ára."
                      [formControlName]="'description'" required></textarea>
            <mat-hint>Egy rövid összefoglaló, bemutatás</mat-hint>
            <mat-error *ngIf="description.errors?.['required']">Kötelező mező</mat-error>
        </mat-form-field>

        <mat-form-field class="zen-form-field" appearance="outline">
            <mat-label>Időtartam</mat-label>
            <input matInput type="number" placeholder="42" [formControlName]="'length'" required>
            <mat-hint>A tartalom hossza, percben.</mat-hint>
            <mat-error *ngIf="length.errors?.['required']">Kötelező mező</mat-error>
            <mat-error *ngIf="length.errors?.['min']">Legalább 1 perc</mat-error>
        </mat-form-field>


        <div class="zen-form-field custom">
            <mat-label>Médiatartalom</mat-label>
            <zen-file-upload [formControlName]="'contentUpload'"
                             [uploadProgress]="(assetUploadPercentage$ | async) || 0"></zen-file-upload>

            <mat-hint>A feltöltendő médiatartalom</mat-hint>
            <mat-error *ngIf="contentUpload.errors?.[validationErrors.fileSize]">A méret túl nagy, a maximum 50MB
            </mat-error>
            <mat-error *ngIf="contentUpload.errors?.[validationErrors.fileType]">Nem hangfájl lett kiválasztva
            </mat-error>
        </div>

    </div>

    <div class="zen-form-actions">
        <button class="zen-button"
                color="accent"
                mat-raised-button
                type="submit"
                [disabled]="!mindfitnessContentForm.valid">Mentés
        </button>

        <button color="warn"
                mat-mini-fab
                type="button"
                *ngIf="!!contentToEdit"
                (click)="onDelete()"
        >
            <mat-icon>delete</mat-icon>
        </button>
    </div>

</form>

<!--<div>-->
<!--    <ul>-->
<!--        <li>-->
<!--            Form Status: {{ mindfitnessContentForm.status }}-->
<!--        </li>-->
<!--        <li>-->
<!--            contentUpdatePending$: {{ contentUpdatePending$ | async }}-->
<!--        </li>-->
<!--        <li>-->
<!--            assetUploadPending$: {{ assetUploadPending$ | async }}-->
<!--        </li>-->
<!--        <li>-->
<!--            assetUploadPercentage$: {{ assetUploadPercentage$ | async }}-->
<!--        </li>-->
<!--    </ul>-->
<!--    <pre>-->
<!--        {{ mindfitnessContentForm.value | json }}-->
<!--    </pre>-->
<!--</div>-->
