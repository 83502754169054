<a [href]="url" target="_blank" class="settings-link" matRipple>

    <div class="icon">
        <zen-svg-icon [icon]="icon"></zen-svg-icon>
    </div>

    <div class="label">
        <ng-content></ng-content>
    </div>

</a>
