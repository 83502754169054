import { createFeatureSelector, createSelector } from '@ngrx/store'
import { authFeatureKey, AuthState, LOGIN_STATUS } from '../reducer'

export const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey)

export const selectLoginInProgress = createSelector(
    selectAuthState,
    (state) => state.loginInProgress,
)

export const selectLogoutInProgress = createSelector(
    selectAuthState,
    (state) => state.logoutInProgress,
)

export const selectAuthActionInProgress = createSelector(
    selectLoginInProgress,
    selectLogoutInProgress,
    (loginInProgress, logoutInProgress) => loginInProgress || logoutInProgress,
)

export const selectLastLogin = createSelector(
    selectAuthState,
    (state) => state.lastLogin,
)

export const selectLastLoginError = createSelector(
    selectAuthState,
    (state) => state.lastLogin?.status === LOGIN_STATUS.error,
)

export const selectCurrentUser = createSelector(
    selectAuthState,
    (state) => state.currentUser,
)

export const selectIsUserLoggedIn = createSelector(
    selectCurrentUser,
    (currentUser) => !!currentUser,
)
