import { Component, EventEmitter, Output } from '@angular/core'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'
import { ZenSvgIconSizes } from '@domain/entities/ZenSvgIconSizes'

@Component({
    selector: 'zen-media-more-box',
    templateUrl: './media-more-box.component.html',
    styleUrls: ['./media-more-box.component.scss'],
})
export class MediaMoreBoxComponent {

    @Output()
    moreClicked = new EventEmitter<void>()

    zenSvgIcons = ZenSvgIcons
    zenSvgIconSizes = ZenSvgIconSizes
}
