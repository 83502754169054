import { Component, Input } from '@angular/core'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'

export enum FeedbackTypes {
    success = 'success',
    error = 'error',
}

@Component({
    selector: 'zen-feedback-card',
    templateUrl: './feedback-card.component.html',
    styleUrls: ['./feedback-card.component.scss'],
})
export class FeedbackCardComponent {

    @Input() type: FeedbackTypes = FeedbackTypes.error
    @Input() title = ''
    @Input() description = ''
    @Input() details = ''

    svgIcons = ZenSvgIcons
}
