import { Injectable, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { doc, docSnapshots, DocumentReference, Firestore } from '@angular/fire/firestore'
import { Auth, user } from '@angular/fire/auth'
import * as Actions from './store/actions'
import { FS_COLLECTION_USER_DATA_SUBSCRIPTION } from '@firestore/collections/user-data-subscription';
import {
    FSCUserSubscriptionProfile,
    mapFromFSCUserSubscriptionProfile,
} from '@/types/firestore/collections/user-data-subscription/FSCUserSubscriptionProfile';

@Injectable({
    providedIn: 'root',
})
export class AfsUserDataSubscriptionService implements OnDestroy {
    private userDataSubscriptionSubscription: Subscription | null = null

    constructor(private firestore: Firestore, private store: Store, private auth: Auth) {
        console.log('AfsUserDataSubscriptionService: constructor')
        user(this.auth).pipe()
            .subscribe(async (authUser) => {
                if (authUser) {
                    this.store.dispatch(Actions.loadUserSubscription())
                    this.userDataSubscriptionSubscription = this.subscribeToUserDataSubscription(authUser.uid)

                } else {
                    this.store.dispatch(Actions.unLoadUserSubscription())
                    this.unsubscribeFromUserDataSubscription()
                }
            })
    }

    ngOnDestroy(): void {
        console.log('AfsUserDataSubscriptionService: ngOnDestroy')
        this.unsubscribeFromUserDataSubscription()
    }

    private subscribeToUserDataSubscription(userId: string): Subscription | null {
        const docRef = doc(this.firestore, FS_COLLECTION_USER_DATA_SUBSCRIPTION, userId) as DocumentReference<FSCUserSubscriptionProfile>

        return docSnapshots(docRef)
            .pipe(
                map((snap) => {
                    const fscUserSubscriptionProfile = snap.data()
                    return fscUserSubscriptionProfile
                        ? mapFromFSCUserSubscriptionProfile(fscUserSubscriptionProfile)
                        : null
                }),
            )
            .subscribe({
                    next: (userSubscriptionProfile) => {
                        this.store.dispatch(Actions.loadUserSubscriptionSuccess(userSubscriptionProfile))
                    },
                    error: (error) => {
                        console.log(error)
                        this.store.dispatch(Actions.loadUserSubscriptionError())
                    },
                },
            )
    }

    private unsubscribeFromUserDataSubscription(): void {
        if (this.userDataSubscriptionSubscription) {
            this.userDataSubscriptionSubscription.unsubscribe()
        }
    }
}
