<div class="header-container">

    <div class="header-content">

        <div class="actions-left">
            <zen-svg-icon-btn *ngIf="(backButtonEnabled$ | async) !== true && (allModalsAreClosed$ | async)"
                              (pressed)="navigateHome()"
                              [iconSize]="zenSvgIconSizes.large"
            >
                <zen-svg-icon [icon]="zenSvgIcons.logoZenCool"></zen-svg-icon>
            </zen-svg-icon-btn>

            <zen-svg-icon-btn *ngIf="(backButtonEnabled$ | async) && (allModalsAreClosed$ | async)"
                              (pressed)="navigateBack()"
                              [iconSize]="zenSvgIconSizes.normal"
            >
                <zen-svg-icon [icon]="zenSvgIcons.chevronLeft"></zen-svg-icon>
            </zen-svg-icon-btn>
        </div>

        <div class="title">{{ (title$ | async) || 'ZenCool' }}</div>

        <div class="actions-right">

<!--            <zen-svg-icon-btn *ngIf="(settingsActive$ | async) !== true && (loginActive$ | async) !== true" (pressed)="toggleNotificationsModal()">-->
<!--                <zen-svg-icon *ngIf="(notificationsActive$ | async) !== true" [icon]="zenSvgIcons.bell"></zen-svg-icon>-->
<!--                <zen-svg-icon *ngIf="notificationsActive$ | async" [icon]="zenSvgIcons.cross"></zen-svg-icon>-->
<!--            </zen-svg-icon-btn>-->

            <zen-svg-icon-btn *ngIf="(notificationsActive$ | async) !== true && (loginActive$ | async) !== true" (pressed)="toggleSettingsModal()">
                <zen-svg-icon *ngIf="(settingsActive$ | async) !== true" [icon]="zenSvgIcons.menu"></zen-svg-icon>
                <zen-svg-icon *ngIf="settingsActive$ | async" [icon]="zenSvgIcons.cross"></zen-svg-icon>
            </zen-svg-icon-btn>

            <zen-svg-icon-btn *ngIf="loginActive$ | async" (pressed)="toggleLoginModal()">
                <zen-svg-icon [icon]="zenSvgIcons.cross"></zen-svg-icon>
            </zen-svg-icon-btn>
        </div>

    </div>

</div>
