<button type="button"
        matRipple="true"
        [matRippleDisabled]="disabled"
        [class.small]="size === sizes.small"
        [class.medium]="size === sizes.medium"
        [class.large]="size === sizes.large"
        [class.dynamic]="size === sizes.dynamic"
        [class.primary]="style === styles.primary"
        [class.secondary]="style === styles.secondary"
        [class.tertiary]="style === styles.tertiary"
        [class.quaternary]="style === styles.quaternary"
        [class.accent]="style === styles.accent"
        [class.has-icon]="!!icon"
        [class.full-width]="fullWidth"
        [class.disabled]="disabled"
        (click)="onClick()"
>
    <div class="icon" *ngIf="icon">
        <zen-svg-icon [icon]="icon"></zen-svg-icon>
    </div>

    <div class="text-content">
        <div class="label">
            <ng-content></ng-content>
        </div>

        <div class="chevron" *ngIf="chevron">
            <zen-svg-icon [icon]="zenSvgIcons.chevronRight"></zen-svg-icon>
        </div>
    </div>

    <div class="icon-placeholder" *ngIf="icon"></div>
</button>
