import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { reducer, settingsFeatureKey } from './store/reducer'
import { EffectsModule } from '@ngrx/effects'
import { SettingsEffects } from '@/app/settings/store/effects'


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(settingsFeatureKey, reducer),
    EffectsModule.forFeature([SettingsEffects]),
  ],
})
export class SettingsModule {
}
