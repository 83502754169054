import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@angular/fire/auth-guard';
import { adminOnlyOrRedirectToDashboard, RoutePaths } from '@/app/routes';
import { RoutePathsAdminMindfitness } from './RoutePathsAdminMindfitness';
import { AdminMindfitnessViewComponent } from './admin-mindfitness-view.component';
import { MindfitnessContentCreateComponent } from './mindfitness-content-create/mindfitness-content-create.component';
import { MindfitnessContentListComponent } from './mindfitness-content-list/mindfitness-content-list.component';
import {
    MindfitnessContentModifyComponent
} from "@/app/views/admin-mindfitness-view/mindfitness-content-modify/mindfitness-content-modify.component";

const routes: Routes = [
    {
        path: '',
        component: AdminMindfitnessViewComponent,
        canActivate: [AuthGuard],
        data: {
            authGuardPipe: adminOnlyOrRedirectToDashboard,
            zenRouteConfig: {
                routeTitle: 'Mindfitness admin',
                toolbarEnabled: true,
                bottomNavEnabled: false,
            },
        },
    },
    {
        path: RoutePathsAdminMindfitness.createContent,
        component: MindfitnessContentCreateComponent,
        data: {
            zenRouteConfig: {
                toolbarEnabled: true,
                sidebarEnabled: true,
                bottomNavEnabled: false,
                routeTitle: 'Tartalom feltöltés',
                navBackUrl: `/${RoutePaths.adminMindfitness}`,
            },
        },
    },
    {
        path: RoutePathsAdminMindfitness.listContent,
        component: MindfitnessContentListComponent,
        data: {
            zenRouteConfig: {
                toolbarEnabled: true,
                sidebarEnabled: true,
                bottomNavEnabled: false,
                routeTitle: 'Tartalmak kezelése',
                navBackUrl: `/${RoutePaths.adminMindfitness}`,
            },
        },
    },
    {
        path: `${RoutePathsAdminMindfitness.modifyContent}/:id`,
        component: MindfitnessContentModifyComponent,
        data: {
            zenRouteConfig: {
                toolbarEnabled: true,
                sidebarEnabled: true,
                bottomNavEnabled: false,
                routeTitle: 'Tartalom szerkesztése',
                navBackUrl: `/${RoutePaths.adminMindfitness}/${RoutePathsAdminMindfitness.listContent}`,
            },
        },
    },
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminMindfitnessViewRoutingModule {
}
