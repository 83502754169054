<div class="home-greeting">

    <form class="edit-nickname-form"
          *ngIf="editActive; else displayNickname"
          [formGroup]="nickNameForm">

        <label class="zen-form-field">
            <div class="label">
                Válassz becenevet
            </div>

            <div class="field">
                <div class="icon">
                    <zen-svg-icon [icon]="zenSvgIcons.accountCircle"
                                  [style]="zenSvgIconStyles.inactive"></zen-svg-icon>
                </div>
                <input type="text"
                       id="nickname-input"
                       placeholder="#becenév"
                       [formControlName]="'nickName'"
                       [minlength]="3"
                       [maxLength]="20"
                       required>
            </div>

            <div class="error" *ngIf="nickNameField.touched && nickNameField.errors?.['required']">* Kötelező mező</div>
            <div class="error" *ngIf="nickNameField.touched && nickNameField.errors?.['minlength']">* Minimum 3
                karakter
            </div>
            <div class="error" *ngIf="nickNameField.touched && nickNameField.errors?.['maxlength']">* Maximum 20
                karakter
            </div>
        </label>

        <div class="zen-form-actions">
            <zen-button (pressed)="editActive = false"
                        [disabled]="submitted"
                        [style]="zenButtonStyles.tertiary"
                        [size]="zenButtonSizes.small"
                        [fullWidth]="false"
                        [chevron]="false"
            >
                Mégsem
            </zen-button>

            <zen-button (pressed)="onSubmit()"
                        [disabled]="!nickNameForm.valid || submitted"
                        [style]="zenButtonStyles.tertiary"
                        [size]="zenButtonSizes.small"
                        [fullWidth]="false"
                        [chevron]="false"
            >
                Mentés
            </zen-button>
        </div>
    </form>

    <ng-template #displayNickname>
        <div class="nickname-line">

            <div class="display-nickname" *ngIf="nickNameLoaded$ | async; else loading">
                Szia <span class="nickname"
                           [class.placeholder]="nickNamePlaceholder$ | async"
                           (click)="onEditStart()">{{ nickName$ | async }}</span>!
            </div>

            <ng-template #loading>
                <div class="display-nickname">
                    Szia!
                </div>
            </ng-template>


            <div class="actions">
                <zen-svg-icon-btn (pressed)="infoBtnPressed.emit()">
                    <zen-svg-icon [icon]="zenSvgIcons.info"></zen-svg-icon>
                </zen-svg-icon-btn>
            </div>
        </div>

    </ng-template>

</div>
