import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons';
import { ZenUser } from '@domain/entities/user/ZenUser';

@Component({
    selector: 'zen-settings-user-logged-in',
    templateUrl: './settings-user-logged-in.component.html',
    styleUrls: ['./settings-user-logged-in.component.scss'],
})
export class SettingsUserLoggedInComponent {

    @Input() icon: ZenSvgIcons = ZenSvgIcons.info
    @Input() loggedInUser?: ZenUser | null

    @Output() logOut = new EventEmitter<void>()

    protected readonly zenSvgIcons = ZenSvgIcons;
}
