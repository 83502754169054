<zen-page-layout [addFooterPaddings]="false">

    <zen-home-slider container
                          [visible]="infoBoxActive$ | async"
                          (closed)="onInfoBoxClose()"
    ></zen-home-slider>

    <zen-home-mindfitness-teaser container
                                 [visible]="mindFitnessTeaserActive"
                                 (closed)="onMindFitnessTeaserClose()"
    ></zen-home-mindfitness-teaser>

    <zen-home-greeting (infoBtnPressed)="onInfoBoxOpen()"></zen-home-greeting>

    <div class="home-cards">
        <zen-home-card (clicked)="goToMediaLibrary()">
            <div text-title>
                Meditációk, Tanítások, Gyakorlatok
            </div>
            <zen-svg-graphic background [graphic]="zenSvgGraphics.headphoneBuddhaCardWrapped"></zen-svg-graphic>
        </zen-home-card>

        <zen-home-card (clicked)="goToMindFitness()">
            <div text-title>
                Mindfitness
            </div>
            <div text-coming-soon>
                *Hamarosan elérhető!
            </div>
            <zen-svg-graphic background [graphic]="zenSvgGraphics.templeExteriorCardWrapped"></zen-svg-graphic>
        </zen-home-card>

        <!--        <zen-home-card (clicked)="goToZenGarden()">-->
        <!--            <div text-title>-->
        <!--                Zenkert-->
        <!--            </div>-->
        <!--            <div text-coming-soon>-->
        <!--                *Hamarosan elérhető!-->
        <!--            </div>-->
        <!--            <zen-svg-icon background [icon]="zenSvgIcons.menu" [style]="zenSvgIconStyles.background"></zen-svg-icon>-->
        <!--        </zen-home-card>-->

        <!--        <zen-home-card (clicked)="goToAshram()">-->
        <!--            <div text-title>-->
        <!--                Ashram-->
        <!--            </div>-->
        <!--            <div text-coming-soon>-->
        <!--                *Hamarosan elérhető!-->
        <!--            </div>-->
        <!--            <zen-svg-icon background [icon]="zenSvgIcons.menu" [style]="zenSvgIconStyles.background"></zen-svg-icon>-->
        <!--        </zen-home-card>-->
    </div>

</zen-page-layout>
