import { ZenUserRoles } from './ZenUserRoles'

interface ConstructorParams {
  id: string;
  displayName: string | null;
  email: string | null;
  photoURL: string | null;
  role?: ZenUserRoles;
  nickName: string | null;
}

export class ZenUser {
  readonly id: string
  readonly displayName: string | null
  readonly email: string | null
  readonly photoURL: string | null
  readonly role: ZenUserRoles
  readonly nickName: string | null

  constructor(params: ConstructorParams) {
    this.id = params.id
    this.displayName = params.displayName
    this.email = params.email
    this.photoURL = params.photoURL
    this.role = params.role || ZenUserRoles.guest
    this.nickName = params.nickName
  }
}
