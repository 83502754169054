import { Component, EventEmitter, Output } from '@angular/core'

@Component({
    selector: 'zen-media-teaser-section',
    templateUrl: './media-teaser-section.component.html',
    styleUrls: ['./media-teaser-section.component.scss'],
})
export class MediaTeaserSectionComponent {

    @Output() clicked = new EventEmitter<void>()
}
