import { Component, Input, OnInit } from '@angular/core'
import { mapToZenTag, ZenTag, ZenTags } from '@domain/entities/ZenTags'
import { mapToZenCategory, ZenCategories, ZenCategory } from '@domain/entities/ZenCategories'

@Component({
  selector: 'zen-media-tag-list',
  templateUrl: './media-tag-list.component.html',
  styleUrls: ['./media-tag-list.component.scss'],
})
export class MediaTagListComponent implements OnInit {
  @Input() zenCategory: ZenCategories | null = null
  @Input() zenTags: ZenTags[] = []

  category: ZenCategory | null = null
  tags: ZenTag[] = []

  ngOnInit(): void {
    this.category = this.zenCategory
      ? mapToZenCategory(this.zenCategory)
      : null
    this.tags = this.zenTags.map((zenTag) => mapToZenTag(zenTag))
  }

}
