import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MediaContent } from '@domain/entities/media-content/MediaContent'

@Component({
  selector: 'zen-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss'],
})
export class MediaListComponent {

  @Input() mediaList: MediaContent[] = []
  @Input() showCategories = false

  @Output() mediaItemSelected = new EventEmitter<string>()

}
