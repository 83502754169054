import { Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { NavigationService } from '../../navigation.service'
import { doNavigateBack, doNavigateHome } from '../actions';
import { selectActiveRouteConfig } from '../selectors';
import {map, tap} from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Injectable()
export class NavigationEffects {

    doNavigateBack$ = createEffect(() => this.actions$.pipe(
        ofType(doNavigateBack),
        tap(() => console.log('doNavigateBack effect')),
        concatLatestFrom(() => this.store.select(selectActiveRouteConfig)),
        map(([action, activeRouteConfig]) => {
            const navBackUrl = activeRouteConfig?.navBackUrl
            console.log('navBackUrl', navBackUrl)
            if (navBackUrl) {
                void this.service.navigateTo(navBackUrl)
            }
        }),
    ), { dispatch: false })

    doNavigateHome$ = createEffect(() => this.actions$.pipe(
        ofType(doNavigateHome),
        map(() => {
            void this.service.navigateHome()
        }),
    ), { dispatch: false })

    constructor(private actions$: Actions, private store: Store, private service: NavigationService) {
    }

}
