import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { authFeatureKey, reducer } from './store/reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './store/effects';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(authFeatureKey, reducer),
        EffectsModule.forFeature([AuthEffects]),
    ],
})
export class AuthModule {
}
