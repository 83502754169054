import { createAction } from '@ngrx/store'
import { SettingsState } from '@/app/settings/store/reducer'

export const loadSettingsComplete = createAction(
    '[Settings] - LOADED',
    (settings: SettingsState) => ({ settings }),
)

export const setInfoHomeActive = createAction(
    '[Settings] infoHomeActive - SET',
    (active: boolean) => ({ active }),
)

export const setInfoRecordingsActive = createAction(
    '[Settings] infoRecordingsActive - SET',
    (active: boolean) => ({ active }),
)

export const setInfoSearchActive = createAction(
    '[Settings] infoSearchActive - SET',
    (active: boolean) => ({ active }),
)

export const setInfoProfileActive = createAction(
    '[Settings] infoProfileActive - SET',
    (active: boolean) => ({ active }),
)
