import { Component } from '@angular/core'
import { UpdateService } from './core/update.service'
import { Analytics, logEvent } from '@angular/fire/analytics'

@Component({
    selector: 'zen-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {

    constructor(private updateService: UpdateService, private analytics: Analytics) {

        logEvent(this.analytics, 'appLoaded', {
            test: 'dummy',
        })

        const myWorker = new Worker(new URL('./test.worker', import.meta.url))
    }


}
