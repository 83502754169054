<div class="feedback-card">
    <div class="icon">
        <zen-svg-icon [icon]="svgIcons.crossRounded"></zen-svg-icon>
    </div>

    <div class="message">
        <div class="title">
            {{ title }}
        </div>

        <div class="description">
            {{ description}}
        </div>

        <div class="details" *ngIf="details">
            Hiba: {{ details }}
        </div>
    </div>
</div>
