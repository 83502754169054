import { Injectable, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { doc, docSnapshots, DocumentReference, Firestore, updateDoc } from '@angular/fire/firestore'
import { Auth, user } from '@angular/fire/auth'
import { FS_COLLECTION_USERS } from '@firestore/collections/users'
import { dataLoaded } from './store/actions'
import { FSZenUser, mapBackToZenUser } from '@firestore/collections/users/FSZenUser'

@Injectable({
    providedIn: 'root',
})
export class AfsCurrentUserService implements OnDestroy {
    private currentUserSubscription: Subscription | null = null
    private uid: string | null = null

    constructor(private firestore: Firestore, private store: Store, private auth: Auth) {
        user(auth).pipe()
            .subscribe(async (authUser) => {
                if (authUser) {
                    this.uid = authUser.uid
                    this.currentUserSubscription = this.subscribeToCurrentUser(authUser.uid)

                } else {
                    this.uid = null
                    this.unsubscribeFromCurrentUser()
                    this.store.dispatch(dataLoaded(null))
                }
            })
    }

    async updateCurrentUserNickName(nickName: string): Promise<void> {
        if (this.uid) {
            const docRef = doc(this.firestore, FS_COLLECTION_USERS, this.uid) as DocumentReference<FSZenUser>

            return updateDoc(docRef, 'nickName', nickName)
        }
    }

    ngOnDestroy(): void {
        console.log('AfsCurrentUserService: ngOnDestroy')
        this.unsubscribeFromCurrentUser()
    }

    private subscribeToCurrentUser(userId: string): Subscription | null {
        const docRef = doc(this.firestore, FS_COLLECTION_USERS, userId) as DocumentReference<FSZenUser>

        return docSnapshots(docRef)
            .pipe(
                map((fsZenUserSnap) => {
                    const fsZenUser = fsZenUserSnap.data()
                    return fsZenUser
                        ? mapBackToZenUser(fsZenUser)
                        : null
                }),
            )
            .subscribe((zenUser) => this.store.dispatch(dataLoaded(zenUser)))
    }

    private unsubscribeFromCurrentUser(): void {
        if (this.currentUserSubscription) {
            this.currentUserSubscription.unsubscribe()
        }
    }
}
