import { Component } from '@angular/core'
import { RoutePaths } from '@/app/routes'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'
import { NavItem } from '@/types'
import { ZenUser } from '@domain/entities/user/ZenUser'
import { ZenUserRoles } from '@domain/entities/user/ZenUserRoles'
import { ZenSvgIconSizes } from '@domain/entities/ZenSvgIconSizes'
import { EMPTY, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCurrentUser } from '@/app/features/auth/store/selectors';
import { doStartLogout } from '@/app/features/auth/store/actions';
import * as NavigationSelectors from '@/app/features/navigation/store/selectors';

@Component({
    selector: 'zen-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {

    settingsActive$: Observable<boolean> = EMPTY
    secondaryNavItems$: Observable<NavItem[]> = EMPTY
    adminNavItems$: Observable<NavItem[]> = EMPTY
    currentUser$: Observable<ZenUser | null> = EMPTY

    zenSvgIcons = ZenSvgIcons
    zenSvgIconSizes = ZenSvgIconSizes
    zenUserRoles = ZenUserRoles
    routePaths = RoutePaths

    constructor(private store: Store) {
        this.settingsActive$ = this.store.select(NavigationSelectors.selectSettingsActive)
        this.secondaryNavItems$ = this.store.select(NavigationSelectors.selectSecondaryNavItems)
        this.adminNavItems$ = this.store.select(NavigationSelectors.selectAdminNavItems)
        this.currentUser$ = this.store.select(selectCurrentUser)
    }

    logout() {
        this.store.dispatch(doStartLogout())
    }

}
