import { AuthPipeGenerator, customClaims, redirectUnauthorizedTo } from '@angular/fire/auth-guard'
import { pipe } from 'rxjs'
import { map } from 'rxjs/operators'
import { SettingsService } from '@/app/settings/settings.service'
import { RoutePathsLogin } from '@/app/login/RoutePathsLogin'

export enum RoutePaths {
    pageHome = 'pageHome',
    login = 'login',
    recordings = 'recordings',
    mindfitness = 'mindfitness',
    aboutUs = 'about-us',
    termsAndConditions = 'termsAndConditions',
    privacyPolicy = 'privacyPolicy',
    admin = 'admin',
    adminMindfitness = 'admin-mindfitness',
    // Legacy
    welcome = 'welcome',
    profile = 'profile',
}

export const adminOnlyOrRedirectToDashboard = () => pipe(
    customClaims,
    map(claims => claims.role === 'admin' ? true : [RoutePaths.recordings]),
)

export const redirectLoggedInToLoginSuccess: AuthPipeGenerator = () =>
    map((user) => user
            ? [RoutePaths.login, RoutePathsLogin.success]
            : true)

export const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo([RoutePaths.login])
export const redirectUnauthorizedToLoginWithRedirectBack: AuthPipeGenerator = (next, state) =>
    map((user) => {
        if (!user) {
            SettingsService.setGoToPathAfterLogin(state.url)
        }
        return user ? true : [RoutePaths.login]
    })
