import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Store } from '@ngrx/store'
import { take } from 'rxjs'
import { filter } from 'rxjs/operators'
import { selectMindfitnessContents } from '@/app/features/admin-mindfitness-feature/store/selectors'
import { MindfitnessContent } from '@domain/entities/mindfitness';

@Component({
    selector: 'zen-mindfitness-content-modify',
    templateUrl: './mindfitness-content-modify.component.html',
    styleUrls: ['./mindfitness-content-modify.component.scss'],
})
export class MindfitnessContentModifyComponent {
    loading = true
    mindfitnessContent?: MindfitnessContent
    private readonly contentId: string

    constructor(private route: ActivatedRoute, private store: Store) {
        this.contentId = this.route.snapshot.paramMap.get('id') || ''

        this.store.select(selectMindfitnessContents)
            .pipe(
                filter((list) => list.length > 0),
                take(1),
            ).subscribe({
            next: (mindfitnessContents) => {
                this.mindfitnessContent = mindfitnessContents.find((media) => media.id === this.contentId)
            },
            complete: () => {
                this.loading = false
            },
        })

    }

}
