import { environment } from '../../../../../src/environments/environment'
import { STORAGE_PATH_MEDIA_THUMBNAILS } from '../../../firestore/collections/media-thumbnails'

export const THUMBNAIL_URL_PREFIX = `${environment.publicStorageBucketCDN}/${STORAGE_PATH_MEDIA_THUMBNAILS}`

interface ConstructorParams {
  id: string;
  originalFilename: string;
  uploadedAt?: Date;
  renditionsGenerated?: boolean;
}

export class MediaThumbnail {
  readonly id: string
  readonly originalFilename: string
  readonly uploadedAt: Date
  readonly renditionsGenerated: boolean

  constructor(params: ConstructorParams) {
    this.id = params.id
    this.originalFilename = params.originalFilename
    this.uploadedAt = params.uploadedAt || new Date()
    this.renditionsGenerated = params.renditionsGenerated || false
  }

  get thumbnailUrlSmall(): string {
    return this.id
      ? `${THUMBNAIL_URL_PREFIX}/${this.id}/thumb@200x200.png`
      : 'assets/e6f2084aa9f3f9ea8881166e3c5ce7e5.png'
  }

  get thumbnailUrlMedium(): string {
    return this.id
      ? `${THUMBNAIL_URL_PREFIX}/${this.id}/thumb@512x512.png`
      : 'assets/e6f2084aa9f3f9ea8881166e3c5ce7e5.png'
  }
}
