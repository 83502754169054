<zen-page-layout>

    <zen-loader *ngIf="submitted"></zen-loader>

    <div class="email-confirm">

        <div class="placeholder"></div>

        <div class="title">
            <h1>Bejelentkezés email címmel</h1>
        </div>

        <div class="description">
            <p>Regisztrációd biztonságos megerősítése érdekében újra meg kell adnod a belépéshez használni kívánt email
                címet.</p>
            <p>Amennyiben ugyanabban a böngészőben nyitottad meg a belépési linket, amelyikben indítottad a belépést, ez
                automatikusan megtörténik.</p>
        </div>

        <form class="zen-email-confirm-form"
              *ngIf="emailLinkPresent; else noLinkFound"
              [formGroup]="loginConfirmForm">

            <label class="zen-form-field">

                <div class="label">
                    Email cím
                </div>

                <div class="field">
                    <input type="email"
                           id="email-input"
                           placeholder="minta.janos@email.com"
                           [formControlName]="'email'"
                           required>
                </div>

                <div class="error" *ngIf="email.touched && email.errors?.['required']">* Kötelező mező</div>
                <div class="error" *ngIf="email.touched && email.errors?.['email']">* Az email cím nem megfelelő</div>
            </label>

            <div class="placeholder"></div>

            <div class="zen-form-actions">
                <zen-button (pressed)="onSubmit()"
                            [disabled]="!loginConfirmForm.valid || submitted"
                            [style]="buttonStyles.primary"
                            [size]="buttonSizes.dynamic"
                            [fullWidth]="true"
                            [chevron]="true"
                >
                    Belépés megerősítése
                </zen-button>
            </div>


        </form>

        <ng-template #noLinkFound>
            <div class="login-link-unavailable">
                <div class="description">
                    Nem találtunk megerősítendő email belépési linket.
                </div>

                <zen-button (pressed)="backToLogin()"
                            [style]="buttonStyles.primary"
                            [size]="buttonSizes.dynamic"
                            [fullWidth]="true"
                            [chevron]="true"
                >
                    Vissza a belépéshez
                </zen-button>
            </div>
        </ng-template>

        <div class="placeholder"></div>

    </div>

</zen-page-layout>
