<div class="media-actions">
    <zen-svg-icon-btn
            (pressed)="toggleLiked.emit()"
            [iconSize]="zenSvgIconSizes.normal"
            aria-label="Mark as favorite"
    >
        <zen-svg-icon
                [icon]="zenSvgIcons.heart"
                [style]="liked ? zenSvgIconStyles.active : zenSvgIconStyles.normal"
        ></zen-svg-icon>
    </zen-svg-icon-btn>

    <zen-svg-icon-btn
            (pressed)="shareClicked.emit()"
            [iconSize]="zenSvgIconSizes.normal"
            aria-label="Share"
    >
        <zen-svg-icon
                [icon]="zenSvgIcons.share"
                [style]="zenSvgIconStyles.normal"
        ></zen-svg-icon>
    </zen-svg-icon-btn>
</div>
