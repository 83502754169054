import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker'
import { filter, tap } from 'rxjs/operators'
import { CoreModule } from '@/app/core/core.module'

@Injectable({
  providedIn: CoreModule,
})
export class UpdateService {
  constructor(private swUpdate: SwUpdate, private snackbar: MatSnackBar) {

    if (!this.swUpdate.isEnabled) {
      const snack = this.snackbar.open('running in dev mode', 'OK')
      window.setTimeout(async () => {
        snack.dismiss()
      }, 2000)
    }

    this.swUpdate.versionUpdates
      .pipe(
        filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
      )
      .subscribe(versionReadyEvent => {
        console.log('current version is', versionReadyEvent.currentVersion)
        console.log('latestVersion version is', versionReadyEvent.latestVersion)
        const snack = this.snackbar.open('A ZenCool App frissült!', 'Újratöltés')
        snack
          .onAction()
          .subscribe(async () => {
            await this.activateUpdate()
          })

        window.setTimeout(async () => {
          snack.dismiss()
          await this.activateUpdate()
        }, 5000)

      })
  }

  async activateUpdate() {
    console.log('activateUpdate triggered...')
    try {
      if (await this.swUpdate.activateUpdate()) {
        console.log('activateUpdate SUCCESS, reloading the app...')
        window.location.reload()
      }
    } catch (e) {
      console.error('activateUpdate ERROR', e)
    }
  }

}
