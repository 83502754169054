import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { mediaContentProgressFeatureKey, reducer } from './store/reducer'
import { EffectsModule } from '@ngrx/effects'
import { MediaContentProgressEffects } from './store/effects'


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(mediaContentProgressFeatureKey, reducer),
    EffectsModule.forFeature([MediaContentProgressEffects]),
  ],
})
export class MediaContentProgressModule {
}
