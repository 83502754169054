import { ActivationEnd, NavigationEnd, Router } from '@angular/router'
import { Injectable, OnDestroy } from '@angular/core'
import { EMPTY, Observable, pipe, Subscription } from 'rxjs'
import { combineLatestWith, filter, map, shareReplay, tap } from 'rxjs/operators'
import { ZenRouteData } from '@/types'
import { Title } from '@angular/platform-browser'
import { Store } from '@ngrx/store';
import * as NavigationActions from './store/actions'
import { selectPreviousRoute } from './store/selectors';
import { RoutePaths } from '@/app/routes';
import { environment } from '@/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class NavigationService implements OnDestroy {

    private readonly previousRoute$: Observable<string> = EMPTY;

    private routerEventsSubscription: Subscription | null = null
    private routeConfigSubscription: Subscription | null = null

    constructor(private router: Router, private titleService: Title, private store: Store) {
        this.previousRoute$ = this.store.select((selectPreviousRoute))

        this.routerEventsSubscription = this.observeRouteEventsForRouteHistory()
        this.routeConfigSubscription = this.observeRouteConfigsToUpdateStore()
    }

    async navigateTo(navBackUrl: string): Promise<boolean> {
        return this.router.navigateByUrl(navBackUrl)
    }

    async navigateHome(): Promise<boolean> {
        return this.router.navigate([RoutePaths.pageHome])
    }

    observeRouteEventsForRouteHistory(): Subscription {
        return this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.store.dispatch(NavigationActions.setLastRoute(event.urlAfterRedirects))
            }
        })
    }

    observeRouteConfigsToUpdateStore(): Subscription {
        return this.router.events.pipe(
            tap(() => {
                this.store.dispatch(NavigationActions.closeAllModals())
            }),
            filter((event) => event instanceof ActivationEnd),
            pipe(
                combineLatestWith(this.previousRoute$),
                map(([event, lastRoute]) => {
                    const snapshot = (event as ActivationEnd).snapshot
                    const data = snapshot.firstChild
                        ? snapshot.firstChild.firstChild
                            ? snapshot.firstChild.firstChild.data // e.g.: /recordings/my-lists/history
                            : snapshot.firstChild.data // e.g.: /recordings/my-lists
                        : snapshot.data // e.g.: /recordings

                    const routeConfigData = (data as ZenRouteData).zenRouteConfig || null

                    return routeConfigData && routeConfigData.navBackDynamic
                        ? {
                            ...routeConfigData,
                            navBackUrl: lastRoute,
                        } : routeConfigData
                }),
            ),
            tap(zenRouteConfig => {
                const newTitle = zenRouteConfig.routeTitle
                    ? `${environment.appTitle} - ${zenRouteConfig.routeTitle}`
                    : environment.appTitle
                this.titleService.setTitle(newTitle)
            }),
            shareReplay(),
        ).subscribe((activeRouteConfig) => {
            this.store.dispatch(NavigationActions.setActiveRouteConfig(activeRouteConfig))
        })
    }

    ngOnDestroy(): void {
        if (this.routerEventsSubscription) {
            this.routerEventsSubscription.unsubscribe()
            this.routerEventsSubscription = null
        }

        if (this.routeConfigSubscription) {
            this.routeConfigSubscription.unsubscribe()
            this.routeConfigSubscription = null
        }
    }
}
