export enum MindfitnessLevels {
    first = 'first',
    second = 'second',
    third = 'third',
    fourth = 'fourth',
    fifth = 'fifth',
}

export interface MindfitnessLevel {
    id: MindfitnessLevels,
    title: string
    description: string
    backgroundImgUrl: string
    limits: {
        min: number
        max: number | null
    }
    previousLevel: MindfitnessLevels | null
    nextLevel: MindfitnessLevels | null
}

export const getMindfitnessLevels = (): MindfitnessLevel[] => [
    {
        id: MindfitnessLevels.first,
        title: 'A Befogadás szintje',
        description: 'Ezen a szinten indul minden felhasználó.',
        backgroundImgUrl: '/assets/images/e89971f87c203c82df66fbf23aa10523.png',
        limits: {
            min: 0,
            max: 20, // => „Elmélyülés beavatási szertartása”,
        },
        previousLevel: null,
        nextLevel: MindfitnessLevels.second,
    },
    {
        id: MindfitnessLevels.second,
        title: 'Az elmélyülés szintje',
        description: 'Ezen a szinten már egyre inkább képes vagy az elmélyülésre, a kapott tanítások mélyebb értelmezésére.',
        backgroundImgUrl: '/assets/images/e89971f87c203c82df66fbf23aa10523.png',
        limits: {
            min: 20,
            max: 100, // => „Rendíthetetlenség beavatási szertartása”,
        },
        previousLevel: MindfitnessLevels.first,
        nextLevel: MindfitnessLevels.third,
    },
    {
        id: MindfitnessLevels.third,
        title: 'A rendíthetetlenség szintje',
        description: 'A buddhizmus szerint a rendíthetetlenség tudatállapotában elengedjük ragaszkodásainkat tárgyakhoz és gondolatokhoz, képessé válunk elménk természetes jóságában maradnunk. Ezen a szinten képes vagy eggyé válni a tanítással, eltántoríthatatlanul haladsz a Dharma útján.',
        backgroundImgUrl: '/assets/images/e89971f87c203c82df66fbf23aa10523.png',
        limits: {
            min: 100,
            max: 200, // => „Mesteri elme beavatási szertartása”,
        },
        previousLevel: MindfitnessLevels.second,
        nextLevel: MindfitnessLevels.fourth,
    },
    {
        id: MindfitnessLevels.fourth,
        title: 'Mesteri elme szintje',
        description: 'Ezen a szinten felismered, hogy nincs többé szükség küzdelemre, kezded érezni a jóságot mindenben, átlátni az önmagadban és körülötted zajló folyamatokat.',
        backgroundImgUrl: '/assets/images/e89971f87c203c82df66fbf23aa10523.png',
        limits: {
            min: 200,
            max: 500, // => „Gyémánt test beavatási szertartása”,
        },
        previousLevel: MindfitnessLevels.third,
        nextLevel: MindfitnessLevels.fifth,

    },
    {
        id: MindfitnessLevels.fifth,
        title: 'Gyémánt test szintje',
        description: 'A buddhizmus tanítása szerint ez az igaz természetünk állapota, amelyet a tiszta tudás végtelen nyitottsága, a határtalan együttérzés melegsége, és a teljes szabadság jellemez.',
        backgroundImgUrl: '/assets/images/e89971f87c203c82df66fbf23aa10523.png',
        limits: {
            min: 200,
            max: null, // Legmagasbb szint, nincs tobb beavatas
        },
        previousLevel: MindfitnessLevels.fourth,
        nextLevel: null,
    },
]

export const getMindfitnessLevel = (levelId: MindfitnessLevels): MindfitnessLevel => {
    return getMindfitnessLevels().find((level) => level.id === levelId) || getMindfitnessLevels()[0]
}

export const calculateMindfitnessLevel = (levelProgress: number): MindfitnessLevel => {
    return getMindfitnessLevels().find((level) => {
        return level.limits.max === null || (level.limits.min < levelProgress && level.limits.max >= levelProgress)
    }) || getMindfitnessLevels()[0]
}
