<div *ngIf="navItem"
     class="tab-item-container"
     [routerLink]="navItem.routerLink"
     [routerLinkActiveOptions]="{exact:true}"
     routerLinkActive="active"
     #rla="routerLinkActive"
>

    <div class="indicator">

    </div>

    <div class="button">
        <zen-svg-icon-btn [iconSize]="zenSvgIconSizes.normal" [propagateEvents]="true">
            <zen-svg-icon [icon]="navItem.icon" [style]="rla.isActive ? zenSvgIconStyles.active : zenSvgIconStyles.inactive"></zen-svg-icon>
        </zen-svg-icon-btn>
    </div>
</div>
