import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { mindFitnessFeatureKey, reducer } from './store/reducer';
import { EffectsModule } from '@ngrx/effects';
import { MindfitnessEffects } from './store/effects';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(mindFitnessFeatureKey, reducer),
        EffectsModule.forFeature([MindfitnessEffects]),
    ],
})
export class MindfitnessModule {
}
