<div class="media-list-row">

    <zen-media-card-box
            *ngFor="let mediaItem of mediaList | slice:0:limitTo; let i=index"
            [mediaContent]="mediaItem"
            [showCategory]="showCategories"
            (clicked)="mediaItemSelected.emit($event)"
    ></zen-media-card-box>

    <zen-media-more-box
            (moreClicked)="moreBoxClicked.emit()"
    ></zen-media-more-box>

<!--    <zen-media-more-box-->
<!--            *ngIf="mediaList.length > limitTo"-->
<!--            (moreClicked)="moreBoxClicked.emit()"-->
<!--    ></zen-media-more-box>-->

</div>
