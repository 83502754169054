import { Injectable, OnDestroy } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import * as MindfitnessActions from '../actions'
import { updateLastDateTime } from '../actions'
import { map } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { FirestoreUserDataMindfitnessService } from '@/app/features/mindfitness/firestore-user-data-mindfitness.service';
import {
    selectAssignedMediaCompleted,
    selectInfoDismissed,
    selectIntroCompleted,
} from '@/app/features/mindfitness/store/selector';
import { timer } from 'rxjs';


@Injectable()
export class MindfitnessEffects implements OnDestroy {

    setInfoOverlayActive$ = createEffect(() => this.actions$.pipe(
        ofType(MindfitnessActions.setInfoOverlayActive),
        concatLatestFrom(() => this.store.select(selectInfoDismissed)),
        map(([{ active }, infoAlreadyDismissed]) => {
            if (!active && !infoAlreadyDismissed) {
                void this.service.markInfoDismissed()
            }
        }),
    ), { dispatch: false })

    markIntroCompleted$ = createEffect(() => this.actions$.pipe(
        ofType(MindfitnessActions.markIntroCompleted),
        concatLatestFrom(() => this.store.select(selectIntroCompleted)),
        map(([action, introAlreadyCompleted]) => {
            if (!introAlreadyCompleted) {
                void this.service.markIntroCompleted()
            }
        }),
    ), { dispatch: false })

    markDailyContentCompleted$ = createEffect(() => this.actions$.pipe(
        ofType(MindfitnessActions.markDailyContentCompleted),
        concatLatestFrom(() => this.store.select(selectAssignedMediaCompleted)),
        map(([action, mediaAlreadyCompleted]) => {
            if (!mediaAlreadyCompleted) {
                void this.service.markDailyContentCompleted()
            }
        }),
    ), { dispatch: false })

    markInitiationContentCompleted$ = createEffect(() => this.actions$.pipe(
        ofType(MindfitnessActions.markInitiationContentCompleted),
        concatLatestFrom(() => this.store.select(selectAssignedMediaCompleted)),
        map(([action, mediaAlreadyCompleted]) => {
            if (!mediaAlreadyCompleted) {
                void this.service.markInitiationContentCompleted()
            }
        }),
    ), { dispatch: false })

    requestNewContent$ = createEffect(() => this.actions$.pipe(
        ofType(MindfitnessActions.requestNewContent),
        map((action) => {
            void this.service.requestDailyContent()
        }),
    ), { dispatch: false })

    private readonly timerSubscription = timer(0, 10000)
        .subscribe({
            next: () => this.store.dispatch(updateLastDateTime(new Date().toISOString())),
        })


    constructor(private actions$: Actions, private service: FirestoreUserDataMindfitnessService, private store: Store) {
    }

    ngOnDestroy(): void {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe()
        }
    }

}
