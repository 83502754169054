import { createAction } from '@ngrx/store';
import { ZenUser } from '@domain/entities/user/ZenUser';

export const doStartLoginEmail = createAction(
    '[Auth] Login - START LOGIN_EMAIL',
)

export const doStartLoginGoogle = createAction(
    '[Auth] Login - START LOGIN_GOOGLE',
)

export const doStartLoginFacebook = createAction(
    '[Auth] Login - START LOGIN_FACEBOOK',
)

export const loginSuccess = createAction(
    '[Auth] Login - SUCCESS',
)

export const loginError = createAction(
    '[Auth] Login - ERROR', (errorMessage: string | null) => ({ errorMessage }),
)

export const doStartLogout = createAction(
    '[Auth] Logout - START LOGOUT',
)

export const logoutComplete = createAction(
    '[Auth] Logout - COMPLETE',
)

export const setCurrentUser = createAction(
    '[Auth] Login - SET CURRENT_USER', (currentUser: ZenUser | null) => ({ currentUser }),
)
