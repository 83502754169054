import { getInitiationMindfitnessContent, getMindfitnessLevels, MindfitnessContent } from '../../entities/mindfitness';

export const determineInitiationContent = (levelProgress: number): MindfitnessContent | null => {
    for (const level of getMindfitnessLevels()) {
        if (level.limits.min === levelProgress) {
            return getInitiationMindfitnessContent(level.id);
        }
    }

    return null
}
