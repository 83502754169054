import { Component, Input } from '@angular/core'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'

@Component({
    selector: 'zen-feedback-card-warning',
    templateUrl: './feedback-card-warning.component.html',
    styleUrls: ['./feedback-card-warning.component.scss'],
})
export class FeedbackCardWarningComponent {

    @Input() icon: ZenSvgIcons = ZenSvgIcons.info
    @Input() title = ''
    @Input() description = ''

    svgIcons = ZenSvgIcons

}
