import { createAction } from '@ngrx/store'
import { v4 as uuid } from 'uuid'
import { serverTimestamp } from '@angular/fire/firestore';
import { MindfitnessContent } from '@domain/entities/mindfitness';
import { FSCMindfitnessContentUpdate } from '@/types/firestore/collections/mindfitness-contents/FSCMindfitnessContent';

export const mindfitnessContentUpdate = createAction(
    '[Admin] MindfitnessContent - LIST UPDATE',
    (mindfitnessContents: MindfitnessContent[]) => ({ mindfitnessContents }),
)

interface Params {
    mediaFields: {
        title: string;
        description: string;
        length: number;
    };
    existingContent?: MindfitnessContent;
    mediaAsset?: File;
}

export const mindfitnessContentUploadStart = createAction(
    '[Admin] MindfitnessContent - UPLOAD START',
    (params: Params) => {
        const { mediaFields, existingContent, mediaAsset } = params
        const mindfitnessContent: FSCMindfitnessContentUpdate = {
            ...mediaFields,
            id: existingContent?.id || uuid(),
            contentFileName: mediaAsset
                ? `content.${mediaAsset?.name.split('.').pop()}`
                : existingContent
                    ? existingContent.contentFileName
                    : 'content.mp3',
            contentType: mediaAsset
                ? mediaAsset.type
                : existingContent
                    ? existingContent.contentType
                    : 'audio/mpeg',
            updatedAt: serverTimestamp(),
        }
        return { mindfitnessContent, mediaAsset }
    },
)

export const mindfitnessContentAssetUploadProgressUpdate = createAction(
    '[Admin] MindfitnessContent - ASSET UPLOAD PROGRESS UPDATE',
    (assetUploadPercentage: number) => ({ assetUploadPercentage }),
)

export const mindfitnessContentAssetUploadSuccess = createAction(
    '[Admin] MindfitnessContent - ASSET UPLOAD SUCCESS',
    () => ({ assetUploadPending: false }),
)

// TODO implement error handling
export const mindfitnessContentAssetUploadError = createAction(
    '[Admin] MindfitnessContent - ASSET UPLOAD ERROR',
    () => ({ assetUploadPending: false }),
)

export const mindfitnessContentDataUploadSuccess = createAction(
    '[Admin] MindfitnessContent - DATA UPLOAD SUCCESS',
    () => ({ contentUpdatePending: false }),
)

// TODO implement error handling
export const mindfitnessContentDataUploadError = createAction(
    '[Admin] MindfitnessContent - DATA UPLOAD ERROR',
    () => ({ contentUpdatePending: false }),
)

export const mindfitnessContentRemoveStart = createAction(
    '[Admin] MindfitnessContent - REMOVE START',
    (id: string, assetFileName: string) => ({ id, assetFileName }),
)

export const mindfitnessContentRemoveSuccess = createAction(
    '[Admin] MindfitnessContent - REMOVE SUCCESS',
    () => ({ inProgress: false }),
)

// TODO implement error handling
export const mindfitnessContentRemoveError = createAction(
    '[Admin] MindfitnessContent - REMOVE ERROR',
    () => ({ inProgress: false }),
)
