<zen-overlay-wrapper [visible]="(active$ | async) || false">

    <zen-page-layout>

        <div class="login-contextual">

            <div class="placeholder"></div>

            <zen-feedback-card-warning
                    [icon]="svgIcons.info"
                    [title]="'Még egy lépés!'"
                    [description]="'Ha szeretnéd meghallgatni a felvételt, kérjük jelentkezz be az applikációba.'"
            >
            </zen-feedback-card-warning>

            <div class="placeholder"></div>

            <zen-button
                    class="cta-button"
                    [fullWidth]="true"
                    [size]="buttonSizes.dynamic"
                    [style]="buttonStyles.primary"
                    [chevron]="true"
                    (pressed)="goToLogin()"
            >
                Tovább a bejelentkezéshez
            </zen-button>

        </div>

    </zen-page-layout>

</zen-overlay-wrapper>
