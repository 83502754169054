import { Component, ViewChild } from '@angular/core'
import {
    AppLayoutRelativeUiComponent,
} from '@/app/components/_layout/app-layout-relative-ui/app-layout-relative-ui.component';
import { EMPTY, Observable } from 'rxjs';
import { ZenRouteConfig } from '@/types';
import { Store } from '@ngrx/store';
import * as NavigationSelectors from '@/app/features/navigation/store/selectors';

@Component({
    selector: 'zen-app-layout-relative',
    templateUrl: './app-layout-relative.component.html',
    styleUrls: ['./app-layout-relative.component.scss'],
})
export class AppLayoutRelativeComponent {

    activeRouteConfig$: Observable<ZenRouteConfig | null> = EMPTY
    notificationsActive$: Observable<boolean> = EMPTY
    settingsActive$: Observable<boolean> = EMPTY
    loginActive$: Observable<boolean> = EMPTY

    @ViewChild('appLayoutUI')
    private appLayoutRelativeUIComponent!: AppLayoutRelativeUiComponent

    constructor(private store: Store) {
        this.activeRouteConfig$ = this.store.select(NavigationSelectors.selectActiveRouteConfig)
        this.notificationsActive$ = this.store.select(NavigationSelectors.selectNotificationsActive)
        this.settingsActive$ = this.store.select(NavigationSelectors.selectSettingsActive)
        this.loginActive$ = this.store.select(NavigationSelectors.selectLoginActive)
    }

    onRouteActivate(): void {
        this.appLayoutRelativeUIComponent.scrollToTop()
    }

}
