import { Component, Input } from '@angular/core'
import { ZenSvgGraphics } from '@domain/entities/ZenSvgGraphics'
import { ZenSvgGraphicModes } from '@domain/entities/ZenSvgGraphicModes';

@Component({
    selector: 'zen-svg-graphic',
    templateUrl: './svg-graphic.component.html',
    styleUrls: ['./svg-graphic.component.scss'],
})
export class SvgGraphicComponent {

    @Input() graphic: ZenSvgGraphics = ZenSvgGraphics.zencoolLogoWithText
    @Input() graphicMode: ZenSvgGraphicModes = ZenSvgGraphicModes.fixedImage

    protected readonly zenSvgGraphicModes = ZenSvgGraphicModes;

    get svgUrl(): string {
        return `/assets/svg-graphics/${this.assetName}.svg`
    }

    get assetName(): string {
        return [
            ZenSvgGraphics.zencoolLogoWithText,
            ZenSvgGraphics.zencoolLogoCircle,
            ZenSvgGraphics.taraMethodLogo,
            ZenSvgGraphics.headphoneBuddhaCircle,
            ZenSvgGraphics.headphoneBuddhaCardWrapped,
            ZenSvgGraphics.templeExteriorCardWrapped,
            ZenSvgGraphics.templeExteriorPageBgCircle,
            ZenSvgGraphics.templeExteriorPageBgWide,
        ].includes(this.graphic)
            ? this.graphic
            : ZenSvgGraphics.zencoolLogoWithText
    }

}
