import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'
import { RoutePaths } from '@/app/routes'
import { RoutePathsLogin } from '@/app/login/RoutePathsLogin'
import { ZenButtonSizes } from '@domain/entities/ZenButtonSizes'
import { ZenButtonStyles } from '@domain/entities/ZenButtonStyles'
import { ZenSvgIconSizes } from '@domain/entities/ZenSvgIconSizes'
import { ZenSvgGraphics } from '@domain/entities/ZenSvgGraphics'
import { EMPTY, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectLastLogin, selectLastLoginError, selectLoginInProgress } from '@/app/features/auth/store/selectors';
import { LastLogin } from '@/app/features/auth/store/reducer';
import { doStartLoginFacebook, doStartLoginGoogle, doStartLogout } from '@/app/features/auth/store/actions';

@Component({
    selector: 'zen-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

    loginInProgress$: Observable<boolean> = EMPTY
    lastLogin$: Observable<LastLogin | null> = EMPTY
    lastLoginError$: Observable<boolean> = EMPTY

    termsAndConditionsVisible = false
    privacyPolicyVisible = false
    termsAccepted = false

    svgIcons = ZenSvgIcons
    svgIconSizes = ZenSvgIconSizes
    buttonSizes = ZenButtonSizes
    buttonStyles = ZenButtonStyles
    zenSvgGraphics = ZenSvgGraphics

    constructor(private router: Router, private store: Store) {
        this.loginInProgress$ = store.select(selectLoginInProgress)
        this.lastLogin$ = store.select(selectLastLogin)
        this.lastLoginError$ = store.select(selectLastLoginError)
    }

    async goToEmailLogin(): Promise<void> {
        await this.router.navigate([RoutePaths.login, RoutePathsLogin.email])
    }

    async loginGoogle(): Promise<void> {
        this.store.dispatch(doStartLoginGoogle())
    }

    async loginFacebook(): Promise<void> {
        this.store.dispatch(doStartLoginFacebook())
    }

    async logout(): Promise<void> {
        this.store.dispatch(doStartLogout())
    }
}
