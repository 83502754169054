<div class="page-title" [class.sub-title]="!!subTitle">
    <div class="section-title" (click)="clicked.emit()">
        <div class="title-icon" *ngIf="titleIcon">
            <ng-content select="[title-icon]"></ng-content>
        </div>
        <div class="title-text" [class.clickable]="titleClickable">
            <ng-content select="[title]"></ng-content>
        </div>
    </div>

    <div class="section-actions">
        <ng-content select="[actions]"></ng-content>
    </div>
</div>
