<div class="media-tag-list">

    <div class="row-category" *ngIf="category">
        <div class="list-item-wrapper">
            <zen-media-tag-category [zenCategory]="category"></zen-media-tag-category>
        </div>
    </div>

    <div class="row-tags">
        <div class="list-item-wrapper" *ngFor="let tag of tags">
            <zen-media-tag [zenTag]="tag"></zen-media-tag>
        </div>
    </div>

</div>
