import { Injectable } from '@angular/core'
import { catchError, delay, Observable, retry, throwError } from 'rxjs'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { MediaContent } from '@domain/entities/media-content/MediaContent'
import { mapBackFromFSCMediaContent } from '@/types/firestore/collections/media-contents'
import { MediaLibraryResponse } from '@/types'
import { environment } from '@/environments/environment'
import { debounceTime, map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class HttpsMediaContentProviderService {

  constructor(private http: HttpClient) {
  }

  getMediaLibrary(): Observable<MediaContent[]> {
    // return of(getMediaContentListMock())
    return this.http
      .get<MediaLibraryResponse>(`${environment.zenCoolApiHostPrefix}/mediaLibrary`)
      .pipe(
        // delay(3000),
        debounceTime(5000),
        map(this.toMediaContents),
        retry(3),
        catchError(this.handleError),
      )
  }

  private toMediaContents(mediaLibraryResponse: MediaLibraryResponse): MediaContent[] {
    return mediaLibraryResponse.mediaContents.map(mapBackFromFSCMediaContent)
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error)
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error)
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.')
  }
}
