import { mapToZenCategory, ZenCategories, ZenCategory } from '../ZenCategories'
import { mapToZenTag, ZenTag, ZenTags } from '../ZenTags'
import { MediaContentBase } from './MediaContentBase'
import { THUMBNAIL_URL_PREFIX } from '../media-thumbnail/MediaThumbnail'
import { environment } from '../../../../../src/environments/environment'
import { STORAGE_PATH_MEDIA_CONTENTS } from '../../../firestore/collections/media-contents'
import { ZenUserMediaContentProgress } from '../user/ZenUserMediaContentProgress'

export const MEDIA_URL_PREFIX = `${environment.publicStorageBucketCDN}/${STORAGE_PATH_MEDIA_CONTENTS}`

export class MediaContent implements MediaContentBase {
  readonly id: string
  readonly title: string
  readonly description: string
  readonly length: number
  readonly promoRank: number
  readonly category: ZenCategories
  readonly tags: ZenTags[]
  readonly thumbnailId: string | null
  readonly contentType: string
  readonly contentFileName: string
  readonly hidden: boolean

  userProgress: ZenUserMediaContentProgress

  constructor(params: MediaContentBase, userProgress?: ZenUserMediaContentProgress) {
    this.id = params.id
    this.title = params.title
    this.description = params.description
    this.length = params.length
    this.promoRank = params.promoRank
    this.category = params.category
    this.tags = params.tags
    this.thumbnailId = params.thumbnailId
    this.contentType = params.contentType
    this.contentFileName = params.contentFileName
    this.hidden = params.hidden

    this.userProgress = userProgress || new ZenUserMediaContentProgress({
      id: this.id,
    })
  }

  get contentUrl(): string {
    return `${MEDIA_URL_PREFIX}/${this.id}/${this.contentFileName}`
  }

  get thumbnailUrlSmall(): string {
    return this.thumbnailId
      ? `${THUMBNAIL_URL_PREFIX}/${this.thumbnailId}/thumb@200x200.png`
      : 'assets/e6f2084aa9f3f9ea8881166e3c5ce7e5.png'
  }

  get thumbnailUrlMedium(): string {
    return this.thumbnailId
      ? `${THUMBNAIL_URL_PREFIX}/${this.thumbnailId}/thumb@512x512.png`
      : 'assets/e6f2084aa9f3f9ea8881166e3c5ce7e5.png'
  }

  get zenTagList(): ZenTag[] {
    return this.tags.map(mapToZenTag)
  }

  get zenCategory(): ZenCategory {
    return mapToZenCategory(this.category)
  }
}

export const getMediaContentMock = (progress = 15): MediaContent => {
  const tags = [ZenTags.egeszseg, ZenTags.fokusz]
  const category = ZenCategories.meditaciok

  return new MediaContent({
    id: 'dummy-id',
    title: 'Dummy title dolor sit amet, consectetur.',
    description: '  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ex illo laudantium quam quidem' +
      ' voluptate? Aut, facere laboriosam? Aspernatur at autem commodi cum dignissimos eligendi fuga fugit ' +
      'ipsum maiores minus natus, officia placeat quam quasi quibusdam reiciendis rerum vel voluptas?',
    length: 15,
    promoRank: 1,
    category,
    tags,
    thumbnailId: null,
    contentType: 'audio/mpeg',
    contentFileName: 'content.mp3',
    hidden: false,
  })
}

export const getMediaContentListMock = (): MediaContent[] => [
  getMediaContentMock(),
  getMediaContentMock(25),
  getMediaContentMock(35),
  getMediaContentMock(55),
  getMediaContentMock(75),
  getMediaContentMock(100),

]
