import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userSubscriptionFeatureKey, UserSubscriptionState } from '../reducer';

export const selectUserSubscriptionState = createFeatureSelector<UserSubscriptionState>(userSubscriptionFeatureKey)

export const selectSubscriptionsFeatureEnabled = createSelector(
    selectUserSubscriptionState,
    (state) => state.featureEnabled,
)

export const selectUserHasSubscription = createSelector(
    selectUserSubscriptionState,
    (state) => !!state.subscription,
)
