import { Component, OnInit } from '@angular/core'
import { AuthService } from '@/app/features/auth/auth.service'
import { Router } from '@angular/router'
import { RoutePaths } from '@/app/routes'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ZenButtonSizes } from '@domain/entities/ZenButtonSizes'
import { ZenButtonStyles } from '@domain/entities/ZenButtonStyles'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'
import { ZenSvgIconStyles } from '@domain/entities/ZenSvgIconStyles';

@Component({
    selector: 'zen-login-email-confirm',
    templateUrl: './login-email-confirm.component.html',
    styleUrls: ['./login-email-confirm.component.scss'],
})
export class LoginEmailConfirmComponent implements OnInit {

    emailLinkPresent: boolean
    submitted = false
    loginConfirmForm = new UntypedFormGroup({
        email: new UntypedFormControl(this.authService.activeSignInEmail || '', [
            Validators.required,
            Validators.email,
        ]),
    })

    buttonSizes = ZenButtonSizes
    buttonStyles = ZenButtonStyles
    zenSvgIcons = ZenSvgIcons
    zenSvgIconStyles = ZenSvgIconStyles

    constructor(private authService: AuthService, private router: Router) {
        this.emailLinkPresent = authService.checkForEmailLink()
    }

    get email(): UntypedFormControl {
        return this.loginConfirmForm.get('email') as UntypedFormControl
    }

    ngOnInit(): void {
        if (this.email.value) {
            void this.onSubmit()
        }
    }

    async onSubmit() {
        console.warn('onSubmit', this.loginConfirmForm.value)

        this.loginConfirmForm.disable()
        this.submitted = true
        await this.authService.confirmEmailLogin(this.email.value)

        setTimeout(async () => {
            await this.router.navigate([RoutePaths.login])
        }, 3000)

    }

    backToLogin(): void {
        void this.router.navigate([RoutePaths.login])
    }

}
