import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminMindfitnessViewRoutingModule } from './admin-mindfitness-view-routing.module';
import { AdminMindfitnessViewComponent } from './admin-mindfitness-view.component';
import { ComponentsModule } from '@/app/components/components.module';
import { AdminMindfitnessFeatureModule } from '@/app/features/admin-mindfitness-feature/admin-mindfitness-feature.module';
import { MindfitnessContentFormComponent } from './mindfitness-content-form/mindfitness-content-form.component';
import { MindfitnessContentCreateComponent } from './mindfitness-content-create/mindfitness-content-create.component';
import { MindfitnessContentListComponent } from './mindfitness-content-list/mindfitness-content-list.component';
import { MindfitnessContentModifyComponent } from './mindfitness-content-modify/mindfitness-content-modify.component';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
    declarations: [
        AdminMindfitnessViewComponent,
        MindfitnessContentFormComponent,
        MindfitnessContentCreateComponent,
        MindfitnessContentListComponent,
        MindfitnessContentModifyComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ComponentsModule,
        AdminMindfitnessViewRoutingModule,
        AdminMindfitnessFeatureModule,
    ],
})
export class AdminMindfitnessViewModule {
}
