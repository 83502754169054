<div class="media-card-container"
     *ngIf="mediaContent"
     (click)="clicked.emit(mediaContent.id)"
     [class.squeezed]="squeezedMode"
     [style.background-image]="'url(\'' + mediaContent.thumbnailUrlSmall + '\')'"
>
    <div class="top-content" [class.category-visible]="showCategory">
        <zen-svg-icon-round *ngIf="showCategory"
                            [icon]="mediaContent.zenCategory.icon"
                            [backgroundColor]="'#00000099'"
        ></zen-svg-icon-round>
    </div>

    <div class="bottom-content-wrapper" [class.progress-bar-visible]="mediaContent.userProgress.progress !== 0">

        <div class="content">

            <div class="title">
                {{ mediaContent.title }}
            </div>

            <div class="tags">
                <zen-svg-icon-round *ngFor="let zenTag of mediaContent.zenTagList"
                                    [icon]="zenTag.icon"
                                    [backgroundColor]="zenTag.color"
                                    [size]="zenSvgIconSizes.small"
                ></zen-svg-icon-round>
            </div>

            <div class="timeline-wrapper" *ngIf="mediaContent.userProgress.progress !== 0">
                <zen-media-card-timeline [progress]="mediaContent.userProgress.progress + '%'">
                </zen-media-card-timeline>
            </div>

        </div>

    </div>

</div>
