import { Component, Input } from '@angular/core'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'
import { ZenSvgIconSizes } from '@domain/entities/ZenSvgIconSizes'
import { Store } from '@ngrx/store';
import * as NavigationActions from '@/app/features/navigation/store/actions';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import * as NavigationSelectors from '@/app/features/navigation/store/selectors';
import { map } from 'rxjs/operators';

@Component({
    selector: 'zen-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

    notificationsActive$: Observable<boolean> = EMPTY
    settingsActive$: Observable<boolean> = EMPTY
    loginActive$: Observable<boolean> = EMPTY
    allModalsAreClosed$: Observable<boolean> = EMPTY
    backButtonEnabled$: Observable<boolean> = EMPTY
    title$: Observable<string> = EMPTY

    zenSvgIcons = ZenSvgIcons
    zenSvgIconSizes = ZenSvgIconSizes

    constructor(private store: Store) {
        this.notificationsActive$ = this.store.select(NavigationSelectors.selectNotificationsActive)
        this.settingsActive$ = this.store.select(NavigationSelectors.selectSettingsActive)
        this.loginActive$ = this.store.select(NavigationSelectors.selectLoginActive)
        this.backButtonEnabled$ = this.store.select(NavigationSelectors.selectCanNavigateBack)
        this.title$ = this.store.select(NavigationSelectors.selectHeaderTitle)
        this.allModalsAreClosed$ = combineLatest([
            this.notificationsActive$,
            this.settingsActive$,
            this.loginActive$,
        ]).pipe(
            map(([notificationsActive, settingsActive, loginActive]) => !notificationsActive && !settingsActive && !loginActive),
        )
    }

    navigateHome(): void {
        this.store.dispatch(NavigationActions.doNavigateHome())
    }

    navigateBack(): void {
        this.store.dispatch(NavigationActions.doNavigateBack())
    }

    toggleSettingsModal(): void {
        this.store.dispatch(NavigationActions.toggleSettingsModal())
    }

    toggleNotificationsModal(): void {
        this.store.dispatch(NavigationActions.toggleNotificationsModal())
    }

    toggleLoginModal(): void {
        this.store.dispatch(NavigationActions.toggleLoginModal())
    }
}
