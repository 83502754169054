import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map } from 'rxjs/operators'
import * as SettingsActions from '../actions'
import { SettingsService } from '../../settings.service'

@Injectable()
export class SettingsEffects {

  setInfoHomeActive$ = createEffect(() => this.actions$.pipe(
    ofType(SettingsActions.setInfoHomeActive),
    map(({ active }) => {
      if (!active) {
        this.service.markInfoHomeDismissed()
      }
    }),
  ), { dispatch: false })

  setInfoRecordingsActive$ = createEffect(() => this.actions$.pipe(
    ofType(SettingsActions.setInfoRecordingsActive),
    map(({ active }) => {
      if (!active) {
        this.service.markInfoRecordingsDismissed()
      }
    }),
  ), { dispatch: false })

  setInfoSearchActive$ = createEffect(() => this.actions$.pipe(
    ofType(SettingsActions.setInfoSearchActive),
    map(({ active }) => {
      if (!active) {
        this.service.markInfoSearchDismissed()
      }
    }),
  ), { dispatch: false })

  setInfoProfileActive$ = createEffect(() => this.actions$.pipe(
    ofType(SettingsActions.setInfoProfileActive),
    map(({ active }) => {
      if (!active) {
        this.service.markInfoProfileDismissed()
      }
    }),
  ), { dispatch: false })


  constructor(private actions$: Actions, private service: SettingsService) {
  }

}
