import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ZenButtonStyles } from '@domain/entities/ZenButtonStyles'
import { ZenButtonSizes } from '@domain/entities/ZenButtonSizes'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'
import { ZenSvgGraphics } from '@domain/entities/ZenSvgGraphics'
import { ZenSvgIconStyles } from '@domain/entities/ZenSvgIconStyles';

@Component({
    selector: 'zen-home-mindfitness-teaser',
    templateUrl: './home-mindfitness-teaser.component.html',
    styleUrls: ['./home-mindfitness-teaser.component.scss'],
})
export class HomeMindfitnessTeaserComponent {

    @Input() visible?: boolean | null = false
    @Output() closed = new EventEmitter<void>()

    zenSvgGraphics = ZenSvgGraphics
    zenSvgIcons = ZenSvgIcons
    zenSvgIconStyles = ZenSvgIconStyles
    zenButtonSizes = ZenButtonSizes
    zenButtonStyles = ZenButtonStyles

    dismiss() {
        this.closed.emit()
    }
}
