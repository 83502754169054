import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ZenSvgIcons } from '@domain/entities/ZenSvgIcons'
import { ZenSvgIconStyles } from '@domain/entities/ZenSvgIconStyles'
import { ZenSvgIconSizes } from '@domain/entities/ZenSvgIconSizes'

@Component({
    selector: 'zen-media-actions',
    templateUrl: './media-actions.component.html',
    styleUrls: ['./media-actions.component.scss'],
})
export class MediaActionsComponent {

    @Input() liked = false
    @Output() toggleLiked = new EventEmitter<boolean>()
    @Output() shareClicked = new EventEmitter<void>()

    zenSvgIcons = ZenSvgIcons
    zenSvgIconStyles = ZenSvgIconStyles
    zenSvgIconSizes = ZenSvgIconSizes
}
