import { Component, Input } from '@angular/core'

export interface Slide {
    position: number;
    active: boolean;
}

@Component({
    selector: 'zen-slider-progress-indicator',
    templateUrl: './slider-progress-indicator.component.html',
    styleUrls: ['./slider-progress-indicator.component.scss'],
})
export class SliderProgressIndicatorComponent {

    @Input() max = 3
    @Input() active = 1

    constructor() {
    }

    get slides(): number[] {
        return Array(this.max)
            .fill(0)
            .map((x, i) => i)
    }
}
