import { Injectable, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'
import {
    collection,
    CollectionReference,
    collectionSnapshots,
    deleteDoc,
    doc,
    Firestore,
    query,
    setDoc,
} from '@angular/fire/firestore'
import { debounceTime, map } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { deleteObject, getStorage, ref, Storage, uploadBytesResumable } from '@angular/fire/storage'
import { Auth, user } from '@angular/fire/auth'
import { environment } from '@/environments/environment'
import {
    mindfitnessContentAssetUploadError,
    mindfitnessContentAssetUploadProgressUpdate,
    mindfitnessContentAssetUploadSuccess,
    mindfitnessContentDataUploadError,
    mindfitnessContentDataUploadSuccess,
    mindfitnessContentRemoveError,
    mindfitnessContentRemoveSuccess,
    mindfitnessContentUpdate,
} from './store/actions'
import {
    FS_COLLECTION_MINDFITNESS_CONTENTS,
    STORAGE_PATH_MINDFITNESS_CONTENTS,
} from '@firestore/collections/mindfitness-contents';
import {
    FSCMindfitnessContent,
    FSCMindfitnessContentUpdate,
    mapFromFSCMindfitnessContent,
} from '@/types/firestore/collections/mindfitness-contents/FSCMindfitnessContent';

@Injectable({
    providedIn: 'root',
})
export class AfsMindfitnessContentProviderService implements OnDestroy {
    private mindfitnessContentsSubscription: Subscription | null = null

    constructor(private firestore: Firestore, private storage: Storage, private store: Store, private auth: Auth) {
        user(auth).pipe()
            .subscribe(async (authUser) => {
                if (authUser) {
                    this.mindfitnessContentsSubscription = this.subscribeToMindfitnessContents()

                } else {
                    this.unsubscribeFromMindfitnessContents()
                }
            })
    }

    uploadMindfitnessAsset(params: { id: string; contentFileName: string; file: File }): void {
        const { id, contentFileName, file } = params
        const publicStorage = getStorage(this.storage.app, environment.publicStorageBucketUrl)
        const storageRef = ref(publicStorage, `${STORAGE_PATH_MINDFITNESS_CONTENTS}/${id}/${contentFileName}`)
        const uploadTask = uploadBytesResumable(storageRef, file)

        uploadTask.on('state_changed',
            (snapshot) => {
                const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                this.store.dispatch(mindfitnessContentAssetUploadProgressUpdate(percentage))
            },
            (error) => {
                console.error('Error in uploadMediaAsset', error)
                this.store.dispatch(mindfitnessContentAssetUploadError())
            },
            () => {
                this.store.dispatch(mindfitnessContentAssetUploadSuccess())
            },
        )
    }

    async updateMindfitnessData(updatedContent: FSCMindfitnessContentUpdate): Promise<void> {

        try {
            await this.updateMindfitnessContentInFS(updatedContent);
            this.store.dispatch(mindfitnessContentDataUploadSuccess())

        } catch (e) {
            this.store.dispatch(mindfitnessContentDataUploadError())
        }
    }

    async removeMindfitnessContent(id: string, assetFileName: string): Promise<void> {
        console.log('removeMindfitnessContent: ', id)
        try {
            await this.removeMindfitnessContentFromFS(id)
            await this.removeMindfitnessContentFromStorage(id, assetFileName)
            this.store.dispatch(mindfitnessContentRemoveSuccess())

        } catch (e) {
            this.store.dispatch(mindfitnessContentRemoveError())
        }
    }

    subscribeToMindfitnessContents(): Subscription {
        const q = query(collection(this.firestore, FS_COLLECTION_MINDFITNESS_CONTENTS) as CollectionReference<FSCMindfitnessContent>)
        return collectionSnapshots(q)
            .pipe(
                debounceTime(1000),
                map((items) =>
                    items.map((item) => mapFromFSCMindfitnessContent(item.data()))),
            ).subscribe((items) => this.store.dispatch(mindfitnessContentUpdate(items)))
    }

    ngOnDestroy(): void {
        console.log('AfsMindfitnessContentProviderService: ngOnDestroy')
        this.unsubscribeFromMindfitnessContents()
    }

    private updateMindfitnessContentInFS(updatedContent: FSCMindfitnessContentUpdate): Promise<void> {
        const docRef = doc(this.firestore, `${FS_COLLECTION_MINDFITNESS_CONTENTS}/${updatedContent.id}`)

        return setDoc(docRef, updatedContent)
    }

    private removeMindfitnessContentFromFS(id: string): Promise<void> {
        const docRef = doc(this.firestore, `${FS_COLLECTION_MINDFITNESS_CONTENTS}/${id}`)

        return deleteDoc(docRef)
    }

    private removeMindfitnessContentFromStorage(id: string, assetFileName: string): Promise<void> {
        const publicStorage = getStorage(this.storage.app, environment.publicStorageBucketUrl)
        const storageRef = ref(publicStorage, `${STORAGE_PATH_MINDFITNESS_CONTENTS}/${id}/${assetFileName}`)

        return deleteObject(storageRef)
    }

    private unsubscribeFromMindfitnessContents(): void {
        if (this.mindfitnessContentsSubscription) {
            this.mindfitnessContentsSubscription.unsubscribe()
        }
    }
}
