import { createReducer, on } from '@ngrx/store'
import { MediaContent } from '@domain/entities/media-content/MediaContent'
import * as MediaContentActions from '../actions/media-content.actions'

export const mediaContentFeatureKey = 'mediaContent'

export interface MediaContentState {
  mediaContents: MediaContent[];
  loaded: boolean;
  loading: boolean;
}

export const initialState: MediaContentState = {
  mediaContents: [],
  loaded: false,
  loading: false,
}

export const reducer = createReducer(
  initialState,

  on(MediaContentActions.loadMediaContentsStart, (state): MediaContentState => ({
    ...state,
    loading: true,
  })),

  on(MediaContentActions.loadMediaContentsSuccess, (state, { mediaContentList }): MediaContentState => ({
    ...state,
    mediaContents: mediaContentList,
    loading: false,
    loaded: true,
  })),

  on(MediaContentActions.addMediaContent, (state, { mediaContent }): MediaContentState => ({
    ...state,
    mediaContents: [...state.mediaContents, mediaContent],
  })),
)
