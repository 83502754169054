<input #fileInput class="file-input" type="file"
       [multiple]="false"
       [disabled]="disabled"
>

<div class="file-upload">

    <div class="filename" *ngIf="fileToUpload; else chooseFile">
        <ul>
            <li>{{ fileToUpload.name }}</li>
            <li>{{ fileToUpload.size / 1000000 | number: '1.2-2' }} Mb</li>
            <li>{{ fileToUpload.type }}</li>
        </ul>
    </div>
    <ng-template #chooseFile>
        Válaszd ki a feltöltendő fájlt...
    </ng-template>

    <button class="upload-btn"
            mat-mini-fab color="primary"
            type="button"
            [disabled]="disabled"
            (click)="fileInput.click()">
        <mat-icon>attach_file</mat-icon>
    </button>

</div>

<mat-progress-bar [value]="uploadProgress"></mat-progress-bar>