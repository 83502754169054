import { Timestamp } from '@angular/fire/firestore'
import {
    IMindfitnessAssignedMedia,
    MindfitnessAssignedMedia,
} from '@domain/entities/user-mindfitness/MindfitnessAssignedMedia';

export interface FSCMindfitnessAssignedMedia extends IMindfitnessAssignedMedia {
    assignedAt: Timestamp
}

export const mapToFSCMindfitnessAssignedMedia = (mindfitnessAssignedMedia: MindfitnessAssignedMedia): FSCMindfitnessAssignedMedia => ({
    ...mindfitnessAssignedMedia,
    assignedAt: Timestamp.fromDate(new Date(mindfitnessAssignedMedia.assignedAt)),
})

export const mapFromFSCMindfitnessAssignedMedia = (fscMindfitnessAssignedMedia: FSCMindfitnessAssignedMedia): MindfitnessAssignedMedia => ({
    ...fscMindfitnessAssignedMedia,
    assignedAt: fscMindfitnessAssignedMedia.assignedAt.toDate().toISOString(),
})
