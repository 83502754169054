import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { LoginRoutingModule } from './login-routing.module'
import { LoginComponent } from '@/app/login/login.component'
import { ComponentsModule } from '@/app/components/components.module'
import { LoginEmailComponent } from './login-email/login-email.component'
import { LoginEmailConfirmComponent } from './login-email-confirm/login-email-confirm.component'
import { ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { LoginSuccessComponent } from './login-success/login-success.component'


@NgModule({
    declarations: [
        LoginComponent,
        LoginEmailComponent,
        LoginEmailConfirmComponent,
        LoginSuccessComponent,
    ],
    imports: [
        CommonModule,
        LoginRoutingModule,
        ComponentsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    exports: [
        LoginComponent,
    ],
})
export class LoginModule {
}
