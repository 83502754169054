import { createFeatureSelector, createSelector } from '@ngrx/store'
import { navigationFeatureKey, NavigationState } from '../reducer'

export const selectNavigationState = createFeatureSelector<NavigationState>(navigationFeatureKey)

export const selectAdminNavItems = createSelector(
    selectNavigationState,
    (state) => state.adminNavItems,
)

export const selectSecondaryNavItems = createSelector(
    selectNavigationState,
    (state) => state.secondaryNavItems,
)
export const selectNotificationsActive = createSelector(
    selectNavigationState,
    (state) => state.notificationsActive,
)

export const selectSettingsActive = createSelector(
    selectNavigationState,
    (state) => state.settingsActive,
)

export const selectLoginActive = createSelector(
    selectNavigationState,
    (state) => state.loginActive,
)

export const selectActiveRouteConfig = createSelector(
    selectNavigationState,
    (state) => state.activeRouteConfig,
)

export const selectCanNavigateBack = createSelector(
    selectNavigationState,
    (state) => !! state.activeRouteConfig?.navBackUrl && !state.activeRouteConfig.navBackUrl.startsWith('/login'),
)

export const selectLastRoute = createSelector(
    selectNavigationState,
    (state) => state.lastRoute,
)

export const selectPreviousRoute = createSelector(
    selectNavigationState,
    (state) => state.previousRoute,
)

export const selectHeaderTitle = createSelector(
    selectNavigationState,
    (state) => state.headerTitle,
)

