import { Component } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { Router } from '@angular/router'
import { selectMindfitnessContents } from '@/app/features/admin-mindfitness-feature/store/selectors'
import { MindfitnessContent } from '@domain/entities/mindfitness';
import { RoutePaths } from '@/app/routes';
import { RoutePathsAdminMindfitness } from '@/app/views/admin-mindfitness-view/RoutePathsAdminMindfitness';

@Component({
    selector: 'zen-mindfitness-content-list',
    templateUrl: './mindfitness-content-list.component.html',
    styleUrls: ['./mindfitness-content-list.component.scss'],
})
export class MindfitnessContentListComponent {
    mindfitnessContents$: Observable<MindfitnessContent[]>

    constructor(private store: Store, private router: Router) {
        this.mindfitnessContents$ = this.store.select(selectMindfitnessContents)
    }

    onMindfitnessContentSelected(id: string): void {
        console.log('onMindfitnessContentSelected', id)
        void this.router.navigate([RoutePaths.adminMindfitness, RoutePathsAdminMindfitness.modifyContent, id])
    }
}
