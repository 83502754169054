import { Component } from '@angular/core'

@Component({
    selector: 'zen-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent {

}
