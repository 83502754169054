import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map } from 'rxjs/operators'
import { Router } from '@angular/router'
import { RoutePaths } from '@/app/routes'
import { mindfitnessContentRemoveStart, mindfitnessContentRemoveSuccess, mindfitnessContentUploadStart } from '../actions'
import { AfsMindfitnessContentProviderService } from '../../afs-mindfitness-content-provider.service'


@Injectable()
export class AdminMindfitnessEffects {

    mindfitnessContentUploadStart$ = createEffect(() => this.actions$.pipe(
        ofType(mindfitnessContentUploadStart),
        map(({ mindfitnessContent, mediaAsset }) => {
            void this.contentService.updateMindfitnessData(mindfitnessContent)

            if (mediaAsset) {
                this.contentService.uploadMindfitnessAsset({
                    id: mindfitnessContent.id,
                    contentFileName: mindfitnessContent.contentFileName,
                    file: mediaAsset,
                })
            }
        }),
    ), { dispatch: false })

    mindfitnessContentRemoveStart$ = createEffect(() => this.actions$.pipe(
        ofType(mindfitnessContentRemoveStart),
        map(({ id, assetFileName }) => this.contentService.removeMindfitnessContent(id, assetFileName)),
    ), { dispatch: false })

    mindfitnessContentRemoveSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(mindfitnessContentRemoveSuccess),
        map(() => this.router.navigate([RoutePaths.adminMindfitness])),
    ), { dispatch: false })

    constructor(
        private actions$: Actions,
        private contentService: AfsMindfitnessContentProviderService,
        private router: Router,
    ) {

        console.log('AdminMindfitnessEffects is alive')
    }

}
